import React, {createElement, useEffect, useMemo, useRef, useState} from 'react';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import i18n from 'i18next';
import useWebSocket from "react-use-websocket";

import {
    CContainer,
    CHeader,
    CHeaderNav,
    CNavLink,
    CNavItem,
    CHeaderToggler,
    CToast,
    CToastHeader,
    CToastBody,
} from '@coreui/react';
import {useTranslation} from "react-i18next";
import {cifKg, cifRu, cifUs, cilMenu} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {
    useChangeStatusGroupMutation,
    useChangeStatusPersonalMutation,
    useLazyChatGroupListQuery, useLazyChatGroupMessagesListQuery,
    useLazyChatMessagesIdQuery, useLazyChatPersonalListQuery
} from "../store/chats/chats.api";
import {useGetInfoUserQuery} from "../store/account/account.api";
import {useLazyGetUsersQuery} from "../store/users/users.api";
import makeQuery from "../functions/makeQuery";
// @ts-ignore
import notificationSound from '../assets/audio/notify.ogg';
import config from "../config";
import {useLazyGetEmergencyListChatQuery} from "../store/emergencyChat/emergencyChat.api";
import UrgentNotifications from "./UrgentNotifications/UrgentNotifications";


interface AppHeaderProps {
    i18n?: any;
    crumbs: any;
    headerMenu: any[];
    currentIndex: number;
    click: (index: number) => void;
    sidebarIsCollapsed?: any
}

export const useLocalStorage = () => {
    let token: any = localStorage.getItem('getMe');
    token = JSON.parse(token);
    token = token.access;

    const WS_URL_ES_NOTIFY = `${config.WS_URL_ES_NOTIFY}?authorization=${token}`;
    // const WS_URL_ES_NOTIFY = `wss://iamsui.yorc.org/ws/emergency-messages/?authorization=${token}`;
    const {lastMessage: esLastMessageNotify} = useWebSocket(token ? WS_URL_ES_NOTIFY : '');

    const [value, setValue] = useState(localStorage.getItem('count') || 0);

    const playNotificationSound = async () => {
        if (esLastMessageNotify) {
            const messageNotify = JSON.parse(esLastMessageNotify.data);
            if (messageNotify.count && messageNotify.count !== 0) {
                const audio = new Audio(notificationSound);
                try {
                    await audio.play();
                } catch (error) {
                    console.error('Error playing audio:', error);
                }
            } else if (messageNotify.count === 0) {
            }
        }
    };

    useEffect(() => {
        const count = localStorage.getItem('count');

        if (esLastMessageNotify) {
            const messageNotify = JSON.parse(esLastMessageNotify.data);

            if (messageNotify.count > Number(count) ) {
                playNotificationSound();
            }

            setValue(messageNotify.count);
            localStorage.setItem('count', `${messageNotify.count}`);
        }

        // const handleClick = async () => {
        //     await playNotificationSound();
        //
        //     // document.getElementById('root')?.click();
        // };
        // document.addEventListener('click', handleClick);
        //
        // return () => {
        //     document.removeEventListener('click', handleClick);
        // };
    }, [esLastMessageNotify]);

    return value;
};

const AppHeader: React.FC<AppHeaderProps> = ({crumbs, headerMenu, currentIndex, click}) => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const ref = useRef(null);
    const location = useLocation();
    const urlEs = '/es-base/detail/feed/'

    const {data: user} = useGetInfoUserQuery();
    const [fetchUsers, {data: users}] = useLazyGetUsersQuery();

    const [fetchGroupList, {data: groupList}] = useLazyChatGroupListQuery();
    const [fetchEmergencyChatList, {data: emergencyChatList, isLoading: emergencyChatListLoading}] = useLazyGetEmergencyListChatQuery();

    const [fetchPersonalList, {data: personalList}] = useLazyChatPersonalListQuery();
    const [fetchPersonalMessagesId, {data: personalMessagesId}] = useLazyChatMessagesIdQuery();
    const [fetchGroupMessagesId, {data: groupMessagesId}] = useLazyChatGroupMessagesListQuery();
    const [readPersonalChat] = useChangeStatusPersonalMutation();
    const [readGroupChat] = useChangeStatusGroupMutation();

    const [lngMenu, setLngMenu] = useState<boolean>(false);
    const [showChat, setShowChat] = useState<boolean>(false);
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const {sidebarShow} = useAppSelector((state) => state.toggleBtnApp);
    const [activeWindow, setActiveWindow] = useState('Window 1');
    const [numberGroup, setNumberGroup] = useState(0);
    const [numberPersonal, setNumberPersonal] = useState(0);
    const [message, setMessage] = useState({
        message: '',
        commands: "new_message"
    });
    const [groupMessage, setGroupMessage] = useState({
        message: '',
        commands: "new_message"
    });
    const [groupMessages, setGroupMessages]: any = useState();
    const [groupMessagesUnread, setGroupMessagesUnread]: any = useState([]);
    const [idsChats, setIdsChats]: any = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [groupUnreadNumber, setGroupUnreadNumber]: any = useState({});
    const [showNotify, setShowNotify] = useState(false);
    const [notifyMessage, setNotifyMessage]: any = useState();
    const [notifyMessageGroup, setNotifyMessageGroup]: any = useState();
    const [idGroupChat, setIdGroupChat] = useState<number>(0);
    const [showAllChatsGroup, setShowAllChatsGroup] = useState(false);
    const [showAllChatsPersonal, setShowAllChatsPersonal] = useState(true);
    const chatContainerRef: any = useRef(null);
    const [personalChatId, setPersonalChatId] = useState(null);
    const [groupChatId, setGroupChatId]: any = useState(null);
    const [showModal, setShowModal] = useState(true);
    const [count, setCount] = useState(() => {
        // Инициализация состояния из localStorage или начальное значение 0
        const savedCount = localStorage.getItem('countMessages');
        return savedCount ? parseInt(savedCount, 10) : 0;
    });

    let menu;
    let token: any = localStorage.getItem('getMe');

    token = JSON.parse(token);
    token = token.access;

    const WS_URL_PERSONAL = `${config.WS_URL_PERSONAL}?user_2=${personalChatId}&authorization=${token}`;
    // const WS_URL_PERSONAL = `wss://iamsui.yorc.org/ws/personalchat/?user_2=${personalChatId}&authorization=${token}`;
    const WS_URL_GROUP = `${config.WS_URL_GROUP}?group_chat=${groupChatId}&authorization=${token}`;
    // const WS_URL_GROUP = `wss://iamsui.yorc.org/ws/groupchat/?group_chat=${groupChatId}&authorization=${token}`;
    const WS_URL_PERSONAL_NOTIFY = `${config.WS_URL_PERSONAL_NOTIFY}?authorization=${token}`;
    // const WS_URL_PERSONAL_NOTIFY = `wss://iamsui.yorc.org/ws/personalchat/notify/?authorization=${token}`;
    const WS_URL_GROUP_NOTIFY = `${config.WS_URL_GROUP_NOTIFY}?authorization=${token}`;
    // const WS_URL_GROUP_NOTIFY = `wss://iamsui.yorc.org/ws/groupchat/notify/?authorization=${token}`;
    // const WS_URL_ES_NOTIFY = `wss://iamsui.yorc.org/ws/emergency-messages/?authorization=${token}`;

    const WS_URL_ES = `${config.WS_URL_ES_CHAT}?authorization=${token}`;

    const {lastMessage: esChatLastMessage, sendMessage: esChatSendMessage} = useWebSocket(token ? WS_URL_ES : '');
    const {lastMessage, sendMessage} = useWebSocket(personalChatId && token ? WS_URL_PERSONAL : '');
    const {
        lastMessage: groupLastMessage,
        sendMessage: groupSendMessage
    } = useWebSocket(groupChatId && token ? WS_URL_GROUP : '');
    const {lastMessage: personalLastMessageNotify} = useWebSocket(token ? WS_URL_PERSONAL_NOTIFY : '');
    const {lastMessage: groupLastMessageNotify} = useWebSocket(token ? WS_URL_GROUP_NOTIFY : '');
    // const {lastMessage: esLastMessageNotify} = useWebSocket(token ? WS_URL_ES_NOTIFY : '');
    // Функция для прокрутки вниз
    const scrollChatToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        const count = localStorage.getItem('countMessages');
        if(count) setCount(Number(count));
        fetchGroupList();
        fetchPersonalList();
        fetchUsers(makeQuery.stringify({page_size: 100000}));
        fetchEmergencyChatList();
    }, []);

    useEffect(() => {
        if (showNotify) {
            const timer = setTimeout(() => {
                setShowNotify(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showNotify, setShowNotify]);

    useEffect(() => {
        if (esChatLastMessage && !showChat) {
            playSound();
            setCount((prevCount: number ) => {
                const newCount = prevCount + 1;
                localStorage.setItem('countMessages', String(newCount));
                return newCount;
            });
        } else {
            if(esChatLastMessage) fetchEmergencyChatList();
        }
    }, [esChatLastMessage]);

    useEffect(() => {
        if(showChat) {
            fetchEmergencyChatList();
            setCount((prevCount: any ) => {
                const newCount = 0;
                localStorage.setItem('countMessages', String(newCount));
                return newCount;
            });
        }
    }, [showChat]);

    const playSound = async () => {
        const audio = new Audio(notificationSound);
        try {
            await audio.play();
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    }

    // const playNotificationSound = async () => {
    //     if (esChatLastMessage) {
    //         const messageNotify = JSON.parse(esChatLastMessage.data);
    //         if (messageNotify.count && messageNotify.count !== 0) {
    //             const audio = new Audio(notificationSound);
    //             try {
    //                 await audio.play();
    //             } catch (error) {
    //                 console.error('Error playing audio:', error);
    //             }
    //         } else if (messageNotify.count === 0) {
    //         }
    //     }
    // };

    const useNotificationHandler = (lastMessageNotify: any, setNotifyMessage: any, user: any, setShowNotify: any, showNotify: any) => {
        useEffect(() => {
            setNotifyMessage(null);
            if (lastMessageNotify?.data) {
                const messageNotify = JSON.parse(lastMessageNotify.data);
                const sender = messageNotify?.sender || messageNotify?.body?.sender;
                if (sender && sender !== user?.username) {
                    setNotifyMessage(messageNotify);
                    setShowNotify(!showNotify);
                }
            }
        }, [lastMessageNotify]);
    };

    useNotificationHandler(groupLastMessageNotify, setNotifyMessageGroup, user, setShowNotify, showNotify);
    useNotificationHandler(personalLastMessageNotify, setNotifyMessage, user, setShowNotify, showNotify);

    // const handleUserGesture = () => {
    //     if (!audioContext) {
    //         //@ts-ignore
    //         audioContext = new (window.AudioContext || window.webkitAudioContext)();
    //     }
    //
    //     if (audioContext.state === 'suspended') {
    //         audioContext.resume().catch((error: any) => {
    //             console.error('Error resuming audio context:', error);
    //         });
    //     }
    // };

    // const handleAllowNotifications = async () => {
    //     setShowModal(false);
    //     //@ts-ignore
    //     const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    //     await audioContext.resume();
    //     localStorage.setItem('modalCount', 'true');
    // };


    useEffect(() => {
        const handleNewMessage = (message: any, number: number, fetchMessages: any) => {
            if (message !== null) {
                // const msg = JSON.parse(message.data);
                if (number) fetchMessages(number);
                setTimeout(scrollChatToBottom, 500);
            }
            scrollChatToBottom();
        };

        handleNewMessage(lastMessage, numberPersonal, fetchPersonalMessagesId);
        handleNewMessage(groupLastMessage, numberGroup, fetchGroupMessagesId);
    }, [lastMessage, groupLastMessage]);

    useEffect(() => {
        getAllGroupsMessages()
            .then((res: any) =>
                setGroupMessages(res)
            )
            .catch((error) => {
                console.error("Error fetching group messages:", error);
                setGroupMessages([]);
            });
    }, [groupList, groupLastMessage, idGroupChat, groupLastMessageNotify]);

    useEffect(() => {
        if (groupMessages?.length) {
            groupMessages?.map((res: any) =>
                setGroupMessagesUnread((prevState: any) => {
                    const existingItemIndex = prevState.findIndex((item: any) => item.originalArgs === res.originalArgs);
                    if (existingItemIndex !== -1) {
                        const newState = [...prevState];
                        newState[existingItemIndex].data = res.data;
                        return newState;
                    } else {
                        return [...prevState, res];
                    }
                }))
        }

    }, [groupMessages]);

    useEffect(() => {
        fetchPersonalList();
        if (selectedChatId !== null) {
            const fetchMessages = async () => {
                const personalMessagesId: any = await fetchPersonalMessagesId(selectedChatId);
            };
            fetchMessages();
        }
    }, [selectedChatId, personalLastMessageNotify]);

    useEffect(() => {
        if (idsChats.length) {
            readPersonalChat({
                messages: idsChats,
            });
        }
    }, [idsChats]);

    useEffect(() => {
        if (idGroupChat !== 0) {
            readGroupChat({
                id: idGroupChat,
                body: {
                    read_users: [user?.id]
                }
            });
        }
    }, [idGroupChat]);

    useEffect(() => {
        showCountGroupMessages();
    }, [groupMessagesUnread, user, groupLastMessageNotify, groupLastMessage]);

    const handleMessageChange = (event: any) => {
        const messageUser = event.target.value;
        setMessage((prev: any) => ({
            ...prev,
            message: messageUser
        }));
    };

    const handleGroupMessageChange = (event: any) => {
        const messageUser = event.target.value;
        setGroupMessage((prev: any) => ({
            ...prev,
            message: messageUser
        }));
    };

    const handleMessageSend = () => {
        message && sendMessage(JSON.stringify(message));
        setMessage((prev: any) => ({
            ...prev,
            message: ''
        }));
        setTimeout(scrollChatToBottom, 500);
    };

    const handleGroupMessageSend = () => {
        groupMessage && groupSendMessage(JSON.stringify(groupMessage));
        setGroupMessage((prev: any) => ({
            ...prev,
            message: ''
        }));
        setTimeout(scrollChatToBottom, 500);
    };

    const personalListUsers = useMemo(() => {
        return personalList?.map((el: any) => ({
            id: el.id,
            users: [el.user_1.username, el.user_2.username],
            user: {
                id: el.user_2.id,
                username: el.user_2.username,
            },
            count: el.count_message_status
        }));
    }, [personalList]);

    const getAllGroupsMessages = () => {
        const promises: any[] = [];
        groupList?.map((g: any) => {
            promises.push(fetchGroupMessagesId(g.id));
        });

        return Promise.all(promises);
    };

    const openGroupChat = (e: any, id: number) => {
        setNumberGroup(id);
        setIdGroupChat(id);
        setShowAllChatsGroup(true);
        setGroupChatId(id);
        setTimeout(scrollChatToBottom, 500);
    };

    const setGroupUnreadNumberHandle = (obj: any, key: number) => {
        if (obj.hasOwnProperty(key)) {
            delete obj[key];
        }
    };

    const backToAllChatsGroup = () => {
        setShowAllChatsGroup(false);
        if (groupUnreadNumber) {
            setGroupUnreadNumberHandle(groupUnreadNumber, numberGroup);
        }
    };

    const chatWith = (e: any, id: any) => {
        setSelectedChatId(id);
        setShowAllChatsPersonal(false);
        setPersonalChatId(id);
        setTimeout(scrollChatToBottom, 500);
    };

    const backToAllChats = () => {
        setShowAllChatsPersonal(true);
    }

    const setLanguageHandler = (lang: any) => {
        if (lang !== i18n.language) i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
        setLngMenu(false);
    };

    const logoutHandler = () => {
        localStorage.setItem('getMe', '');
        navigate('/');
    };

    const chatHandler = () => {
        setShowChat(!showChat);
        localStorage.setItem('showChat', 'true');

        if (ref.current) {
            //@ts-ignore
            let height = ref.current.getBoundingClientRect().height;
            setHeaderHeight(height);
        }
    };

    const handleWindowClick = (title: string) => {
        setActiveWindow(title);
        setShowAllChatsPersonal(true);
        setShowAllChatsGroup(false);
        setTimeout(scrollChatToBottom, 500);
    };

    const getMonthName = (month: number): string => {
        const months = [t('chat.Jan'), t('chat.Feb'), t('chat.Mar'), t('chat.Apr'), t('chat.May'), t('chat.Jun'), t('chat.Jul'), t('chat.Aug'), t('chat.Sep'), t('chat.Oct'), t('chat.Nov'), t('chat.Dec'), t('chat.Aug')];
        return months[month];
    };

    const formatTime = (date: Date): string => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    if (headerMenu && headerMenu.length) {
        menu = headerMenu.map((el, idx) => {
            if (el.path === '/') return null;
            return (
                <CNavItem key={idx}>
                    <CNavLink className={`${currentIndex === idx ? 'active' : ''}`}
                              style={{marginRight: '5px'}}
                              to={el.path}
                              component={NavLink}
                              onClick={() => click(idx)}
                    >
                        {el.name}
                    </CNavLink>
                </CNavItem>
            );
        });
    }

    const showGroupChat = (id: number) => {
        return (
            <div className="chat-group-wrapper-users">
                <p className="chat-inner-title group-chat-title">{t('chat.allGroups')}</p>
                {groupList?.map((u: any) => {
                    if (u.id === id) {
                        return (
                            <div key={u.id} className="chat-group-users">
                                {u.user.map((el: any) => {
                                    return (
                                        <p key={el.id} className="chat-group-user">{el.username}</p>
                                    )
                                })}
                            </div>
                        )
                    }
                    return null;
                })}
            </div>
        );
    };

    const showCountGroupMessages = () => {
        let unreadCount: any = {};
        groupMessagesUnread.forEach((messages: any) => {
            messages?.data?.forEach((message: any) => {
                groupList.forEach((g: any) => {
                    if (g.id === message?.chat_group?.id) {
                        if (message.read_users.includes(user?.id)) {
                            if (!unreadCount[g.id]) {
                                unreadCount[g.id] = 0;
                            }
                            unreadCount[g.id]++;
                        }
                    }
                });
            });
        });
        setGroupUnreadNumber(unreadCount);
    };

    const showGroupMessages = () => {
        return (
            <div className="chat-messages">
                <div className="chat-inner-title">
                    <p className="group-chat-title">{t('chat.groupMessages')}</p>
                    <i
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                        onClick={() => backToAllChatsGroup()}
                    >
                    </i>
                </div>

                {groupMessagesUnread?.length ? (
                    <>
                        <div ref={chatContainerRef} style={{overflowY: 'scroll'}} className="chat-main">
                            {groupMessagesUnread?.map((messages: any) => {
                                // Reverse the array of messages
                                const reversedMessages = messages.data.slice().reverse();
                                return reversedMessages.map((message: any) => {
                                    if (message?.chat_group?.id === numberGroup) {
                                        const dateObject = new Date(message?.created_at);
                                        const formattedDate = `${dateObject.getDate()} ${getMonthName(dateObject.getMonth())} ${formatTime(dateObject)}`;

                                        if (user?.id === message?.sender?.id)
                                            return (
                                                <div key={message?.id} className="msg right-msg">
                                                    <div className="msg-bubble">
                                                        <div className="msg-info">
                                                            <div
                                                                className="msg-info-name">{message?.sender?.username}</div>
                                                            <div className="msg-info-time">{formattedDate}</div>
                                                        </div>
                                                        <div className="msg-text">
                                                            {message.message}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        else
                                            return (
                                                <div key={message?.id} className="msg left-msg">
                                                    <div className="msg-bubble">
                                                        <div className="msg-info">
                                                            <div
                                                                className="msg-info-name">{message?.sender?.username}</div>
                                                            <div className="msg-info-time">{formattedDate}</div>
                                                        </div>
                                                        <div className="msg-text">
                                                            {message.message}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                    }
                                })
                            })}
                        </div>
                        <div className="msger-inputarea">
                            <input className="msger-input" type="text"
                                   value={groupMessage.message}
                                   onChange={handleGroupMessageChange}/>
                            <button className="msger-send-btn"
                                    onClick={handleGroupMessageSend}>{t('chat.send')}
                            </button>
                        </div>
                    </>
                ) : null}
            </div>
        );
    };

    const notify = () => {
        if (notifyMessageGroup && groupLastMessageNotify) {
            return (
                <CToast animation={true} autohide={true} visible={showNotify} style={{
                    position: "fixed",
                    top: "20px",
                    right: "20px",
                    zIndex: 9999999999
                }}>
                    <CToastHeader closeButton>
                        <svg
                            className="rounded me-2"
                            width="20"
                            height="20"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                            role="img"
                        >
                            <rect width="100%" height="100%" fill="#007aff"></rect>
                        </svg>
                        <div
                            className="fw-bold me-auto">{t('chat.chatName')} - {notifyMessageGroup?.chat_group_name}</div>
                    </CToastHeader>
                    <CToastBody>{t('chat.messageFrom')} {notifyMessageGroup?.sender}</CToastBody>
                </CToast>
            )
        }

        if (notifyMessage && personalLastMessageNotify) {
            return (
                <CToast animation={true} autohide={true} visible={showNotify} style={{
                    position: "fixed",
                    top: "20px",
                    right: "20px",
                    zIndex: 9999999999
                }}>
                    <CToastHeader closeButton>
                        <svg
                            className="rounded me-2"
                            width="20"
                            height="20"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                            role="img"
                        >
                            <rect width="100%" height="100%" fill="#007aff"></rect>
                        </svg>
                        <div className="fw-bold me-auto">Chat with - {notifyMessage?.body.sender}</div>
                    </CToastHeader>
                    <CToastBody>Message from {notifyMessage?.body.sender}</CToastBody>
                </CToast>
            )
        }
    }

    return (
        <>
            <CHeader position="sticky" ref={ref}>
                <CContainer fluid className="flex-nowrap">
                    {!location.pathname.includes('profile') ?
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() => dispatch({type: 'set', sidebarShow: !sidebarShow})}
                        >
                            <CIcon icon={cilMenu} size="lg"/>
                        </CHeaderToggler> : null}
                    {(!crumbs) &&
                        <div className="d-flex align-items-center justify-content-center"
                             style={{width: '256px', cursor: 'default'}}
                        >
                            <Link to="/" style={{textDecoration: "none"}}>
                                <span style={{fontSize: '24px', color: '#fff'}}>
                                    {t("IAMS")} ({t("MES")})
                                    <sub style={{fontSize: '12px', marginLeft: '5px'}}>v.1.0.0</sub>
                                 </span>
                            </Link>
                        </div>
                    }

                    <CHeaderNav className="header-main-nav d-md-flex me-auto flex-wrap">
                        {menu}
                    </CHeaderNav>

                    <div className="d-flex header-settings-icons">
                        <CHeaderNav className="header-settings-icons__icon">
                            <i className="fa fa-info-circle" aria-hidden="true"> </i>
                        </CHeaderNav>
                        <CHeaderNav className="header-settings-icons__icon" style={{position: 'relative'}}>
                            {lngMenu && <div className="language-select-overlay" onClick={() => {
                                setLngMenu(false)
                            }}/>}
                            <div
                                className={`language-select-wrapper${lngMenu ? ' language-select-wrapper__active' : ''}`}>
                                <div
                                    className={`language-select-item${i18n.language === 'en' ? ' language-select-item__active' : ''}`}
                                    onClick={() => setLanguageHandler('en')}
                                >
                                    <CIcon icon={cifUs} size="xxl"/>
                                    <span>English</span>
                                </div>
                                <div
                                    className={`language-select-item${i18n.language === 'ky' ? ' language-select-item__active' : ''}`}
                                    onClick={() => setLanguageHandler('ky')}
                                >
                                    <CIcon icon={cifKg} size="xxl"/>
                                    <span>Кыргыз тили</span>
                                </div>
                                <div
                                    className={`language-select-item${i18n.language === 'ru' ? ' language-select-item__active' : ''}`}
                                    onClick={() => setLanguageHandler('ru')}
                                >
                                    <CIcon icon={cifRu} size="xxl"/>
                                    <span>Русский</span>
                                </div>
                            </div>
                            <i className="fa fa-language" aria-hidden="true" onClick={() => {
                                setLngMenu(prev => !prev)
                            }}> </i>
                        </CHeaderNav>

                        <CHeaderNav className="header-settings-icons__icon" style={{position: 'relative'}}>
                            <i className="fa fa-comments-o" aria-hidden="true" onClick={chatHandler}>
                                {count !== 0 ? <span className="chat-circle"></span> : null}
                            </i>
                        </CHeaderNav>

                        <CHeaderNav className="header-settings-icons__icon">
                            <i className="fa fa-sign-out" aria-hidden="true" onClick={logoutHandler}> </i>
                        </CHeaderNav>
                    </div>
                </CContainer>
            </CHeader>

            <UrgentNotifications/>

            {showChat ?
                <div className="chat" style={{
                    position: "fixed",
                    top: 0,
                    right: '15px',
                    marginTop: `${headerHeight}px`,
                    // height: `calc(100vh - ${headerHeight}px)`,
                    background: "white",
                    zIndex: 1002
                }}>
                    <div className="chat-wrapper chat-wrapper-es">
                        {/*<p className="chat-title">{t('chat.chatEs') }</p>*/}

                        <div className="chat-content-wrapper">
                            <div className="chat-content">

                                {
                                    <div className="chat-messages">
                                        <div className="chat-inner-title">
                                            <p className="chat-inner-title-text">{t('chat.chatEs')}</p>
                                            <i
                                                className="fa fa-times chat-icon"
                                                aria-hidden="true"
                                                onClick={() => setShowChat(false)}
                                            >
                                            </i>
                                        </div>

                                        <div className="chat-main"
                                            // ref={chatContainerRef}
                                             style={{overflowY: 'scroll', maxHeight: `calc(100vh - ${headerHeight}px - 78px)`}}>
                                            {
                                                emergencyChatList?.map((message: any, i: number) => {
                                                    const dateObject = new Date(message.created_at);
                                                    const formattedDate = `${dateObject.getDate()} ${getMonthName(dateObject.getMonth())} ${formatTime(dateObject)}`;

                                                    if (user.id === message.author.id)
                                                        return (
                                                            <div key={`${message.id}-${i}`}
                                                            // <Link to={config.baseUrl + urlEs + message.id} key={`${message.id}-${i}`}
                                                                 className="msg right-msg">
                                                                <a href={config.baseUrl + urlEs + message.emergency}
                                                                   target="_blank" className="msg-bubble">
                                                                    <div className="msg-info">
                                                                        <div className="msg-info-name">
                                                                            {t('assignmentsPage.you')}
                                                                        </div>
                                                                        <div className="msg-info-time">
                                                                            {formattedDate}
                                                                        </div>
                                                                    </div>
                                                                    <div className="msg-info">
                                                                        <div className="msg-info-emergency">
                                                                            {message.emergency_title}
                                                                        </div>
                                                                    </div>
                                                                    <div className="msg-text">
                                                                        {message.title}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        );

                                                    if (user.id !== message.author.id)
                                                        return (
                                                          <div key={`${message.id}-${i}`}
                                                                 className="msg left-msg">
                                                              <a href={config.baseUrl + urlEs + message.emergency}
                                                                 target="_blank" className="msg-bubble">
                                                                  <div className="msg-info">
                                                                      <div className="msg-info-name">
                                                                          {message.author.username}
                                                                      </div>
                                                                      <div className="msg-info-time">
                                                                          {formattedDate}
                                                                      </div>
                                                                  </div>
                                                                  <div className="msg-info">
                                                                      <div className="msg-info-emergency">
                                                                          {message.emergency_title}
                                                                      </div>
                                                                  </div>
                                                                  <div className="msg-text">
                                                                      {message.title}
                                                                  </div>
                                                              </a>
                                                          </div>
                                                        );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {/*{showChat ?*/}
            {/*    <div className="chat" style={{*/}
            {/*        position: "fixed",*/}
            {/*        marginTop: `${headerHeight}px`,*/}
            {/*        background: "white",*/}
            {/*        zIndex: 99999999*/}
            {/*    }}>*/}
            {/*        <div className="chat-wrapper">*/}
            {/*            <p className="chat-title">{t('chat.title')}</p>*/}

            {/*            <div className="chat-nav">*/}
            {/*                <div className={activeWindow === 'Window 1' ? 'active' : ''}*/}
            {/*                     onClick={() => handleWindowClick('Window 1')}>*/}
            {/*                    {t('chat.personalChat')}*/}
            {/*                </div>*/}
            {/*                <div className={activeWindow === 'Window 2' ? 'active' : ''}*/}
            {/*                     onClick={() => handleWindowClick('Window 2')}>*/}
            {/*                    {t('chat.groupChat')}*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div className="chat-content-wrapper">*/}
            {/*                {activeWindow === 'Window 1' && (*/}
            {/*                    <div className="chat-content">*/}

            {/*                        {showAllChatsPersonal ? <div className="chat-users">*/}
            {/*                            <p className="chat-inner-title">{t('chat.allChats')}</p>*/}
            {/*                            <>*/}
            {/*                                {*/}
            {/*                                    users?.results.length && users?.results?.map((userFromList: any) => {*/}
            {/*                                        if (!userFromList.username.includes(user?.username)) {*/}
            {/*                                            return (*/}
            {/*                                                <div key={userFromList.id}*/}
            {/*                                                     className="chat-with-person-main-block">*/}
            {/*                                                    <div className="chat-with-person-inner-block"*/}
            {/*                                                         onClick={(e) => chatWith(e, userFromList.id)}>*/}
            {/*                                                        <p className="chat-with-person-name">*/}

            {/*                                                            {t('chat.chatWith')} {userFromList?.username}*/}
            {/*                                                        </p>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            )*/}
            {/*                                        }*/}
            {/*                                    })*/}

            {/*                                }*/}
            {/*                            </>*/}
            {/*                        </div> : null}*/}

            {/*                        {!showAllChatsPersonal*/}
            {/*                            ? <div className="chat-messages">*/}
            {/*                                <div className="chat-inner-title">*/}
            {/*                                    <p className="chat-inner-title-text">{t('chat.personalChatMessages')}</p>*/}
            {/*                                    <i*/}
            {/*                                        className="fa fa-arrow-left"*/}
            {/*                                        aria-hidden="true"*/}
            {/*                                        onClick={() => backToAllChats()}*/}
            {/*                                    >*/}
            {/*                                    </i>*/}
            {/*                                </div>*/}
            {/*                                <div className="chat-main" ref={chatContainerRef}*/}
            {/*                                     style={{overflowY: 'scroll'}}>*/}
            {/*                                    {*/}
            {/*                                        personalMessagesId?.map((message: any, i: number) => {*/}
            {/*                                            const dateObject = new Date(message.created_at);*/}
            {/*                                            const formattedDate = `${dateObject.getDate()} ${getMonthName(dateObject.getMonth())} ${formatTime(dateObject)}`;*/}

            {/*                                            if (user.id === message.user.id)*/}
            {/*                                                return (*/}
            {/*                                                    <div key={`${message.id}-${i}`}*/}
            {/*                                                         className="msg right-msg">*/}
            {/*                                                        <div className="msg-bubble">*/}
            {/*                                                            <div className="msg-info">*/}
            {/*                                                                <div*/}
            {/*                                                                    className="msg-info-name">{message.user.username}</div>*/}
            {/*                                                                <div*/}
            {/*                                                                    className="msg-info-time">{formattedDate}</div>*/}
            {/*                                                            </div>*/}
            {/*                                                            <div className="msg-text">*/}
            {/*                                                                {message.message}*/}
            {/*                                                            </div>*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                );*/}

            {/*                                            if (user.id !== message.user.id)*/}
            {/*                                                return (*/}
            {/*                                                    <div key={`${message.id}-${i}`}*/}
            {/*                                                         className="msg left-msg">*/}
            {/*                                                        <div className="msg-bubble">*/}
            {/*                                                            <div className="msg-info">*/}
            {/*                                                                <div*/}
            {/*                                                                    className="msg-info-name">{message.user.username}</div>*/}
            {/*                                                                <div*/}
            {/*                                                                    className="msg-info-time">{formattedDate}</div>*/}
            {/*                                                            </div>*/}
            {/*                                                            <div className="msg-text">*/}
            {/*                                                                {message.message}*/}
            {/*                                                            </div>*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                );*/}
            {/*                                        })*/}
            {/*                                    }*/}
            {/*                                </div>*/}
            {/*                                <div className="msger-inputarea">*/}
            {/*                                    <input className="msger-input" type="text"*/}
            {/*                                           value={message.message}*/}
            {/*                                           onChange={handleMessageChange}/>*/}
            {/*                                    <button className="msger-send-btn"*/}
            {/*                                            onClick={handleMessageSend}>{t('chat.send')}*/}
            {/*                                    </button>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            : null*/}
            {/*                        }*/}
            {/*                    </div>*/}
            {/*                )}*/}
            {/*                {activeWindow === 'Window 2' && (*/}
            {/*                    <div className="chat-content">*/}
            {/*                        {!showAllChatsGroup ? <div className="chat-groups">*/}
            {/*                                <p className="chat-inner-title group-chat-title">{t('chat.allGroups')}</p>*/}
            {/*                                <div className="chat-all-chat">*/}
            {/*                                    {groupList?.length ?*/}
            {/*                                        groupList.map((chat: any) => {*/}
            {/*                                            return (*/}
            {/*                                                <div key={chat.id} className="chat-with-person-main-block">*/}
            {/*                                                    <div className="chat-with-person-inner-block">*/}
            {/*                                                        <p className="chat-groups-name chat-with-person-name"*/}
            {/*                                                           onClick={(e) => openGroupChat(e, chat.id)}>{chat.name}</p>*/}
            {/*                                                        {*/}
            {/*                                                            Object.entries(groupUnreadNumber).map((el: any) => {*/}
            {/*                                                                if (String(el[0]) === String(chat.id)) {*/}
            {/*                                                                    return <p key={el[0]}*/}
            {/*                                                                              className="chat-with-person-count">{el[1]}</p>*/}
            {/*                                                                }*/}
            {/*                                                            })*/}
            {/*                                                        }*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            )*/}
            {/*                                        })*/}
            {/*                                        : <div className="chat-with-person-main-block">*/}
            {/*                                            {t('chat.withOutGroup')}*/}
            {/*                                        </div>*/}
            {/*                                    }*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            : null}*/}
            {/*                        {numberGroup !== 0 && numberGroup && showAllChatsGroup ? showGroupChat(numberGroup) : null}*/}
            {/*                        {groupMessagesUnread.length && numberGroup && showAllChatsGroup ? showGroupMessages() : null}*/}
            {/*                    </div>*/}
            {/*                )}*/}

            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*    : null}*/}

            {notify()}

            {/*{showModal && !localStorage.getItem('modalCount') ? <CModal visible={true}>*/}
            {/*    <CModalHeader>*/}
            {/*        <CModalTitle>React Modal title</CModalTitle>*/}
            {/*    </CModalHeader>*/}
            {/*    <CModalBody>*/}
            {/*        <p>Разрешить звуковые уведомления?</p>*/}
            {/*    </CModalBody>*/}
            {/*    <CModalFooter>*/}
            {/*        <CButton color="secondary" onClick={() => setShowModal(false)}>Net</CButton>*/}
            {/*        <CButton color="primary" onClick={handleAllowNotifications}>Da</CButton>*/}
            {/*    </CModalFooter>*/}
            {/*</CModal> : null}*/}
        </>
    )
}

export default AppHeader;
