import React, {useEffect, useMemo, useState} from 'react';
import {
    FeatureGroup,
    GeoJSON,
    LayersControl,
    MapContainer,
    Marker,
    Polygon,
    Popup,
    ScaleControl,
    TileLayer,
    ZoomControl
} from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import {useTranslation} from "react-i18next";
import Control from 'react-leaflet-custom-control';
import 'leaflet/dist/leaflet.css';
import "leaflet-draw/dist/leaflet.draw.css";
import {getTiles} from "../../../components/maps/tiles";
import MapLoader from "../../../components/preloaders/MapLoader/MapLoader";
import {CButton, CFormCheck, CImage, CModal} from "@coreui/react";
import {useLazyGetGeoCategoriesQuery} from "../../../store/geoCategories/geoCategories.api";
import {useLazyGetGeoTypesQuery} from "../../../store/geoTypes/geoTypes.api";
import {useLazyGetGeoIconsQuery} from "../../../store/geoIcons/geoIcons.api";
import makeQuery from "../../../functions/makeQuery";
import {useLazyGetGeoObjectsGeomQuery, useLazyGetGeoObjectsQuery} from "../../../store/geoObjects/geoObjects.api";
import {useDebounce} from "../../../hooks/debounce";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {getParamsOfFileByUrl} from "../../../functions";
import GalleryModal from "../../../components/UI/Modals/GalleryModal";
import ReactDOM from "react-dom/client";
import {renderToString} from "react-dom/server";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useLazyGetEmergencyTypesQuery} from "../../../store/emergencyTypes/emergencyTypes.api";

const GeoBase = () => {
    const {t} = useTranslation();

    // const [state, setState]: any = useState([]);
    const [tree, setTree] = useState<{ [key: number]: boolean }>({});
    const [checked, setChecked]: any = useState([]);
    const [treeShowAll, setTreeShowAll] = useState<boolean>(true);
    const [showGalleryModal, setShowGalleryModal] = useState<number | null>(null);
    const [currentMarkerId, setCurrentMarkerId] = useState<number | null>(null);

    const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
    const [fetchGeoCategories, {
        isError: geoCategoriesError,
        isLoading: geoCategoriesLoading,
        data: geoCategories
    }] = useLazyGetGeoCategoriesQuery();
    const [fetchGeoTypes, {isError: error, isLoading: geoTypesLoading, data: geoTypes}] = useLazyGetGeoTypesQuery();
    const [fetchGeoIcons, {data: geoIcons, isLoading: geoIconsLoading}] = useLazyGetGeoIconsQuery();
    // const [fetchGeoObjects, {
    //     isError: deleteGeoObjectsError,
    //     isLoading: geoObjectsLoading,
    //     data: geoObjects
    // }] = useLazyGetGeoObjectsQuery();
    const [fetchGeoObjectsGeom, {
        isError: geoObjectsGeomError,
        isLoading: geoObjectsGeomLoading,
        isFetching: geoObjectsGeomFetching,
        data: geoObjectsGeom
    }] = useLazyGetGeoObjectsGeomQuery();
    const [coordinates, setCoordinates]: any = useState({lat: '', lng: ''});
    const [mapKey, setMapKey] = useState(0);
    const [center, setCenter] = useState(false);
    const [visible, setVisible]: any = useState(false);
    const [visibleLock, setVisibleLock]: any = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [opacityVisible, setOpacityVisible] = useState(false);
    const [coordinatesVisible, setCoordinatesVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [imgSrc, setImgSrc] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [queryState, setQueryState] = useState<string>(makeQuery.stringify({}));
    const filterDebounce = useDebounce(queryState, 3000);
    const [newState, setNewState] = useState([]);

    const isLoading = useMemo(() => {
        return regionsLoading || geoCategoriesLoading || geoTypesLoading || geoIconsLoading || geoObjectsGeomLoading || geoObjectsGeomFetching;
    }, [regionsLoading, geoCategoriesLoading, geoTypesLoading, geoIconsLoading, geoObjectsGeomLoading, geoObjectsGeomFetching])

    useEffect(() => {
        fetchGeoCategories();
        fetchGeoTypes({query: ''});
        fetchGeoIcons(makeQuery.stringify({page_size: 10000}));
    }, []);

    useEffect(() => {
        if (queryState) fetchGeoObjectsGeom(filterDebounce);
    }, [filterDebounce]);

    useEffect(() => {
        if (geoObjectsGeom && queryState) {
            const filteredTypesPosition: any = [];
            geoObjectsGeom?.features?.forEach((g: any) => {
                const type = g.properties?.geo_type && geoTypes?.results?.find((type: any) => String(type.id) === String(g.properties?.geo_type));
                const icon = type?.icon && geoIcons?.results?.find((icon: any) => icon.id === type?.icon);

                if (g.geometry?.type === 'MultiLineString') {
                    filteredTypesPosition.push({
                        type: "Feature",
                        geometry: g.geometry,
                        properties: {
                            ...g.properties,
                            type: type.type_name,
                        }
                    });
                }

                if (g.geometry?.type === 'LineString') {
                    filteredTypesPosition.push({
                        type: "Feature",
                        geometry: g.geometry,
                        properties: {
                            ...g.properties,
                            type: type.type_name,
                        }
                    });
                }

                if (g.geometry?.type === 'MultiPolygon') {
                    filteredTypesPosition.push({
                        type: "Feature",
                        geometry: g.geometry,
                        properties: {
                            ...g.properties,
                            type: type.type_name,
                        }
                    });
                }

                if (g.geometry?.type === 'Polygon') {
                    filteredTypesPosition.push({
                        type: "Feature",
                        geometry: g.geometry,
                        properties: {
                            ...g.properties,
                            // icon: icon?.icon,
                            type: type.type_name,
                        }
                    });
                }

                if (g.geometry?.type === 'Point') {
                    filteredTypesPosition.push({
                        type: "Feature",
                        geometry: g.geometry,
                        properties: {
                            ...g.properties,
                            icon: icon?.icon,
                        }
                    });
                }
            });

            // const filteredTypesPosition = geoObjectsGeom?.features?.map((g: any) => {
            //     const type = g.properties?.geo_type && geoTypes?.results?.find((type: any) => String(type.id) === String(g.properties?.geo_type));
            //     const icon = type?.icon && geoIcons?.results?.find((icon: any) => icon.id === type?.icon);
            //
            //     if (g.geometry?.type === 'Polygon') {
            //         return {
            //             type: "Feature",
            //             geometry: g.geometry,
            //             properties: {
            //                 ...g.properties,
            //                 icon: icon?.icon,
            //                 type: type.type_name,
            //             }
            //         };
            //     }
            //
            //     if (g.geometry?.type === 'Point') {
            //         return {
            //             type: "Feature",
            //             geometry: g.geometry,
            //             properties: {
            //                 ...g.properties,
            //                 icon: icon?.icon,
            //             }
            //         };
            //     }
            //
            // });

            setNewState(filteredTypesPosition || []);
        } else {
            setNewState([]);
        }
    }, [geoObjectsGeom, filterDebounce]);

    useEffect(() => {
        const extractIds = (data: any) => {
            if (!data) return;
            // if (data.id) {
            //     setTree(prev => ({...prev, [data.id]: true}));
            // }
            if (treeShowAll && data.id) {
                setTree(prev => ({...prev, [data.id]: true}));
            } else if (!treeShowAll && data.id) {
                setTree(prev => ({...prev, [data.id]: false}));
            }

            if (data.children && data.children.length > 0) {
                data.children.forEach((child: any) => {
                    extractIds(child);
                });
            }
        };

        geoCategories?.forEach((cat: any) => {
            extractIds(cat);
        });

    }, [geoCategories, treeShowAll]);

    useEffect(() => {
        if (!checked) return;

        const query: { [key: string]: string } = {}
        if (checked && checked.length > 0) {
            query.geo_type = checked.join(',');
        }

        setQueryState(makeQuery.stringify(query))

        // const filteredTypesPosition = checked.flatMap((c: any) => {
        //     return geoObjects?.results?.flatMap((g: any) => {
        //         if (String(c) === String(g.geo_type)) {
        //             const type = geoTypes?.results?.find((type: any) => String(type.id) === String(g.geo_type));
        //             if (type) {
        //                 const icon = geoIcons?.results?.find((icon: any) => icon.id === type.icon);
        //                 if (icon) {
        //                     return [{
        //                         icon: icon.icon,
        //                         position: [g.latitude, g.longitude],
        //                         name: g.name,
        //                         type: type.type_name,
        //                         district: g.district,
        //                         region: g.region,
        //                         images: g.images,
        //                         fields: g.geo_object_field,
        //                     }];
        //                 }
        //             }
        //         }
        //         return [];
        //     });
        // });
        // setState(filteredTypesPosition || []);
    }, [checked]);

    const onToggleGalleryModal = (markerId: number | null = null, value: number | null = null) => {
        setCurrentMarkerId(markerId);
        setShowGalleryModal(value);
    };

    const handleGetId = (id: number | string) => {
        if (checked.includes(id)) {
            setChecked(checked.filter((c: number) => c !== id));
        } else {
            setChecked([...checked, id]);
        }
    };
    const [visibleNodes, setVisibleNodes]: any = useState([]);

    const handleToggleTree = (id: number) => {
        setTree(prev => ({...prev, [id]: !prev[id]}));
        if (visibleNodes.includes(id)) {
            setVisibleNodes(visibleNodes.filter((nodeId: any) => nodeId !== id));
        } else {
            setVisibleNodes([...visibleNodes, id]);
        }
    };

    const handleVisible = (e: any, name: string) => {
        e.preventDefault();
        if (name === 'tree') {
            setVisible(!visible);
        } else if (name === 'opacity') {
            setOpacityVisible(!opacityVisible);
        } else if (name === 'coordinates') {
            setCoordinatesVisible(!coordinatesVisible);
        } else if (name === 'lock') {
            setVisibleLock(true);
            setVisible(true);
        } else if (name === 'unlock') {
            setVisibleLock(false);
        } else {
            return;
        }
    };

    const handleOpacityChange = (e: any) => {
        const value = e.target.value / 100;
        setOpacity(value);
        const mapContainer: any = document.querySelector('#MapContainer');
        const images = mapContainer.querySelector('div');
        images.style.opacity = opacity;
    };

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setCoordinates((prevCoordinates: any) => ({
            ...prevCoordinates,
            [name]: parseFloat(value) || ''
        }));
    };

    const searchCoordinates = (e: any) => {
        e.preventDefault();
        setMapKey(prevKey => prevKey + 1);
        setCenter(true);
    };


    const showImage = (links: any[], id: number, idx: number) => {
        let arraySrc: any = [];
        links.map((link: any) => arraySrc.push(link.image));
        setImgSrc(arraySrc);
        setVisibleModal(!visibleModal);
        setCurrentIndex(idx);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + imgSrc?.length) % imgSrc?.length);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imgSrc?.length);
    };

    const renderInfoObj = (e: any) => {
        const marker = e.properties;

        return (
          <div key={marker.region + marker.name} className="info-popup-block">
              <Link className="detail-es-more" to={`/gis-geo-object/${marker.id}`}
                    target="_blank">{t('edit')}</Link>

              <p><strong>{t("gis.geoObjects.infoName")}:</strong> {marker.name}</p>
              <p><strong>{t("gis.geoObjects.type")}:</strong> {marker.geo_type_name}</p>
              <p><strong>{t("gis.geoObjects.description")}:</strong> {marker.description}</p>
              {marker?.geo_object_field.length ?
                <>
                    <p><strong>{t("gis.geoObjects.additionalInformation")}:</strong></p>
                    <ul>
                        {marker.geo_object_field.map((field: any) => {
                            return (
                              <li key={field.id}>
                                  <strong>{field.geo_type_field_name}: </strong>
                                  {
                                      field.type === 'date' ? dayjs(field.value).format('DD.MM.YYYY') :
                                        field.type === 'datetime' ? dayjs(field.value).format('DD.MM.YYYY HH:mm') :
                                          field.type === 'boolean' ? (field.value === 'true' ? t('Yes') : t('No')) :
                                            field.value || '-'
                                  }
                              </li>
                            )
                        })}
                    </ul>
                </>
                : null
              }
              <p><strong>{t('Last update at')}:</strong> {dayjs(marker.updated_at).format('YYYY.MM.DD HH:mm')}</p>
              {
                  marker?.images?.length ?
                    <div>
                        <strong>{t("gis.geoObjects.image")}:</strong>
                        <div className="info-popup-img-block">
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '4px'}}>
                                {marker.images.map((file: any, idx: number) => {
                                    const fileParams = getParamsOfFileByUrl(file.image);
                                    let innerJSX;
                                    if (fileParams.type === 'image') {
                                        innerJSX = (
                                          <img alt={""}
                                               src={file.image}
                                               style={{height: '40px', width: 'auto'}}
                                          />
                                        );
                                    }
                                    if (fileParams.type === 'video') {
                                        innerJSX = (
                                          <video id="video" width="auto" height="40">
                                              <source src={file.image} type={`${fileParams.type}/${fileParams.ext}`}/>
                                          </video>
                                        );
                                    }
                                    if (fileParams.type === 'audio') {
                                        innerJSX = (
                                          <i className="fa fa-file-audio-o" aria-hidden="true"
                                             style={{fontSize: '40px'}}/>
                                        );
                                    }
                                    return (
                                      <div key={idx + 'file' + file.emergency}
                                           style={{cursor: 'pointer'}}
                                           onClick={() => onToggleGalleryModal(marker.id, idx)}
                                      >
                                          {innerJSX}
                                      </div>
                                    )
                                })}
                            </div>
                            {/*{marker?.images?.map((img: any, index: number) => {*/}
                            {/*    return (*/}
                            {/*      <img*/}
                            {/*        key={img.id + index}*/}
                            {/*        className="info-popup-img"*/}
                            {/*        src={img.image}*/}
                            {/*        alt={img.id}*/}
                            {/*        onClick={() => showImage(marker?.images, img.id, index)}*/}
                            {/*      />*/}
                            {/*    )*/}
                            {/*})}*/}
                        </div>
                        {/*<CModal*/}
                        {/*  className="info-popup-modal-block"*/}
                        {/*  alignment="center"*/}
                        {/*  visible={visibleModal}*/}
                        {/*  onClose={() => setVisibleModal(false)}*/}
                        {/*  aria-labelledby="ImageSrc"*/}
                        {/*>*/}
                        {/*    <i*/}
                        {/*      className="fa fa-times info-popup-close-icon"*/}
                        {/*      aria-hidden="true"*/}
                        {/*      onClick={() => setVisibleModal(false)}*/}
                        {/*    />*/}

                        {/*    <div className="arrow-block-left arrow-block" onClick={() => handlePrev()}>*/}
                        {/*        <i className="fa fa-arrow-left" aria-hidden="true"></i>*/}
                        {/*    </div>*/}

                        {/*    <CImage*/}
                        {/*      className="info-popup-modal-img"*/}
                        {/*      src={imgSrc[currentIndex]}*/}
                        {/*    />*/}

                        {/*    <div className="arrow-block-right arrow-block" onClick={() => handleNext()}>*/}
                        {/*        <i className="fa fa-arrow-right" aria-hidden="true"></i>*/}
                        {/*    </div>*/}
                        {/*</CModal>*/}
                    </div>
                    : null
              }
          </div>
        );
    };

    const renderOpacity = (() => {
        return (
          <div className="map-opacity-block">
              <p className="map-opacity-title">{t('gis.geoObjects.titleOpacity')}</p>
                <input
                    className="map-opacity-input"
                    type="range"
                    min="0"
                    max="100"
                    value={opacity * 100}
                    onChange={handleOpacityChange}
                />
            </div>
        )
    })

    const renderCoordinate = (
        <div className="map-opacity-block">
            <p className="map-opacity-title">{t('gis.geoObjects.coordinates')}:</p>
            <div style={{marginBottom: '10px'}}>
                <label>
                    {t("gis.geoObjects.latitude")}
                    <input
                        type="number"
                        name="lat"
                        value={coordinates.lat}
                        onChange={handleInputChange}
                    />
                </label>
                <label>
                    {t("gis.geoObjects.longitude")}
                    <input
                        type="number"
                        name="lng"
                        value={coordinates.lng}
                        onChange={handleInputChange}
                    />
                </label>
            </div>
            <CButton
                type="button"
                size="sm"
                className="bg-info"
                color="black"
                onClick={e => searchCoordinates(e)}
                disabled={!(coordinates.lat && coordinates.lng)}
            >
                {t("pagination.search")}
            </CButton>
        </div>
    );

    const Tree = (data: any, level: number = 0) => {
        const result: any[] = [];

        data && data.forEach((i: any, n: any) => {

            result.push(
                <div className="geo-base-tree-wrapper" key={n}>
                    <div className="tree geo-base-tree"
                         style={{paddingLeft: `${5 * level}px`}}>
                        {/*{i.children.length ?*/}
                        {/*    (*/}
                        {/*        tree[i.id]*/}
                        {/*            ? <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => handleToggleTree(i.id)}></i>*/}
                        {/*            : <i className="fa fa-chevron-down" aria-hidden="true" onClick={() => handleToggleTree(i.id)}></i>*/}
                        {/*    )*/}
                        {/*    : null*/}
                        {/*}*/}
                        <i
                            // className={`fa  fa-chevron-${tree[i.id] ? 'up' : 'down'}`}
                            className={`fa caret-icon fa-caret-${tree[i.id] ? 'down' : 'right'}`}
                            aria-hidden="true"
                            onClick={() => handleToggleTree(i.id)}
                        />

                        <strong>{i.category_name}</strong>
                    </div>
                    {i.children && tree[i.id] &&
                        <div
                            className={`children-wrapper ${visibleNodes.includes(i.id) ? 'tree-node-enter' : 'tree-node-exit'}`}>
                            {Tree(i.children, level + 3)}
                        </div>
                    }

                    <div>
                        {
                            geoTypes?.results?.map((type: any) => {
                                if (type.category === i.id && tree[i.id]) {
                                    return (
                                        <div
                                            className={`panel-content ${type.category === i.id && tree[i.id] ? 'fadeIn' : ''}`}
                                            style={{paddingLeft: `${level * 5}px`}} key={type.id}>
                                            <CFormCheck
                                                className='tree-checkbox'
                                                type="checkbox"
                                                name="tree-checkbox"
                                                id="tree-checkbox"
                                                value={i.id}
                                                checked={checked.includes(type.id)}
                                                onChange={() => handleGetId(type.id)}
                                                style={{marginLeft: '12px'}}
                                            />

                                            {geoIcons?.results?.map((icon: any) => {
                                                if (icon?.id === type?.icon) {
                                                    return (
                                                        <img key={icon.id} src={icon?.icon} alt={type.type_name}/>
                                                    );
                                                }
                                            })}
                                            <p className='tree-type-text'>
                                                {type.type_name}
                                            </p>
                                        </div>
                                    )
                                }
                            })
                        }

                    </div>
                </div>
            )
        })
        return result;
    }

    const handleCloseTree = () => {
        if (!visibleLock) setVisible(false);
        if (visibleLock) setVisible(true);
    }

    const galleryModalData = useMemo(() => {
        const data: any[] = [];
        if (newState?.length && currentMarkerId) {
            const element: any = newState.find((el: any) => el.properties?.id === currentMarkerId);
            if (element?.properties?.images?.length) {
                element.properties.images.forEach((el: any) => {
                    data.push({file: el.image});
                });
            }
        }
        return data;
    }, [newState, currentMarkerId]);

    const [visibleLegend, setVisibleLegend] = useState(true);
    const [switcher, setSwitcher] = useState<string>('region');

    const showLegend = () => {
        setVisibleLegend(!visibleLegend);
    }

    let obj: any = {
        count: 0
    };
    // let username = users?.results?.length ? users?.results?.find(el => el.id === userId)?.first_name + " " + users?.results?.find(el => el.id === userId)?.last_name : ''

    newState && newState?.forEach((es: any) => {
        obj.count++;
        if (obj[es?.properties?.type_emergency_name] === undefined) {
            obj[es?.properties?.type_emergency_name] = 0;
        }
        obj[es?.properties?.type_emergency_name]++;
    });

    const legendData = useMemo(() => {
        const data: {[key: string | number]: any} = { total: newState?.length, results: [] };
        const byRegions: {[key: number]: any} = {};

        newState.forEach((es: any) => {
            if (byRegions[es?.properties?.region || 0]) byRegions[es?.properties?.region || 0]++;
            else byRegions[es?.properties?.region || 0] = 1;
        });
        Object.entries(byRegions).forEach(pair => {
            data.results.push({ name: regions?.find(r => String(r.id) === String(pair[0]))?.name || 'n/a', count: pair[1] });
        });
        return data;
    },  [newState]);

    const legendTypes = useMemo(() => {
        const data: {[key: string | number]: any} = { total: newState.length, results: [] };
        const byRegions: {[key: number]: any} = {};

        newState.forEach((es: any) => {
            if (byRegions[es.properties?.geo_type || 0]) byRegions[es.properties?.geo_type || 0]++;
            else byRegions[es.properties?.geo_type || 0] = 1;
        });
        Object.entries(byRegions).forEach(pair => {
            data.results.push({ name: geoTypes?.results?.find(r => String(r.id) === String(pair[0]))?.type_name || 'n/a', count: pair[1] });
        })
        return data;
    }, [newState]);

    const legend = (
        visibleLegend
            ?
            <>
                {switcher === "region"
                    ?
                    <div className="map-legend">
                        <div className="map-legend-inner-info">
                            <p className="map-legend-title">{t('Total')}: {legendData.total}</p>
                            <div className="map-legend-info-block">
                                {legendData.results.map((el: any, idx: number) => (
                                    <div key={'legendItem' + idx} className="map-legend-info-inner-block">
                                        <div className='map-legend-circle' style={{background: 'grey'}}></div>
                                        <p
                                            className="map-legend-type map-legend-es-type"><span
                                            className="long-text">{el.name}</span><span className="number-text">{el.count}</span></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="map-legend-btns">
                            {switcher === 'region'
                                ?
                                <CButton size="sm" color="info" className="map-legend-switcher"
                                         onClick={() => setSwitcher('types')}>
                                    {t('ESMap.types')}
                                </CButton>
                                :
                                <CButton size="sm" color="info" className="map-legend-switcher"
                                         onClick={() => setSwitcher('region')}>
                                    {t('ESMap.regions')}
                                </CButton>
                            }
                            <i
                                className="fa fa-arrow-right map-legend-arrow-hide map-legend-arrow-click"
                                aria-hidden="true"
                                onClick={() => showLegend()}
                            />
                        </div>

                    </div>
                    :
                    <div className="map-legend">
                        <div className="map-legend-inner-info">
                            <p className="map-legend-title">{t('Total')}: {legendTypes.total}</p>
                            <div className="map-legend-info-block">
                                {legendTypes.results.map((el: any, idx: number) => (
                                    <div key={'legendItem' + idx} className="map-legend-info-inner-block">
                                        <div className='map-legend-circle' style={{background: 'grey'}}></div>
                                        <p
                                            className="map-legend-type map-legend-es-type"><span
                                            className="long-text">{el.name}</span><span className="number-text">{el.count}</span></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="map-legend-btns">
                            {switcher === 'region'
                                ?
                                <CButton size="sm" color="info" className="map-legend-switcher"
                                         onClick={() => setSwitcher('types')}>
                                    {t('ESMap.types')}
                                </CButton>
                                :
                                <CButton size="sm" color="info" className="map-legend-switcher"
                                         onClick={() => setSwitcher('region')}>
                                    {t('ESMap.regions')}
                                </CButton>
                            }
                            <i
                                className="fa fa-arrow-right map-legend-arrow-hide map-legend-arrow-click"
                                aria-hidden="true"
                                onClick={() => showLegend()}
                            />
                        </div>
                    </div>
                }
            </>
            :
            <div className="map-legend-arrow-block">
                <i
                    className="fa fa-arrow-left map-legend-arrow map-legend-arrow-click"
                    aria-hidden="true"
                    onClick={() => showLegend()}
                />
            </div>
    );

    const PopupContent = ({feature}: any) => {
        const marker = feature.properties;

        return (
          <div key={marker.region + marker.name} className="info-popup-block">
              <a className="detail-es-more" href={`/gis-geo-object/${marker.id}`}
                 target="_blank">{t('edit')}</a>

              <p><strong>{t("gis.geoObjects.infoName")}:</strong> {marker.name}</p>
              <p><strong>{t("gis.geoObjects.type")}:</strong> {marker.geo_type_name}</p>
              <p><strong>{t("gis.geoObjects.description")}:</strong> {marker.description}</p>
              {marker?.geo_object_field.length ?
                <>
                    <p><strong>{t("gis.geoObjects.additionalInformation")}:</strong></p>
                    <ul>
                        {marker.geo_object_field.map((field: any) => {
                            return (
                              <li key={field.id}>
                                  <strong>{field.geo_type_field_name}: </strong>
                                  {
                                      field.type === 'date' ? dayjs(field.value).format('DD.MM.YYYY') :
                                        field.type === 'datetime' ? dayjs(field.value).format('DD.MM.YYYY HH:mm') :
                                          field.type === 'boolean' ? (field.value === 'true' ? t('Yes') : t('No')) :
                                            field.value || '-'
                                  }
                              </li>
                            )
                        })}
                    </ul>
                </>
                : null
              }
              <p><strong>{t('Last update at')}:</strong> {dayjs(marker.updated_at).format('YYYY.MM.DD HH:mm')}</p>
              {
                  marker?.images?.length ?
                    <div>
                        <strong>{t("gis.geoObjects.image")}:</strong>
                        <div className="info-popup-img-block">
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '4px'}}>
                                {marker.images.map((file: any, idx: number) => {
                                    const fileParams = getParamsOfFileByUrl(file.image);
                                    let innerJSX;
                                    if (fileParams.type === 'image') {
                                        innerJSX = (
                                          <img alt={""}
                                               src={file.image}
                                               style={{height: '40px', width: 'auto'}}
                                          />
                                        );
                                    }
                                    if (fileParams.type === 'video') {
                                        innerJSX = (
                                          <video id="video" width="auto" height="40">
                                              <source src={file.image} type={`${fileParams.type}/${fileParams.ext}`}/>
                                          </video>
                                        );
                                    }
                                    if (fileParams.type === 'audio') {
                                        innerJSX = (
                                          <i className="fa fa-file-audio-o" aria-hidden="true"
                                             style={{fontSize: '40px'}}/>
                                        );
                                    }
                                    return (
                                      <div key={idx + 'file' + file.emergency}
                                           style={{cursor: 'pointer'}}
                                           onClick={() => onToggleGalleryModal(marker.id, idx)}
                                      >
                                          {innerJSX}
                                      </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    : null
              }
          </div>
        );
    }

    const geoJsonOnEachFeature = (feature: any, layer: any, marker: any) => {
        const popupContent = <PopupContent feature={marker} />;
        const popupContainer = document.createElement("div");
        const root = ReactDOM.createRoot(popupContainer);
        root.render(popupContent);
        layer.bindPopup(popupContainer);
    };

    return (
        <>
            <div className="whole-place-wrapper geo-base">
                {coordinatesVisible ?
                    <div className="map-coordinate">
                        {renderCoordinate}
                    </div>
                    : null
                }
                <MapContainer key={mapKey}
                              center={center ? [coordinates.lat, coordinates.lng] : [41.1262532, 73.79516602]}
                              zoom={7}
                              zoomControl={false}
                              attributionControl={false}
                              style={{height: '100%', position: 'relative'}}
                              id="MapContainer"
                >
                    {
                        (coordinates.lat && coordinates.lng) ?
                            <Marker position={[coordinates.lat, coordinates.lng]}
                                    icon={
                                        L.divIcon({
                                            className: 'coordinates-icon',
                                            html: '<i class="fa fa-dot-circle-o" aria-hidden="true"></i>',
                                            iconSize: [32, 32]
                                        })
                                    }>
                                <Popup>
                                    <div>
                                        <p>Широта:{coordinates.lat}</p>
                                        <p>Долгота: {coordinates.lng}</p>
                                    </div>
                                </Popup>
                            </Marker>
                            : null
                    }
                    <Control position="topleft">
                        <div className="leaflet-control-filter leaflet-control-button"
                             title="Category filter"
                             role="button"
                             aria-label="Category filter"
                             aria-disabled="false"
                            // onClick={e => handleVisible(e, 'tree')}
                             onMouseEnter={() => setVisible(true)}
                        >
                            <i className="fa fa-filter" aria-hidden="true"
                            />
                        </div>
                    </Control>

                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                            <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="OpenStreetMap">
                            <TileLayer url={getTiles('OpenStreetMap')}/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Google.Hybrid">
                            <TileLayer url={getTiles('Google.Hybrid')}/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Esri.WorldStreetMap">
                            <TileLayer url={getTiles('Esri.WorldStreetMap')}/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Esri.WorldGrayCanvas">
                            <TileLayer url={getTiles('Esri.WorldGrayCanvas')}/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Esri.WorldImagery">
                            <TileLayer url={getTiles('Esri.WorldImagery')}/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Esri.WorldShadedRelief">
                            <TileLayer url={getTiles('Esri.WorldShadedRelief')}/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Stamen.Terrain">
                            <TileLayer url={getTiles('Stamen.Terrain')}/>
                        </LayersControl.BaseLayer>
                    </LayersControl>

                    <ZoomControl position="topright"/>

                    <Control position="topright">
                        <a className="leaflet-control-crosshairs leaflet-control-button"
                           href="/"
                           title="Crosshairs"
                           role="button"
                           aria-label="Crosshairs"
                           aria-disabled="false"
                           onClick={e => handleVisible(e, 'coordinates')}
                        >
                            <i className="fa fa-crosshairs" aria-hidden="true"/>
                        </a>
                    </Control>

                    <Control position="topright">
                        {opacityVisible ? renderOpacity() : null}
                        <a className="leaflet-control-layers-satellite leaflet-control-button"
                           href="/"
                           title="Layers satellite"
                           role="button"
                           aria-label="Layers satellite"
                           aria-disabled="false"
                           onClick={e => handleVisible(e, 'opacity')}
                        >
                            <i className="fa fa-adjust" aria-hidden="true"/>
                        </a>
                    </Control>

                    <ScaleControl position="bottomleft"/>

                    {isLoading ? <MapLoader/> : null}

                    <MarkerClusterGroup
                        chunkedLoading
                    >
                        {newState?.map((marker: any, index: any) => {
                            // let copiedCoordinates;

                            if (marker?.geometry?.type === "Point" && marker.properties) {
                                return (
                                  <Marker key={marker.geometry.type + marker.properties.id}
                                          position={[marker?.geometry?.coordinates[1], marker?.geometry?.coordinates[0]]}
                                          icon={new L.Icon({
                                              iconUrl: marker?.properties?.icon,
                                              iconSize: [32, 37],
                                              iconAnchor: [16, 37],
                                              popupAnchor: [0, -32],
                                          })}>
                                      <Popup>
                                          {renderInfoObj(marker)}
                                      </Popup>
                                  </Marker>
                                )
                            } else if (marker?.geometry && marker.properties) {
                                return (
                                  <FeatureGroup key={marker.geometry.type + marker.properties.id}>
                                      <GeoJSON key={marker.geometry.type + marker.properties.id + 'json'}
                                               data={marker.geometry}
                                               onEachFeature={(feature, layer) => geoJsonOnEachFeature(feature, layer, marker)}
                                      />
                                  </FeatureGroup>
                                )
                            }

                            // } else if (marker?.geometry?.type === 'Polygon') {
                            //     console.log(marker?.geometry?.type);
                            //     copiedCoordinates = marker?.geometry?.coordinates?.map((subArray: any) => {
                            //         return subArray.map((coord: any) => coord.slice().reverse());
                            //     });
                            //     if (marker?.geometry?.type === 'Polygon') console.log(copiedCoordinates);
                            //
                            //     return marker && copiedCoordinates && (
                            //         <Polygon
                            //             positions={copiedCoordinates}
                            //             key={marker?.properties.id}
                            //         >
                            //             <Popup>
                            //                 {renderInfoObj(marker)}
                            //             </Popup>
                            //         </Polygon>
                            //     )
                            // } else if (marker?.geometry?.type === 'MultiPolygon') {
                            //     return <GeoJSON data={marker.geometry}/>
                            // }
                        })}
                    </MarkerClusterGroup>

                </MapContainer>
            </div>
            {
                visible ?
                    <div className="geo-base-tree-block" onMouseLeave={() => handleCloseTree()}>
                        <div className="geo-base-tree-title panel-heading">
                            <div
                                className={`geo-base-tree-block-handleLock-block`}
                                onClick={(e) => {
                                    // handleVisible(e, "lock");
                                    setTreeShowAll(!treeShowAll);
                                }}
                                style={{marginLeft: '20px'}}
                            >
                                <i className={`fa fa-${treeShowAll ? 'minus' : 'plus'}`} aria-hidden="true"></i>
                            </div>
                            <p className="panel-title">{t('gis.geoObjects.geoBaseTitle')}</p>
                            <div className="geo-base-tree-block-handleLock"
                            >
                                {/*<div*/}
                                {/*    className={`geo-base-tree-block-handleLock-block`}*/}
                                {/*    onClick={(e) => {*/}
                                {/*        handleVisible(e, "lock");*/}
                                {/*        setTreeShowAll(!treeShowAll);*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <i className={`fa fa-${treeShowAll ? 'minus' : 'plus'}`} aria-hidden="true"></i>*/}
                                {/*</div>*/}
                                <div
                                    className={`geo-base-tree-block-handleLock-block ${visibleLock ? 'active' : ''}`}
                                    onClick={(e) => handleVisible(e, "lock")}
                                >
                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                </div>
                                <div
                                    className={`geo-base-tree-block-handleLock-block ${!visibleLock ? 'active' : ''}`}
                                    onClick={(e) => handleVisible(e, "unlock")}
                                >
                                    <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="panel-body tree-container">
                            {Tree(geoCategories)}
                        </div>
                    </div>
                    : null
            }

            <GalleryModal show={!!showGalleryModal || showGalleryModal === 0}
                          toggle={onToggleGalleryModal}
                          data={galleryModalData}
                          startIndex={showGalleryModal || 0}
            />
            {newState.length ? legend : null}
        </>
    );
};

export default GeoBase;
