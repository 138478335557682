import React, {ReactNode} from 'react';
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import {useTranslation, withTranslation} from "react-i18next";

interface DefaultModalProps {
    visible: boolean;
    setVisible: any;
    backdrop?: boolean;
    title?: string;
    type?: 'info' | 'danger' | 'warning' | 'success';
    size?: 'sm' | 'lg' | 'xl';
    okButton?: string;
    cancelButton?: string;
    onOk?: () => void;
    okDisabled?: boolean;
    error?: string | null;
    children?: ReactNode;
    loading?: any;
    onOkHover?: (isHover?: boolean) => void;
}

const DefaultModal: React.FC<DefaultModalProps> = ({
                                                       visible,
                                                       setVisible,
                                                       backdrop,
                                                       title = '',
                                                       type = 'info',
                                                       size,
                                                       okButton = 'OK',
                                                       cancelButton,
                                                       onOk,
                                                       okDisabled,
                                                       error = '',
                                                       children,
                                                       onOkHover
                                                   }) => {
    const {t} = useTranslation();

    const onOkButtonHoverHandler = (isHover: boolean) => {
      if (onOkHover) onOkHover(isHover);
    };

    return (
        <CModal className={`default-modal default-modal--${type || 'info'}`}
                alignment="center"
                backdrop={backdrop ? undefined : visible ? 'static' : undefined}
                onClose={() => setVisible(false)}
                visible={visible}
                size={size}
        >
            <CModalHeader>
                <CModalTitle>{title || ''}</CModalTitle>
            </CModalHeader>
            <CModalBody className="p-3">
                {children}
            </CModalBody>
            <CModalFooter>
                <div className="modal-error">
                    {error || ''}
                </div>
                <div style={{display: 'flex', columnGap: '1rem'}}>
                    {onOk ?
                      <div onMouseOver={() => onOkButtonHoverHandler(true)} onMouseOut={() => onOkButtonHoverHandler(false)}>
                        <CButton size="sm"
                                 color={type || 'info'}
                                 onClick={onOk}
                                 disabled={okDisabled}
                                 // className="mx-3"
                        >
                            {okButton || 'OK'}
                        </CButton>
                      </div>
                        : null}
                    <CButton size="sm"
                             color={onOk ? 'dark' : type || 'info'}
                             onClick={() => setVisible(false)}
                    >
                        {cancelButton || t('cancel')}
                    </CButton>
                </div>
            </CModalFooter>
        </CModal>
    );
};

export default withTranslation()(DefaultModal);
