import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const emergencyMessagesApi = createApi({
    reducerPath: 'emergencyMessages/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['emergencyMessages'],
    endpoints: build => ({
        getEsList: build.query<any, string | void>({
            query: (query) => {
                return {
                    url: `emergency/message/list/${query || ''}`
                }
            },
            providesTags: ['emergencyMessages'],
        }),
        updateEsStatus: build.mutation<any, any>({
            query: ({body, id}) => {
                return {
                    url: `emergency/message/change/status/${id}/`,
                    method: 'PATCH',
                    body
                }
            },
            invalidatesTags: ['emergencyMessages'],
        }),

    })
})

export const {
    useLazyGetEsListQuery,
    useUpdateEsStatusMutation
} = emergencyMessagesApi