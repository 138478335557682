import React, {useEffect, useState} from 'react';
import {
  CFormInput,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableDataCell,
  CButtonGroup,
  CButton,
  CForm,
  CRow,
  CCol,
  CFormLabel,
  CCard,
  CCardHeader,
  CCardBody
} from "@coreui/react";

import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import FormGroup from "../../../components/UI/Forms/FormGroup";

import {Query} from "../../../functions";
// import {createUserGroup, deleteUserGroup, fetchUserGroups, updateUserGroup} from "../../../store/actions/userGroups";
// import {fetchPermissionsList} from "../../../store/actions/permissions";
import {useTranslation} from "react-i18next";
import {useLazyGetPermissionsQuery} from "../../../store/permissions/permissions.api";
import {
  useCreateGroupMutation,
  useLazyGetGroupQuery,
  useRemoveGroupMutation,
  useUpdateGroupMutation
} from "../../../store/group/group.api";

const GroupsList = () => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();

  const [fetchPermissions, {isLoading, isError, data: permissions }] = useLazyGetPermissionsQuery();
  const [fetchUserGroups, {data: userGroups}] = useLazyGetGroupQuery();
  const [updateUserGroup] =  useUpdateGroupMutation();
  const [createUserGroup] = useCreateGroupMutation();
  const [deleteUserGroup] =  useRemoveGroupMutation();

  // const userGroups = useSelector(state => state.userGroups.userGroups);
  // const permissions = useSelector(state => state.permissions.permissions);

  const [filter, setFilter]: any = useState({sort_by: '', direction: 'asc'});
  const [editModalVisible, setEditModalVisible]: any = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editingGroup, setEditingGroup]: any = useState({});
  const [multiSelection, setMultiSelection]: any = useState({available: [], selected: []});
  const [selectedPermissions, setSelectedPermissions]: any = useState([]);
  const [error, setError]: any = useState(null);

  useEffect(() => {
    fetchPermissions('');
    fetchUserGroups('');
    // dispatch(fetchUserGroups());
    // dispatch(fetchPermissionsList());
    // eslint-disable-next-line
  }, []);

  const onChangeHandler = (event: any) => {
    const state: any = {...editingGroup};
    state[event.target.name] = event.target.value;
    setEditingGroup(state);
  };

  const onChangeMultiselectHandler = (name: string, id: number) => {
    if (!name || !id) return null;
    const selected = Array.isArray(multiSelection[name]) ? [...multiSelection[name]] : [];
    if (selected.includes(id)) selected.splice(selected.findIndex(el => el === id), 1);
    else selected.push(id);
    setMultiSelection({...multiSelection, [name]: selected});
  }

  const onSelectPermissions = (action: string) => {
    let state: any = [...selectedPermissions];
    switch (action) {
      case 'add-all':
        // @ts-ignore
        state = permissions?.map((el: any) => el.id);
        break;
      case 'remove-all':
        state = [];
        break;
      case 'add':
        multiSelection.available.forEach((id: any) => { state.push(id) });
        break;
      case 'remove':
        multiSelection.selected.forEach((id: any) => { state.splice(state.findIndex((el: any) => el === id), 1) });
        break;
      default:
        return null;
    }
    setMultiSelection({available: [], selected: []})
    setSelectedPermissions(state);
  };

  const toggleEditModalHandler = (visible?: boolean, data?: any) => {
    if (data && data.id) {
      setEditingGroup(data);
      setSelectedPermissions(data.permissions || []);
    }
    else {
      setTimeout(() => {
        setEditingGroup({});
      }, 200);
    }
    setError(null);
    setEditModalVisible(!!visible);
  };

  const toggleDeleteModalHandler = (visible?: boolean, data?: any) => {
    if (data && data.id) setEditingGroup(data);
    else {
      setTimeout(() => {
        setEditingGroup({});
      }, 200);
    }
    setError(null);
    setDeleteModalVisible(!!visible);
  };

  const onFilterHandler = (field: any) => {
    const state: any = {...filter}, query: any = {};
    if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
    else {
      state.sort_by = field;
      state.direction = 'asc';
    }
    setFilter(state);

    if (state.sort_by) query.sort_by = state.sort_by;
    if (state.direction === 'desc') query.direction = 'desc';
    fetchUserGroups(Query.stringify(query));
  };

  const onEditUserGroupHandler = () => {
    const data: any = {};
    if (editingGroup.name) data.name = editingGroup.name;
    else {
      setError(`"${t('settings.groups.name')}" - ${t('Should not be empty')}`);
      return null;
    }
    if (selectedPermissions.length) {
      data.permissions = selectedPermissions;
    } else {
      setError(`"${t('settings.groups.permissions')}" - ${t('Should not be empty')}`);
      return null;
    }

    if (editingGroup.id) {
      updateUserGroup({body: data, id: editingGroup.id}).then(
          () => {
            onFilterHandler(filter.sort_by);
            toggleEditModalHandler();
          },
          () => {
            setError('Something went wrong')
          }
      )
      // updateUserGroup(
      //   editingGroup.id,
      //   data,
      //   () => {
      //     onFilterHandler(filter.sort_by);
      //     toggleEditModalHandler();
      //   },
      //   () => { setError('Something went wrong') }
      // );
    } else {
      createUserGroup(data).then(
          () => {
            onFilterHandler(filter.sort_by);
            toggleEditModalHandler();
          },
          () => {
            setError('Something went wrong')
          }
      )
      // dispatch(createUserGroup(
      //   data,
      //   () => {
      //     onFilterHandler(filter.sort_by);
      //     toggleEditModalHandler();
      //   },
      //   () => { setError('Something went wrong') }
      // ));
    }
  };

  const onDeleteUserGroupHandler = () => {
    if (!editingGroup.id) return null;
    deleteUserGroup(editingGroup.id).then(
        () => {
          onFilterHandler(filter.sort_by);
          toggleDeleteModalHandler();
        },
        () => {
          setError('Something went wrong')
        }
    )
    // dispatch(deleteUserGroup(
    //   editingGroup.id,
    //   () => {
    //     onFilterHandler(filter.sort_by);
    //     toggleDeleteModalHandler();
    //   },
    //   () => { setError('Something went wrong') }
    // ));
  };

  let groupsList = [];
  try {
    // @ts-ignore
    groupsList = userGroups?.map((el: any) => {
      // @ts-ignore
      const permsList = permissions?.filter((perm: any) => el.permissions.includes(perm.id))
        .map((perm: any) => (
          <p key={'perm' + el.id + '-' + perm.id} style={{margin: 0, fontStyle: 'italic', fontSize: '0.75rem'}}>{perm.name}</p>
        ));
      return (
        <CTableRow key={'userGroup' + el.id}>
          <CTableDataCell>{el.name}</CTableDataCell>
          <CTableDataCell>
            {permsList}
          </CTableDataCell>
          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">
              <CButton color="info" className="default-table__action-btn" onClick={() => toggleEditModalHandler(true, el)}>
                <i className="fa fa-pencil-square-o color-white" aria-hidden="true"> </i>
              </CButton>
              <CButton color="danger" className="default-table__action-btn" onClick={() => toggleDeleteModalHandler(true, el)}>
                <i className="fa fa-times color-white" aria-hidden="true"> </i>
              </CButton>
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  } catch (e) {}

  let availablePermissionsList: any[] = [], selectedPermissionsList: any[] = [];
  try {
    // @ts-ignore
    permissions?.forEach((el: any) => {
      const isSelected = (multiSelection.available.includes(el.id) || multiSelection.selected.includes(el.id))
      if (selectedPermissions.includes(el.id))
        selectedPermissionsList.push(
          <div key={'permission' + el.id}
               className={`multi-select-item${isSelected ? ' selected' : ''}`}
               onClick={() => onChangeMultiselectHandler('selected', el.id)}
          >
            {el.name}
          </div>
        );
      else availablePermissionsList.push(
        <div key={'permission' + el.id}
             className={`multi-select-item${isSelected ? ' selected' : ''}`}
             onClick={() => onChangeMultiselectHandler('available', el.id)}
        >
          {el.name}
        </div>);
    })
  } catch (e) {}

  return (
    <div className="pb-3 mb-4 title-divider">
      <h1>Permission denied</h1>
    </div>
  );

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('settings.groups.groupsList')}</h1>
      </div>

      <div className="d-flex justify-content-md-end mb-3">
        <CButton size="sm"
                 color="success"
                 className="color-white"
                 onClick={() => toggleEditModalHandler(true)}
        >
          {t('settings.groups.addGroup')}
        </CButton>
      </div>

      <CTable bordered striped className="default-table">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => onFilterHandler('name')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('settings.groups.groupName')}</span>
                {filter.sort_by === 'name' ?
                  <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '67%'}} scope="col">
              <span>{t('settings.groups.permissions')}</span>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '13%'}} scope="col" className="default-table__actions">
              {t('settings.groups.actions')}
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>

        <CTableBody>
          {groupsList}
        </CTableBody>
      </CTable>

      <DefaultModal visible={editModalVisible}
                    setVisible={toggleEditModalHandler}
                    title={t(`settings.groups.${editingGroup.id ? 'editGroup' : 'addGroup'}`)}
                    cancelButton={t('cancel')}
                    okButton={t('save')}
                    onOk={onEditUserGroupHandler}
                    error={error}
                    size="xl"
      >
        <CForm>
          <FormGroup htmlFor='name' label={t('settings.groups.name')}>
            <CFormInput id="name"
                        name="name"
                        type="text"
                        value={editingGroup.name || ''}
                        onChange={onChangeHandler}
            />
          </FormGroup>

          <CCard className="mb-3">
            <CCardHeader>
              <div className="card-header-title">{t('settings.groups.permissions')}</div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <CFormLabel className="col-form-label">
                    {t('settings.groups.available')}
                  </CFormLabel>
                </CCol>
                <CCol sm={2}/>
                <CCol sm={5}>
                  <CFormLabel className="col-form-label">
                    {t('settings.groups.selected')}
                  </CFormLabel>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={5}>
                  <div className="multi-select-list">
                    {availablePermissionsList}
                  </div>
                </CCol>
                <CCol sm={2} className="button-group-vertical button-group-vertical--navigation">
                  <CButton size="sm"
                           color="info"
                           className="color-white non-outline"
                           onClick={() => onSelectPermissions('add-all')}
                  >
                    <i className="fa fa-forward" aria-hidden="true"/>
                  </CButton>
                  <CButton size="sm"
                           color="info"
                           className="color-white non-outline"
                           onClick={() => onSelectPermissions('add')}
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true"/>
                  </CButton>
                  <CButton size="sm"
                           color="info"
                           className="color-white non-outline"
                           onClick={() => onSelectPermissions('remove')}
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true"/>
                  </CButton>
                  <CButton size="sm"
                           color="info"
                           className="color-white non-outline"
                           onClick={() => onSelectPermissions('remove-all')}
                  >
                    <i className="fa fa-backward" aria-hidden="true"/>
                  </CButton>
                </CCol>
                <CCol sm={5}>
                  <div className="multi-select-list">
                    {selectedPermissionsList}
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CForm>
      </DefaultModal>

      <DefaultModal visible={deleteModalVisible}
                    setVisible={toggleDeleteModalHandler}
                    type="danger"
                    title={t('settings.groups.deleteUserGroup')}
                    cancelButton={t('cancel')}
                    okButton={t('delete')}
                    onOk={onDeleteUserGroupHandler}
      >
        <p>{t('settings.groups.Do you really want to delete a user group?')}</p>
        <p><strong>{editingGroup.name}</strong></p>
      </DefaultModal>
    </>
  );
};

export default GroupsList;
