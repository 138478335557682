import React from 'react';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {useGetUrgentNotificationsQuery} from "../../store/urgentNotifications/urgentNotifications.api";

const UrgentNotifications = () => {
  const {t} = useTranslation();

  const {data} = useGetUrgentNotificationsQuery();

  if (!data?.length || !data.some(el => new Date(el.datetime) > new Date())) return null;

  return (
    <div className="urgent-notifications">
      <div className="urgent-notifications__wrapper">
        {data?.map((notification) => {
          if (new Date(notification.datetime) > new Date()) return (
            <p key={'urgentNotification' + notification.id} className="urgent-notifications__notification">
              <span>{notification.description}</span>
              <span>{t('Relevant until')}: {dayjs(notification.datetime).format('DD.MM.YYYY HH:mm')}</span>
            </p>
          );
          return null;
        })}
      </div>
    </div>
  );
};

export default UrgentNotifications;
