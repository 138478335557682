import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardTitle,
  CCollapse,
  CFormInput,
  CFormLabel,
  CFormSelect,
} from "@coreui/react";
import {useTranslation} from "react-i18next";

import DefaultModal from "../../../components/UI/Modals/DefaultModal";

import {useCreateRegionMutation, useGetRegionsQuery, useRemoveRegionMutation, useUpdateRegionMutation} from "../../../store/regions/regions.api";
import {useCreateDistrictMutation, useGetDistrictsQuery, useRemoveDistrictMutation, useUpdateDistrictMutation} from "../../../store/districts/districts.api";
import {useCreateCountyMutation, useGetCountiesQuery, useRemoveCountyMutation, useUpdateCountyMutation} from "../../../store/counties/counties.api";
import {useCreateLocalityMutation, useGetLocalitiesQuery, useRemoveLocalityMutation, useUpdateLocalityMutation} from "../../../store/localities/localities.api";

import {IRegionResponse} from "../../../interfaces/IRegions";
import {IDistrictResponse} from "../../../interfaces/IDistricts";
import {ICountyResponse} from "../../../interfaces/ICounties";
import {ILocalityResponse} from "../../../interfaces/ILocalities";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

interface ITreeCounties extends ICountyResponse {
  children: ILocalityResponse[]
}
interface ITreeDistricts extends IDistrictResponse {
  children: ITreeCounties[]
}
interface ITree extends IRegionResponse {
  children: ITreeDistricts[]
}

type TState = {
  locationType: string,
  region: string | undefined,
  district: string | undefined,
  county: string | undefined,
  locationTitle: string,
  soatoClassifier: string
}

const Regions = () => {
  const { t, i18n } = useTranslation();

  const [expRegions, setExpRegions] = useState<{[key: number]: boolean}>({});
  const [expDistricts, setExpDistricts] = useState<{[key: number]: boolean}>({});
  const [expCounties, setExpCounties] = useState<{[key: number]: boolean}>({});
  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [state, setState] = useState<TState>({
    locationType: '',
    region: '',
    district: '',
    county: '',
    locationTitle: '',
    soatoClassifier: ''
  });
  const [selectedId, setSelectedId] = useState(null);
  const [selectedType, setSelectedType] = useState('')
  const [error, setError] = useState('');

  const {isError: regionsError,  isLoading: regionsLoading, data: regions} = useGetRegionsQuery();
  const [createRegion, {isError: createRegionError,  isLoading: createRegionLoading}] = useCreateRegionMutation();
  const [updateRegion, {isError: updateRegionError,  isLoading: updateRegionLoading}] = useUpdateRegionMutation();
  const [deleteRegion, {isError: deleteRegionError,  isLoading: deleteRegionLoading}] = useRemoveRegionMutation();
  const {isError: districtsError,  isLoading: districtsLoading, data: districts} = useGetDistrictsQuery();
  const [createDistrict, {isError: createDistrictError,  isLoading: createDistrictLoading}] = useCreateDistrictMutation();
  const [updateDistrict, {isError: updateDistrictError,  isLoading: updateDistrictLoading}] = useUpdateDistrictMutation();
  const [deleteDistrict, {isError: deleteDistrictError,  isLoading: deleteDistrictLoading}] = useRemoveDistrictMutation();
  const {isError: countiesError,  isLoading: countiesLoading, data: counties} = useGetCountiesQuery();
  const [createCounty, {isError: createCountyError,  isLoading: createCountyLoading}] = useCreateCountyMutation();
  const [updateCounty, {isError: updateCountyError,  isLoading: updateCountyLoading}] = useUpdateCountyMutation();
  const [deleteCounty, {isError: deleteCountyError,  isLoading: deleteCountyLoading}] = useRemoveCountyMutation();
  const {isError: localitiesError,  isLoading: localitiesLoading, data: localities} = useGetLocalitiesQuery();
  const [createLocality, {isError: createLocalityError,  isLoading: createLocalityLoading}] = useCreateLocalityMutation();
  const [updateLocality, {isError: updateLocalityError,  isLoading: updateLocalityLoading}] = useUpdateLocalityMutation();
  const [deleteLocality, {isError: deleteLocalityError,  isLoading: deleteLocalityLoading}] = useRemoveLocalityMutation();

  useEffect(() => {
    setError('Something went wrong');
  }, [
    regionsError, createRegionError, updateRegionError, deleteRegionError,
    districtsError, createDistrictError, updateDistrictError, deleteDistrictError,
    countiesError, createCountyError, updateCountyError, deleteCountyError,
    localitiesError, createLocalityError, updateLocalityError, deleteLocalityError
  ]);

  const isLoading = useMemo(() => {
    return regionsLoading || createRegionLoading || updateRegionLoading || deleteRegionLoading ||
      districtsLoading || createDistrictLoading || updateDistrictLoading || deleteDistrictLoading ||
      countiesLoading || createCountyLoading || updateCountyLoading || deleteCountyLoading ||
      localitiesLoading || createLocalityLoading || updateLocalityLoading || deleteLocalityLoading;
  }, [
    regionsLoading, createRegionLoading, updateRegionLoading, deleteRegionLoading,
    districtsLoading, createDistrictLoading, updateDistrictLoading, deleteDistrictLoading,
    countiesLoading, createCountyLoading, updateCountyLoading, deleteCountyLoading,
    localitiesLoading, createLocalityLoading, updateLocalityLoading, deleteLocalityLoading
  ]);

  const tree: ITree[] = useMemo(() => {
    if (regions && districts && counties && localities && !isLoading) {
      return regions.map(r => ({
        ...r, children: districts.filter(d => d.region === r.id).map(d => ({
          ...d, children: counties.filter(c => c.district === d.id).map(c => ({
            ...c, children: localities.filter(l => l.county === c.id)
          }))
        }))
      }));
    }
    return [];
  }, [regions, districts, counties, localities, isLoading]);

  const handleToggleModal = (visible: boolean) => {
    if (!visible) {
      setState({
        locationType: '',
        region: '',
        district: '',
        county: '',
        locationTitle: '',
        soatoClassifier: ''
      })
      setSelectedId(null);
      setSelectedType('');
    }
    setVisible(visible);
    setError('');
  }

  const handleToggleDeleteModal = (visible: boolean) => {
    if (!visible) {
      setSelectedId(null);
      setSelectedType('');
      setError('');
    }
    setDeleteModal(visible)
  }

  const handleOpenEditModal = (visible: boolean, item: any, type: string) => {
    const innerState = {
      ...state,
      locationType: type,
      locationTitle: item.name,
      soatoClassifier: item.coat_code
    };

    switch (type) {
      case 'district':
        innerState.region = item.region;
        break;
      case 'county':
        innerState.district = item.district;
        innerState.region = String(districts?.find(el => String(el.id) === String(innerState.district))?.region);
        break;
      case 'locality':
        innerState.county = item.county;
        innerState.district = String(counties?.find(el => String(el.id) === String(innerState.county))?.district);
        innerState.region = String(districts?.find(el => String(el.id) === String(innerState.district))?.region);
        break;
      default:
        // do nothing
    }

    setState(innerState);
    setSelectedId(item.id);
    handleToggleModal(visible);
  }

  const handleOpenDeleteModal = (item: any, type: string) => {
    setDeleteModal(true);
    setSelectedId(item.id);
    setSelectedType(type);
  }

  const handleEdit = () => {
    const body: any = {
      coat_code: (state.soatoClassifier + '000000000').substring(0, 14),
      name: state.locationTitle
    };

    switch (state.locationType) {
      case 'region':
        if (selectedId || selectedId === 0) updateRegion({id: selectedId, body}).then(() => handleToggleModal(false));
        else createRegion(body).then(() => handleToggleModal(false));
        break;
      case 'district':
        body.region = Number(state.region);
        if (selectedId || selectedId === 0) updateDistrict({id: selectedId, body}).then(() => handleToggleModal(false));
        else createDistrict(body).then(() => handleToggleModal(false));
        break;
      case 'county':
        body.district = Number(state.district);
        if (selectedId || selectedId === 0) updateCounty({id: selectedId, body}).then(() => handleToggleModal(false));
        else createCounty(body).then(() => handleToggleModal(false));
        break;
      case 'locality':
        body.county = Number(state.county);
        if (selectedId || selectedId === 0) updateLocality({id: selectedId, body}).then(() => handleToggleModal(false));
        else createLocality(body).then(() => handleToggleModal(false));
        break;
      default:
      // do nothing
    }
  }

  const handleDelete = () => {
    switch (selectedType) {
      case 'region':
        deleteRegion(Number(selectedId)).then(() => handleToggleDeleteModal(false));
        break;
      case 'district':
        deleteDistrict(Number(selectedId)).then(() => handleToggleDeleteModal(false));
        break;
      case 'county':
        deleteCounty(Number(selectedId)).then(() => handleToggleDeleteModal(false));
        break;
      case 'locality':
        deleteLocality(Number(selectedId)).then(() => handleToggleDeleteModal(false));
        break;
      default:
        // do nothing
    }
  }

  const handleToggleCollapse = (event: any, id: number, type: string) => {
    event.preventDefault();
    // setVisibleCollapse({...visibleCollapse, [id]: !visibleCollapse[id]})
    if (type === 'region') {
      setExpCounties({});
      setExpDistricts({});
      setExpRegions(prev => ({...prev, [id]: !prev[id]}));
    }
    if (type === 'district') {
      setExpCounties({});
      setExpDistricts(prev => ({...prev, [id]: !prev[id]}));
    }
    if (type === 'county') {
      setExpCounties(prev => ({...prev, [id]: !prev[id]}));
    }
  }

  const handleChange = (event: any) => {
    setState(prevState => {
      return {...prevState, [event.target.name]: event.target.value}
    });
  }

  const formValidate = () => {
    let isValid = !!(state.locationType && state.locationTitle && state.soatoClassifier);
    switch (state.locationType) {
      case 'district':
        isValid = isValid && !!state.region;
        break;
      case 'county':
        isValid = isValid && !!state.region && !!state.district;
        break;
      case 'locality':
        isValid = isValid && !!state.region && !!state.district && !!state.county;
        break;
      default:
        // do nothing
    }
    return isValid;
  }

  const renderTreeRow = (region: ITree) => {
    return (
      <>
        <div className="regions-collapse">
          <i className={`fa ${expRegions[region.id] ? 'fa-caret-down' : 'fa-caret-right'} caret-icon`}
             onClick={(e) => handleToggleCollapse(e, region.id, 'region')}
          />
          <div className="region-wrapper">
            <i className="fa fa-folder folder-icon"/>
            {region.coat_code} - {region.name}
            <i className="fa fa-pencil-square-o region-action-active region-edit-icon"
               onClick={() => handleOpenEditModal(true, region, 'region')}
            />
            <i className="fa fa-times region-action-active region-delete-icon"
               onClick={() => handleOpenDeleteModal(region, 'region')}
            />
          </div>
        </div>
        <CCollapse visible={expRegions[region.id]}>
          {region.children.map(d => {
            return (
              <Fragment key={'district' + d.id}>
                <div className="district-wrapper">
                  <i className={`fa ${expDistricts[d.id] ? 'fa-caret-down' : 'fa-caret-right'} caret-icon`}
                     onClick={(e) => handleToggleCollapse(e, d.id, 'district')}
                  />
                  <i className="fa fa-code-fork folder-icon"/>
                  {d.coat_code} - {d.name}
                  <i className="fa fa-pencil-square-o region-action-active region-edit-icon"
                     onClick={() => handleOpenEditModal(true, d, 'district')}
                  />
                  <i className="fa fa-times region-action-active region-delete-icon"
                     onClick={() => handleOpenDeleteModal(d, 'district')}
                  />
                </div>
                <CCollapse visible={expDistricts[d.id]}>
                  {d.children.map(c => {
                    return (
                      <Fragment key={'county' + c.id}>
                        <div className="county-wrapper">
                          <i className={`fa ${expCounties[c.id] ? 'fa-caret-down' : 'fa-caret-right'} caret-icon`}
                             onClick={(e) => handleToggleCollapse(e, c.id, 'county')}
                          />
                          <i className="fa fa-code-fork folder-icon"/>
                          {c.coat_code} - {c.name}
                          <i className="fa fa-pencil-square-o region-action-active region-edit-icon"
                             onClick={() => handleOpenEditModal(true, c, 'county')}
                          />
                          <i className="fa fa-times region-action-active region-delete-icon"
                             onClick={() => handleOpenDeleteModal(c, 'county')}
                          />
                        </div>
                        <CCollapse visible={expCounties[c.id]}>
                          {c.children.map(l => {
                            return (
                              <Fragment key={'locality' + l.id}>
                                <div className="locality-wrapper">
                                  <i className="fa fa-code-fork folder-icon"/>
                                  {l.coat_code} - {l.name}
                                  <i className="fa fa-pencil-square-o region-action-active region-edit-icon"
                                     onClick={() => handleOpenEditModal(true, l, 'locality')}
                                  />
                                  <i className="fa fa-times region-action-active region-delete-icon"
                                     onClick={() => handleOpenDeleteModal(l, 'locality')}
                                  />
                                </div>
                              </Fragment>
                            )
                          })}
                        </CCollapse>
                      </Fragment>
                    )
                  })}
                </CCollapse>
              </Fragment>
            )
          })}
        </CCollapse>
      </>
    )
  };

  const regionsOptions = useMemo(() => {
    const res = [{label: t('settings.regions.selectRegion'), value: ''}];
    tree.forEach(el => { res.push({ label: el.name, value: String(el.id) }) });
    return res;
  }, [tree, i18n.language, t]);

  const districtsOptions = useMemo(() => {
    const res = [{label: t('settings.regions.selectDistrict'), value: ''}];
    tree.find(r => String(r.id) === String(state.region))?.children.forEach(el => { res.push({ label: el.name, value: String(el.id) }) });
    return res;
  }, [tree, state.region, i18n.language, t]);

  const countiesOptions = useMemo(() => {
    const res = [{label: t('settings.regions.selectCounty'), value: ''}];
    tree.find(r => String(r.id) === String(state.region))?.children.find(d => String(d.id) === String(state.district))?.children.forEach(el => { res.push({ label: el.name, value: String(el.id) }) });
    return res;
  }, [tree, state.district, i18n.language, t]);

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('settings.regions.regions')}</h1>
        <LoaderLine visible={isLoading}/>
      </div>

      <CCard className="p-3">
        <CCardTitle>
          <CButton size="sm"
                   color="info"
                   className="color-white mb-2"
                   onClick={() => handleToggleModal(true)}
          >
            {t('settings.regions.addLocation')}
          </CButton>
        </CCardTitle>
        <CCardBody className="p-0">
          <CCard>
            <CCardBody>
              {
                tree.map(r => (
                  <div key={'regionRow' + r.id} className="regions-collapse-wrapper">
                    {renderTreeRow(r)}
                  </div>
                ))
              }
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>

      <DefaultModal visible={visible}
                    setVisible={handleToggleModal}
                    title={selectedId ? t('settings.regions.editLocation') : t('settings.regions.addLocation')}
                    type="info"
                    cancelButton={t('cancel')}
                    onOk={handleEdit}
                    okDisabled={!formValidate()}
                    size="xl"
                    error={error}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <CFormLabel className="regions-label">{t('settings.regions.locationType')}</CFormLabel>
          <CFormSelect aria-label="Default select example"
                       size="sm"
                       value={state.locationType}
                       name="locationType"
                       onChange={handleChange}
                       options={[
                         {label: t('settings.regions.selectType'), value: ''},
                         {label: t('settings.regions.region'), value: 'region'},
                         {label: t('settings.regions.district'), value: 'district'},
                         {label: t('settings.regions.county'), value: 'county'},
                         {label: t('settings.regions.locality'), value: 'locality'},
                       ]}
                       className="regions-modal-input"
                       disabled={selectedId === 0 || !!selectedId}
          />
        </div>
        {(state.locationType && state.locationType !== 'region') ?
          <div className="d-flex justify-content-between align-items-center mb-3">
            <CFormLabel className="regions-label">{t('settings.regions.region')}</CFormLabel>
            <CFormSelect aria-label="Default select example"
                         size="sm"
                         value={state.region}
                         name="region"
                         onChange={handleChange}
                         options={regionsOptions}
                         className="regions-modal-input"
                         disabled={!!selectedId}
            />
          </div> : null}
        {(state.locationType === 'county' || state.locationType === 'locality') ?
          <div className="d-flex justify-content-between align-items-center mb-3">
            <CFormLabel className="regions-label">{t('settings.regions.district')}</CFormLabel>
            <CFormSelect aria-label="Default select example"
                         size="sm"
                         value={state.district}
                         name="district"
                         onChange={handleChange}
                         options={districtsOptions}
                         className="regions-modal-input"
                         disabled={!!selectedId || !state.region}
            />
          </div> : null}
        {state.locationType === 'locality' ?
          <div className="d-flex justify-content-between align-items-center mb-3">
            <CFormLabel className="regions-label">{t('settings.regions.county')}</CFormLabel>
            <CFormSelect aria-label="Default select example"
                         size="sm"
                         value={state.county}
                         name="county"
                         onChange={handleChange}
                         options={countiesOptions}
                         className="regions-modal-input"
                         disabled={!!selectedId || !state.district}
            />
          </div> : null}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <CFormLabel className="regions-label">{t('settings.regions.locationTitle')}</CFormLabel>
          <CFormInput type="text"
                      value={state.locationTitle}
                      name="locationTitle"
                      onChange={handleChange}
                      size="sm"
                      className="regions-modal-input"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <CFormLabel className="regions-label">{t('settings.regions.soatoClassifier')}</CFormLabel>
          <span style={{width: '70%'}}>
            <CFormInput type="text"
                        value={state.soatoClassifier}
                        name="soatoClassifier"
                        onChange={handleChange}
                        size="sm"
                        className="regions-modal-input"
                        style={{width: '100%'}}
            />
          </span>
        </div>
      </DefaultModal>

      <DefaultModal visible={deleteModal}
                    setVisible={handleToggleDeleteModal}
                    title={t('deletion')}
                    type="danger"
                    cancelButton={t('cancel')}
                    onOk={handleDelete}
                    // @ts-ignore
                    error={selectedType === 'region' ? regionsError?.data?.error : districtsError?.data?.error}
                    size="lg"
      >
        <div>
          <p className="mb-0 fs-6">
            {
              selectedType === 'region' ? t('settings.regions.regionDeleting') :
                selectedType === 'district' ? t('settings.regions.districtDeleting') :
                  selectedType === 'county' ? t('settings.regions.countyDeleting') :
                    t('settings.regions.localityDeleting')
            }
          </p>
          <p><strong>
            {
              selectedType === 'region' ? regions?.find(el => el.id === selectedId)?.name || '' :
                selectedType === 'district' ? districts?.find(el => el.id === selectedId)?.name || '' :
                  selectedType === 'county' ? counties?.find(el => el.id === selectedId)?.name || '' :
                    localities?.find(el => el.id === selectedId)?.name || ''
            }
          </strong></p>
        </div>
      </DefaultModal>
    </>
  );
};

export default Regions;
