import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {
    CButton,
    CButtonGroup, CCard, CCardBody, CCardHeader, CCollapse, CFormCheck, CFormInput, CFormSelect, CFormTextarea,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";

import {getFormData} from "../../../functions";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import FormGroup from "../../../components/UI/Forms/FormGroup";

import {useLazyGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useLazyGetRegionsQuery} from "../../../store/regions/regions.api";
import {
  useCreateGeoObjectMutation,
  useLazyGetGeoObjectsQuery, useRemoveGeoObjectMutation,
  useUpdateGeoObjectMutation
} from "../../../store/geoObjects/geoObjects.api";
import {useGetGeoTypesQuery} from "../../../store/geoTypes/geoTypes.api";

import makeQuery from "../../../functions/makeQuery";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

const GeoObjects = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [fetchGeoObjects, {isError: geoObjectsError, isLoading: geoObjectsLoading, isFetching: geoObjectsFetching, data: geoObjects}] = useLazyGetGeoObjectsQuery();
    const [updateGeoObject] = useUpdateGeoObjectMutation();
    const [createGeoObject] = useCreateGeoObjectMutation();
    const [deleteGeoObject] = useRemoveGeoObjectMutation();

    const { data: geoTypes } = useGetGeoTypesQuery({ query: ''});

    const [fetchRegions, {isError: regionsError, isLoading: regionsLoading, data: regions}] = useLazyGetRegionsQuery();
    const [fetchDistricts, {isError: districtsError, isLoading: districtsLoading, data: districts}] = useLazyGetDistrictsQuery();

    const [pagination, setPagination] = useState({page_size: '100', search: '', page: 1});
    const [filter, setFilter] = useState({sort_by: 'name', direction: 'asc'});
    const [visible, setVisible] = useState(false);
    const [addVisible, setAddVisible] = useState<any[]>([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [state, setState]: any = useState(
        {
            name: '',
            description: '',
            geo_type: '',
            region: '',
            district: '',
            latitude: '',
            longitude: ''
        }
    );
    const [additionalInformation, setAdditionalInformation]: any = useState({});
    const [deletedImages, setDeletedImages]: any = useState([]);
    const [error, setError] = useState('')
    const countGeoObjects: number | undefined = geoObjects?.results.length;

    useEffect(() => {
        fetchGeoObjects('');
        fetchRegions('');
        fetchDistricts('');
    }, []);

    useEffect(() => {
        let array: any[] = []
        if (geoObjects?.results?.length) {
            array = geoObjects.results.map(el => {
                return {id: el.id, value: false}
            })
            setAddVisible(array)
        }
    }, [geoObjects]);

    const isLoading = useMemo(() => {
        return (geoObjectsLoading || regionsLoading || districtsLoading || geoObjectsFetching);
    }, [geoObjectsLoading, regionsLoading, districtsLoading, geoObjectsFetching]);

    const handleClickToAdditionalInformation = (id: number) => {
        // @ts-ignore
        setAddVisible(prevState => prevState.map((el: any) => {
            if (el.id === id) {
                return {...el, value: !el.value}
            }
            return {...el}
        }))
    }

    const handleChange = (event: any) => {
        if (event.target.name === 'geo_type') manageAdditionalInformation(event);

        setState((prevState: any) => {
            return {...prevState, [event.target.name]: event.target.value}
        });
    }

    const manageAdditionalInformation = (event: any) => {
        let fieldsArray: any[] = [], fieldsData;

        if (geoTypes) {
            geoTypes?.results?.forEach((el: any) => {
                if (el.id === parseInt(event.target.value, 10)) {
                    fieldsArray = el.geo_type_field.map((elem: any) => {
                        let data: any = {}
                        data['type'] = elem.field_type;
                        data['id'] = elem.id;
                        switch (elem.field_type) {
                            case 'boolean':
                                data['value'] = {value: false};
                                break;
                            case 'image':
                                data['value'] = {value: ''};
                                break;
                            case 'date':
                                data['value'] = {value: moment(new Date()).format('YYYY-MM-DD')};
                                break;
                            case 'time':
                                data['value'] = {value: moment(new Date()).format('HH:mm')};
                                break;
                            case 'datetime':
                                data['value'] = {value: moment(new Date()).format('YYYY-MM-DDTHH:mm')};
                                break;
                            default:
                                data['value'] = {value: ''};
                        }
                        return data;
                    })
                }
            })
        }

        fieldsData = fieldsArray.reduce((a, b) => ({...a, [b.type + '-' + b.id]: b.value}), {});
        setAdditionalInformation(fieldsData);
    }

    const handleAdditionalInformationChange = (event: any, type: string) => {
        let addInfo: any = {...additionalInformation};

        if (type === 'image') {
            if (!addInfo[event.target.name]) {
                addInfo[event.target.name].value = [...event.target.files];
            } else addInfo[event.target.name].value = [...addInfo[event.target.name].value, ...event.target.files];
            setAdditionalInformation(addInfo);
        } else if (type === 'boolean') {
            addInfo[event.target.name].value = !additionalInformation[event.target.name].value
            setAdditionalInformation(addInfo);
        } else {
            addInfo[event.target.name].value = event.target.value;
            setAdditionalInformation(addInfo);
        }
    }

    const handleToggleModal = (visible: boolean) => {
        setTimeout(() => {
            if (visible === false) {
                setAdditionalInformation({});
                setState({
                    name: '',
                    description: '',
                    geo_type: '',
                    region: '',
                    district: '',
                    latitude: '',
                    longitude: ''
                })
                setSelectedId(null);
                setDeletedImages([]);
                setError('')
            }
        }, 200)
        setVisible(visible);
    }

    const handleCreate = (e: any) => {
        e.preventDefault();
        navigate({pathname: '/gis-geo-object'})
    }

    const handleUpdate = (e: any, id: number) => {
        e.preventDefault();
        navigate({pathname: `/gis-geo-object/${id}`})
    }

    const handleToggleDeleteModal = (visible: boolean) => {
        if (visible === false) setSelectedId(null);
        setDeleteModal(visible)
    }

    const handleEdit = () => {
        let data: any, preData, formData: any, dataArray: any[] = [], filesArray: any[] = [], linksArray: any[] = [],
            geo_object_field: any[] = [], dataForSend: any, filesData: any = {}, deletedFiles: any = {},
            linksObj: any = {};


        data = {
            ...additionalInformation
        }

        preData = Object.keys(data).map(el => {
            return [Number(el.split('-')[1]), data[el], el.split('-')[0]]
        })

        preData.forEach(el => {
            if (el[2] === 'image' && el[1].value.length) {
                el[1]?.value.forEach((item: any) => {
                    if (typeof item === 'object') {
                        filesArray.push({file: item, id: el[0], type: el[2]})
                    } else {
                        linksArray.push({file: item, id: el[0], type: el[2], fieldId: el[1].id})
                    }
                })

            } else if (el[2] !== 'image') {
                dataArray.push({[el[0]]: el[1].value, type: el[2], id: el[1].id ? Number(el[1].id) : null})
            }
        })

        for (const item of Object.keys(state)) {
            if (!state[item]) {
                setError(t('allFieldsValidate'));
                return null
            }
        }

        for (const item of dataArray) {
            const key = Object.keys(item).filter((el: any) => !isNaN(el))[0];
            if (item.type !== 'boolean' && !item[key]) {
                setError(t('allFieldsValidate'));
                return null;
            }
        }

        if (linksArray.length) {
            linksArray.forEach((link) => {
                if (linksObj.hasOwnProperty(link.id)) {
                    const linkFile = JSON.parse(linksObj[link.id][link.id]);
                    linkFile.push(link.file);
                    linksObj[link.id][link.id] = JSON.stringify(linkFile);
                } else {
                    linksObj[link.id] = {
                        [link.id]: JSON.stringify([link.file]),
                        type: 'image',
                        id: link.fieldId
                    }
                }
            });
        }

        geo_object_field = dataArray.map(el => {
            return {
                geo_type_field: Number(Object.keys(el)[0]),
                value: Object.values(el)[0] + '',
                type: Object.values(el)[1],
                id: Object.values(el)[2]
            }
        })

        dataForSend = {...state}
        dataForSend.geo_object_field = geo_object_field

        dataForSend.geo_type = Number(dataForSend.geo_type);
        dataForSend.district = Number(dataForSend.district);
        dataForSend.region = Number(dataForSend.region);
        dataForSend.latitude = Number(dataForSend.latitude);
        dataForSend.longitude = Number(dataForSend.longitude);
        filesData = filesArray.reduce((a, b, idx) => ({...a, ['file_' + (idx + 1) + "_" + b.id]: b.file}), {});

        if (selectedId) {
            deletedImages.forEach((el: any) => {
                if (deletedFiles.hasOwnProperty(el.fieldId)) {
                    deletedFiles[el.fieldId].push(el.images)
                } else {
                    deletedFiles[el.fieldId] = [el.images]
                }
            })


            let deletedData = {
                geo_object_id: selectedId,
                deleting_files: deletedFiles
            }

            //     dispatch(updateGeoObject(selectedId, dataForSend,
            //       () => {
            //         if (filesArray.length) {
            //           filesData.geo_object_id = Number(selectedId);
            //           formData = getFormData(filesData);
            //           if (deletedImages.length) {
            //             deleteGeoObjectFiles(deletedData) => {
            //               uploadGeoObjectFiles(deletedData).then(
            //                   () => {
            //                     // dispatch(fetchRegions());
            //                     handleToggleModal(false);
            //                   },
            //                   () => {
            //
            //                   }
            //               )
            //             }
            //             dispatch(deleteGeoObjectFiles(deletedData,
            //               () => {
            //                 dispatch(uploadGeoObjectFiles(formData,
            //                   () => {
            //                     dispatch(fetchGeoObjects());
            //                     handleToggleModal(false);
            //                   },
            //                   () => {
            //                   }
            //                 ))
            //               },
            //               () => {
            //               }
            //             ))
            //           } else {
            //             uploadGeoObjectFiles(formData).then(
            //                 () => {
            //                   // dispatch(fetchGeoObjects());
            //                   handleToggleModal(false);
            //                 },
            //                 () => {
            //                 }
            //             )
            //             dispatch(uploadGeoObjectFiles(formData,
            //               () => {
            //                 dispatch(fetchGeoObjects());
            //                 handleToggleModal(false);
            //               },
            //               () => {
            //               }
            //             ))
            //           }
            //         } else {
            //           fetchGeoObjects('');
            //           handleToggleModal(false);
            //         }
            //         if (deletedImages.length) {
            //           dispatch(deleteGeoObjectFiles(deletedData,
            //             () => {
            //               dispatch(fetchGeoObjects());
            //               handleToggleModal(false);
            //             },
            //             () => {
            //             }
            //           ))
            //         }
            //       },
            //       () => {
            //       }
            //     ))
            //   } else {
            //     dispatch(createGeoObject(dataForSend,
            //       (response: any) => {
            //         if (filesArray.length) {
            //           if (response.data.id) {
            //             filesData.geo_object_id = Number(response.data.id);
            //             formData = getFormData(filesData);
            //           }
            //           dispatch(uploadGeoObjectFiles(formData,
            //             () => {
            //               dispatch(fetchGeoObjects());
            //               handleToggleModal(false);
            //             },
            //             () => {
            //             }
            //           ))
            //         } else {
            //           dispatch(fetchGeoObjects());
            //           handleToggleModal(false);
            //         }
            //       },
            //       () => {
            //       }
            //     ));
            //   }
            // }

            updateGeoObject({id: selectedId, body: dataForSend}).then(
                () => {
                    if (filesArray.length) {
                        filesData.geo_object_id = Number(selectedId);
                        formData = getFormData(filesData);
                        // if (deletedImages.length) {
                        //     deleteGeoObjectFiles(deletedData).then(() => {
                        //         uploadGeoObjectFiles(deletedData).then(
                        //             () => {
                        //                 // dispatch(fetchRegions());
                        //                 handleToggleModal(false);
                        //             },
                        //             () => {
                        //
                        //             }
                        //         )
                        //     })
                        //     deleteGeoObjectFiles(deletedData).then(() => {
                        //         uploadGeoObjectFiles(formData).then(() => {
                        //             handleToggleModal(false);
                        //         })
                        //     })
                        // } else {
                        //     uploadGeoObjectFiles(formData).then(
                        //         () => {
                        //             // dispatch(fetchGeoObjects());
                        //             handleToggleModal(false);
                        //         },
                        //         () => {
                        //         }
                        //     )
                        //     uploadGeoObjectFiles(formData).then(
                        //         () => {
                        //             handleToggleModal(false);
                        //         },
                        //         () => {
                        //         })
                        // }
                    } else {
                        fetchGeoObjects('');
                        handleToggleModal(false);
                    }
                    if (deletedImages.length) {
                        // deleteGeoObjectFiles(deletedData).then(
                        //     () => {
                        //         handleToggleModal(false);
                        //     },
                        //     () => {
                        //     })
                    }
                },)
        } else {
            createGeoObject(dataForSend).then(
                (response: any) => {
                  if (filesArray.length) {
                    if (response.data.id) {
                      filesData.geo_object_id = Number(response.data.id);
                      formData = getFormData(filesData);
                    }
                    // uploadGeoObjectFiles(formData).then(
                    //     () => {
                    //       handleToggleModal(false);
                    //     },
                    //     () => {
                    //     }
                    // )
                  } else {
                    fetchGeoObjects();
                    handleToggleModal(false);
                  }
                }
            )
        }
    }

    const handleOpenEditModal = (visible?: any, item?: any) => {
        let data = {...state}
        let addInfo = {...additionalInformation}
        data.name = item.name;
        data.description = item.description;
        data.geo_type = item.geo_type;
        data.region = item.region;
        data.district = item.district;
        data.latitude = item.latitude;
        data.longitude = item.longitude;

        setState(data);

        if (item.geo_object_field.length) {
            item.geo_object_field.forEach((el: any) => {
                switch (el.type) {
                    case 'boolean':
                        addInfo['boolean-' + el.geo_type_field] = {value: el.value !== 'false', id: el.id};
                        break;
                    case 'number':
                        addInfo['number-' + el.geo_type_field] = {value: Number(el.value), id: el.id};
                        break;
                    case 'string':
                        addInfo['string-' + el.geo_type_field] = {value: el.value, id: el.id};
                        break;
                    case 'textarea':
                        addInfo['textarea-' + el.geo_type_field] = {value: el.value, id: el.id};
                        break;
                    case 'date':
                        addInfo['date-' + el.geo_type_field] = {value: el.value, id: el.id};
                        break;
                    case 'time':
                        addInfo['time-' + el.geo_type_field] = {value: el.value, id: el.id};
                        break;
                    case 'datetime':
                        addInfo['datetime-' + el.geo_type_field] = {value: el.value, id: el.id};
                        break;
                    case 'file':
                        let imagesLinks = [];
                        try {
                            imagesLinks = JSON.parse(el.value);
                        } catch (e) {
                            imagesLinks = el.value.slice(1, -1).replace(/\s/g, '').slice(1, -1).replace(/'/g, '').split(',');
                        }
                        addInfo['image-' + el.geo_type_field] = {value: imagesLinks, id: el.id};
                        break;
                    default:
                        return addInfo;
                }
            })
        }
        setAdditionalInformation(addInfo);
        setVisible(visible)
        setSelectedId(item.id)
    }

    const handleOpenDeleteModal = (id: any) => {
        setDeleteModal(true);
        setSelectedId(id);
    }

    const handleDelete = async () => {
        deleteGeoObject(Number(selectedId)).then(
            () => {
              handleToggleDeleteModal(false);
            },
            (error: string) => {
              // console.log(error?.data?.error)
            }
        )
    }

    const onPaginationHandler = (state: any) => {
        let innerState = {...pagination, ...state};

        if(countGeoObjects && countGeoObjects <= Number(pagination.page_size)) {
            innerState = {
                page_size: pagination.page_size,
                search: pagination.search,
                page: 1
                , ...state}
        }

        setPagination(innerState);
        onSearchDataHandler({...innerState, ...filter});
    };

    const onFilterHandler = (field: string) => {
        const state = {...filter};
        if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
        else {
            state.sort_by = field;
            state.direction = 'asc';
        }
        setFilter(state);
        onSearchDataHandler({...pagination, ...state});
    };

    const onSearchCallbackHandler = (search: string) => {
        onSearchDataHandler({...pagination, ...filter, search});
    };

    const onSearchDataHandler = (data: any) => {
        const query: any = {};
        if (data.page_size) query.page_size = data.page_size;
        if (data.page) query.page = data.page;
        if (data.search && data.search !== '') query.search = data.search;
        if (data.sort_by) query.sort_by = data.sort_by;
        if (data.direction === 'desc') query.direction = 'desc';

        // dispatch(fetchGeoObjects(Query.stringify(query)));
        fetchGeoObjects(makeQuery.stringify(query));
    }

    const handleDeletePreviewImage = (event: any, item: any, file: any, index: number) => {
        let deletedImagesArray: any = [...deletedImages], deletedItem: any = {};
        if (typeof file !== 'object') {
            deletedItem['fieldId'] = item[0].split('-')[1];
            deletedItem['images'] = file;
            deletedImagesArray.push(deletedItem);
            setDeletedImages(deletedImagesArray);
        }
        let addInfo = {...additionalInformation};

        addInfo[item[0]].value = addInfo[item[0]].value.filter((i: any, idx: number) => idx !== index);
        setAdditionalInformation(addInfo);
    }

    let geoObjectsForTable, geoTypesOptions, regionsOptions, districtsOptions, geoObjectFields;

    if (geoTypes) {
        geoTypesOptions = geoTypes?.results?.map((el: any) => {
            return {label: el.type_name, value: el.id}
        })
        geoTypesOptions.unshift({label: t('gis.geoObjects.selectType'), value: 'null'});
    }

    if (regions) {
        regionsOptions = regions.map((el: any) => {
            return {label: el.name, value: el.id}
        })
        regionsOptions.unshift({label: t('gis.geoObjects.selectArea'), value: 'null'});
    }

    if (districts) {
        districtsOptions = districts.map((el: any) => {
            return {label: el.name, value: el.id}
        })
        districtsOptions.unshift({label: t('gis.geoObjects.selectDistrict'), value: 'null'})
    }

    if (geoTypes) {
        geoObjectFields = geoTypes?.results?.map((el: any) => {
            return el.id === parseInt(state.geo_type, 10) ? el.geo_type_field.map((elem: any) => {
                switch (elem.field_type) {
                    case 'boolean':
                        return (
                            <FormGroup key={elem.id} htmlFor={elem.field_type + elem.id} label={elem.field_name}
                                       className="mb-1">
                                <CFormCheck id="flexCheckDefault"
                                            label=""
                                            name={elem.field_type + '-' + elem.id}
                                            checked={!!additionalInformation[elem.field_type + '-' + elem.id]?.value}
                                            onChange={e => handleAdditionalInformationChange(e, elem.field_type)}
                                />
                            </FormGroup>
                        )
                    case 'number':
                        return (
                            <FormGroup key={elem.id} htmlFor={elem.field_type + elem.id} label={elem.field_name}
                                       className="mb-1">
                                <CFormInput id={elem.field_type + elem.id}
                                            type="number"
                                            value={additionalInformation[elem.field_type + '-' + elem.id]?.value}
                                            name={elem.field_type + '-' + elem.id}
                                            onChange={e => handleAdditionalInformationChange(e, elem.field_type)}
                                            size="sm"
                                />
                            </FormGroup>
                        )
                    case 'string':
                        return (
                            <FormGroup key={elem.id} htmlFor={elem.field_type + elem.id} label={elem.field_name}
                                       className="mb-1">
                                <CFormInput id={elem.field_type + elem.id}
                                            type="text"
                                            value={additionalInformation[elem.field_type + '-' + elem.id]?.value}
                                            name={elem.field_type + '-' + elem.id}
                                            onChange={e => handleAdditionalInformationChange(e, elem.field_type)}
                                            size="sm"
                                />
                            </FormGroup>
                        )
                    case 'image':
                        return (
                            <div key={elem.id}>
                                <FormGroup htmlFor={elem.field_type + elem.id} label={elem.field_name} className="mb-3">
                                    <label htmlFor={'image-' + elem.field_type + elem.id}>
                                        <div className="select-file-btn">{t('gis.geoObjects.selectFiles')}</div>
                                    </label>
                                    <input type="file"
                                           id={'image-' + elem.field_type + elem.id}
                                           name={elem.field_type + '-' + elem.id}
                                           multiple
                                           accept=".png, .bmp, .jpg, .jpeg, .bmp, .tif, tiff"
                                        // @ts-ignore
                                           size="sm"
                                           onChange={e => handleAdditionalInformationChange(e, elem.field_type)}
                                           style={{display: "none"}}
                                    />
                                </FormGroup>
                                <div>
                                    {Object.entries(additionalInformation).map((item: any) => {
                                        return item[0] === elem.field_type + '-' + elem.id && item[1]?.value?.length ?
                                            item[1].value.map((file: any, idx: number) => {
                                                return (
                                                    <span key={idx + '-file'} className="preview-image-wrapper">
                            <img alt='' src={typeof file === 'object' ? URL.createObjectURL(file) : 'http://' + file}
                                 className="preview-image"/>
                              <i className="fa fa-times-circle-o close-icon image-action-active"
                                 aria-hidden="true"
                                 onClick={e => handleDeletePreviewImage(e, item, file, idx)}
                              />
                          </span>
                                                )
                                            }) : null
                                    })}
                                </div>
                            </div>
                        )
                    case 'date':
                        return (
                            <FormGroup key={elem.id} htmlFor={elem.field_type + elem.id} label={elem.field_name}
                                       className="mb-1">
                                <input type='date'
                                       value={additionalInformation[elem.field_type + '-' + elem.id]?.value}
                                       name={elem.field_type + '-' + elem.id}
                                       onChange={e => handleAdditionalInformationChange(e, elem.field_type)}
                                       className="date-input"
                                />
                            </FormGroup>
                        )
                    case 'time':
                        return (
                            <FormGroup key={elem.id} htmlFor={elem.field_type + elem.id} label={elem.field_name}
                                       className="mb-1">
                                <input onChange={e => handleAdditionalInformationChange(e, elem.field_type)}
                                       type='time'
                                       value={additionalInformation[elem.field_type + '-' + elem.id]?.value}
                                       name={elem.field_type + '-' + elem.id}
                                       className="date-input"
                                />
                            </FormGroup>
                        )
                    case 'datetime':
                        return (
                            <FormGroup key={elem.id} htmlFor={elem.field_type + elem.id} label={elem.field_name}
                                       className="mb-1">
                                <input onChange={e => handleAdditionalInformationChange(e, elem.field_type)}
                                       type='datetime-local'
                                       value={additionalInformation[elem.field_type + '-' + elem.id]?.value}
                                       name={elem.field_type + '-' + elem.id}
                                       className="date-input"
                                />
                            </FormGroup>
                        )
                    case 'textarea':
                        return (
                            <FormGroup key={elem.id} htmlFor={elem.field_type + elem.id} label={elem.field_name}
                                       className="mb-1">
                                <CFormTextarea id={elem.field_type + elem.id}
                                               value={additionalInformation[elem.field_type + '-' + elem.id]?.value}
                                               name={elem.field_type + '-' + elem.id}
                                               onChange={e => handleAdditionalInformationChange(e, elem.field_type)}
                                               //@ts-ignore
                                               size="sm"
                                />
                            </FormGroup>
                        )
                    default:
                        return null
                }
            }) : null
        })
    }

  if (geoObjects?.results?.length) {
        geoObjectsForTable = geoObjects.results.map((el: any, idx: number) => {
            return (
                <CTableRow key={el.id}>
                    <CTableDataCell>
                        {el.name}
                    </CTableDataCell>
                    <CTableDataCell>
                        {el.geo_type_name}
                    </CTableDataCell>
                    <CTableDataCell>
                        {el.description}
                    </CTableDataCell>

                    <CTableDataCell>
                        {el?.geo_object_field?.length ?
                          <div style={{background: 'transparent', padding: '8px'}}>
                              <div className={addVisible[idx]?.value ? "visible-item" : "collapsable-item"}
                                   onClick={() => handleClickToAdditionalInformation(el.id)}>
                                  {
                                      addVisible[idx]?.value ?
                                        <i className="fa fa-chevron-down" aria-hidden="true"
                                           style={{marginRight: '10px'}}></i> :
                                        <i className="fa fa-chevron-right" aria-hidden="true"
                                           style={{marginRight: '10px'}}></i>
                                  }
                                  <div style={{cursor: 'pointer', fontWeight: 'bold'}}>
                                      {t("gis.geoObjects.additionalInformation")}
                                  </div>
                              </div>
                              <CCollapse visible={addVisible[idx]?.value}>
                                  <ul style={{marginTop: '20px', paddingLeft: '5px'}}>
                                      {el.geo_object_field.map((elem: any) => {
                                          const meaning: string = elem.type === 'date' ? dayjs(elem.value).format('DD.MM.YYYY')
                                            // : elem.type === 'time' ? dayjs(elem.value).format('HH:mm')
                                            : elem.type === 'datetime' ? dayjs(elem.value).format('DD.MM.YYYY HH:mm')
                                              : elem.type === 'boolean' ? (elem.value === 'true' ? t('Yes') : t('No')) : elem.value || ' - ';
                                          return (
                                            <li key={elem.id + '_emergency-type'} className="li-dot">
                                                <strong>{elem.geo_type_field_name}:</strong> {" "} {meaning}
                                            </li>
                                          )
                                      })}
                                  </ul>
                              </CCollapse>
                              <ul style={{marginTop: '12px', marginBottom: 0, paddingLeft: 0}}>
                                  <li className="li-dot">
                                      <strong>{t('Last update at')}:</strong> {dayjs(el.updated_at).format('YYYY.MM.DD HH:mm')}
                                  </li>
                              </ul>
                          </div>
                          : null}
                    </CTableDataCell>

                    {/*<CTableDataCell>*/}
                    {/*    <ul className="mb-0" style={{paddingLeft: '25px'}}>*/}
                    {/*        {el?.geo_object_field?.length ? el.geo_object_field.map((field: any) => {*/}
                    {/*            if (field.type !== 'file' && field.type !== 'datetime') {*/}
                    {/*                return (*/}
                    {/*                    <li key={field.id}>*/}
                    {/*                        <span*/}
                    {/*                            style={{fontWeight: 'bold'}}>{field.geo_type_field_name}</span> - {field.value}*/}
                    {/*                    </li>*/}
                    {/*                )*/}
                    {/*            } else if (field.type === 'datetime') {*/}
                    {/*                return (*/}
                    {/*                    <li key={field.id}>*/}
                    {/*  <span style={{fontWeight: 'bold'}}>*/}
                    {/*    {field.geo_type_field_name}*/}
                    {/*  </span> - {moment(field.value).format('YYYY-MM-DD HH:mm')}*/}
                    {/*                    </li>*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        }) : ''}*/}
                    {/*    </ul>*/}
                    {/*</CTableDataCell>*/}

                    <CTableDataCell className="text-center">
                        <CButtonGroup size="sm">
                            <CButton size="sm" color="info" className="default-table__action-btn"
                                     onClick={(e) => handleUpdate(e, el.id)}>
                                <i className="fa fa-pencil-square-o color-white"/>
                            </CButton>
                            <CButton size="sm" color="danger" className="default-table__action-btn"
                                     onClick={() => handleOpenDeleteModal(el.id)}>
                                <i className="fa fa-times color-white" aria-hidden="true"> </i>
                            </CButton>
                        </CButtonGroup>
                    </CTableDataCell>
                </CTableRow>
            )
        })
    }

    return (
      <div className={geoObjectsFetching ? 'page-loading' : ''}>
          <div className="pb-3 mb-4 title-divider">
              <h1>{t('gis.geoObjects.geoObjects')}</h1>
              <LoaderLine visible={isLoading}/>
          </div>

          <div className="d-flex justify-content-md-end mb-3">
              <CButton size="sm" color="info" className="color-white" onClick={(e) => handleCreate(e)}>
                  {t('gis.geoObjects.addGeoObject')}
              </CButton>
          </div>

          <DefaultPaginationWrapper data={geoObjects}
                                    state={pagination}
                                    onChange={onPaginationHandler}
                                    onSearchDelay={2000}
                                    onSearchCallback={onSearchCallbackHandler}
          >
              <CTable bordered striped className="default-table">
                  <CTableHead>
                      <CTableRow>
                          <CTableHeaderCell style={{width: '17%'}}
                                            scope="col"
                                            className="sorted-table-cell"
                                            onClick={() => onFilterHandler('name')}
                          >
                              <div className="d-flex justify-content-between">
                                  <span>{t('gis.geoObjects.naming')}</span>
                                  {filter.sort_by === 'name' ?
                                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                                    <i className="fa fa-sort" aria-hidden="true"/>
                                  }
                              </div>
                          </CTableHeaderCell>

                          <CTableHeaderCell style={{width: '18%'}}
                                            scope="col"
                                            className="sorted-table-cell"
                                            onClick={() => onFilterHandler('geo_type')}
                          >
                              <div className="d-flex justify-content-between">
                                  <span>{t('gis.geoObjects.type')}</span>
                                  {/*<i className="fa fa-sort" aria-hidden="true"/>*/}
                                  {filter.sort_by === 'geo_type' ?
                                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                                    <i className="fa fa-sort" aria-hidden="true"/>
                                  }
                              </div>
                          </CTableHeaderCell>

                          <CTableHeaderCell style={{width: '25%'}}
                                            scope="col"
                                            className="sorted-table-cell"
                            // onClick={() => onFilterHandler('icon')}
                          >
                              <div className="d-flex justify-content-between">
                                  <span>{t('gis.geoObjects.description')}</span>
                                  {/*<i className="fa fa-sort" aria-hidden="true"/>*/}
                              </div>
                          </CTableHeaderCell>

                          <CTableHeaderCell style={{width: '300px'}}
                                            scope="col"
                                            className="sorted-table-cell"
                            // onClick={() => onFilterHandler('geo_type_field')}
                          >
                              <div className="d-flex justify-content-between">
                                  <span>{t('gis.geoObjects.additionalInformation')}</span>
                                  {/*<i className="fa fa-sort" aria-hidden="true"/>*/}
                              </div>
                          </CTableHeaderCell>

                          <CTableHeaderCell style={{width: '13%'}} scope="col" className="default-table__actions">
                              {t('actions')}
                          </CTableHeaderCell>
                      </CTableRow>
                  </CTableHead>
                  <CTableBody>
                      {geoObjectsForTable}
                  </CTableBody>
              </CTable>
          </DefaultPaginationWrapper>

          <DefaultModal visible={visible}
                        setVisible={handleToggleModal}
                        title={selectedId ?
                          t('gis.geoObjects.editGeoObject') :
                          t('gis.geoObjects.addGeoObject')}
                        type="info"
                        cancelButton={t('cancel')}
                        onOk={handleEdit}
                        size="xl"
                        error={error}
          >
              <FormGroup htmlFor="name" label={t('gis.geoObjects.naming')}>
                  <CFormInput id="name"
                              type="text"
                              value={state.name}
                              name="name"
                              onChange={handleChange}
                              size="sm"
                  />
              </FormGroup>

              <FormGroup htmlFor="description" label={t('gis.geoObjects.description')}>
                  <CFormTextarea id="description"
                                 value={state.description}
                                 name="description"
                                 onChange={handleChange}
                    //@ts-ignore
                                 size="sm"
                  />
              </FormGroup>

              {selectedId ? null : <FormGroup htmlFor="geo_type" label={t('gis.geoObjects.type')}>
                  <CFormSelect size="sm"
                               id="geo_type"
                               value={state.geo_type}
                               name="geo_type"
                               onChange={handleChange}
                               options={geoTypesOptions}
                  />
              </FormGroup>}

              <FormGroup htmlFor="region" label={t('gis.geoObjects.area')}>
                  <CFormSelect size="sm"
                               id="region"
                               value={state.region}
                               name="region"
                               onChange={handleChange}
                               options={regionsOptions}
                  />
              </FormGroup>

              <FormGroup htmlFor="district" label={t('gis.geoObjects.district')}>
                  <CFormSelect size="sm"
                               id="district"
                               value={state.district}
                               name="district"
                               onChange={handleChange}
                               options={districtsOptions}
                  />
              </FormGroup>

              <FormGroup htmlFor="latitude" label={t('gis.geoObjects.latitude')}>
                  <CFormInput id="latitude"
                              type="text"
                              value={state.latitude}
                              name="latitude"
                              onChange={handleChange}
                              size="sm"
                  />
              </FormGroup>

              <FormGroup htmlFor="longitude" label={t('gis.geoObjects.longitude')}>
                  <CFormInput id="longitude"
                              type="text"
                              value={state.longitude}
                              name="longitude"
                              onChange={handleChange}
                              size="sm"
                  />
              </FormGroup>

              <CCard className="mb-3">
                  Здесь будет карта
              </CCard>

              <CCard>
                  <CCardHeader className="p-0">
                      <div className="p-2 mb-0 card-header-title">
                          {t('gis.geoObjects.additionalInformation')}
                      </div>
                  </CCardHeader>
                  <CCardBody>
                      {geoObjectFields}
                  </CCardBody>
              </CCard>

          </DefaultModal>

          <DefaultModal visible={deleteModal}
                        setVisible={handleToggleDeleteModal}
                        title={t('deletion')}
                        type="danger"
                        cancelButton={t('cancel')}
                        onOk={handleDelete}
            //@ts-ignore
                        error={geoObjectsError?.data?.error}
                        size="lg"
          >
              <div>
                  <p className="mb-0 fs-6">{t('gis.geoObjects.geoObjectDeletion')}</p>
              </div>
          </DefaultModal>

      </div>
    );
};

export default GeoObjects;
