import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from "react-router-dom";
import Select, {StylesConfig} from "react-select";
import {useTranslation} from "react-i18next";
import {
  CButton,
  CFormLabel, CSpinner
} from "@coreui/react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

import {
  useGetTemplatesQuery,
  useLazyGetAnalyticTemplateQuery,
  useLazyGetTemplateByIdQuery
} from "../../../store/analytics/analytics.api";
import {useLazyGetUsersQuery} from "../../../store/users/users.api";
import {useLazyGetCountiesQuery} from "../../../store/counties/counties.api";
import {useLazyGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useLazyGetLocalitiesQuery} from "../../../store/localities/localities.api";
import {useLazyGetRegionsQuery} from "../../../store/regions/regions.api";
import {useLazyGetEmergencyTypesQuery} from "../../../store/emergencyTypes/emergencyTypes.api";
import makeQuery from "../../../functions/makeQuery";
import {ITemplate} from "../../../interfaces/ITemplates";
import {IAnalyticsTemplate} from "../../../interfaces/IAnalyticsTemplates";
import DataDisplay from "./DataDisplay";

export type TSelectOption = {
  label: string,
  value: string
};

interface ISelectsOptions {
  [key: string]: TSelectOption[]
}

interface IRestrictionData {
  [key: string]: TSelectOption
}

interface IRestrictionDates {
  [key: string]: Date | number | string
}

const datetimeTypes = ['created_at', 'updated_at', 'date_emergency'];
const booleanTypes = ['late_information'];
const statuses = ['in processing', 'completed'];
const typeReports = ['emergency', 'incident', 'other'];

const AnalyticReport = () => {
  const location = useLocation();
  const {t} = useTranslation();

  const templateId = location.pathname.split('/').reverse()[0];

  const {data: analyticTemplates} = useGetTemplatesQuery();
  const [getTemplateById, {data: oneTemplate}] = useLazyGetTemplateByIdQuery();
  const [getUsersList, {data: usersList}] = useLazyGetUsersQuery();
  const [getCountiesList, {data: countiesList}] = useLazyGetCountiesQuery();
  const [getDistrictsList, {data: districtsList}] = useLazyGetDistrictsQuery();
  const [getLocalitiesList, {data: localitiesList}] = useLazyGetLocalitiesQuery();
  const [getRegionsList, {data: regionsList}] = useLazyGetRegionsQuery();
  const [getEmergencyTypesList, {data: emergencyTypesList}] = useLazyGetEmergencyTypesQuery();
  const [getAnalyticTemplate, {isLoading: getAnalyticTemplateLoading, isFetching: getAnalyticTemplateFetching}] = useLazyGetAnalyticTemplateQuery();

  const [templateState, setTemplateState] = useState<ITemplate>({
    id: 0,
    name: '',
    collection: '',
    group_aggregation_functions: [],
    group_by_fields: [],
    restrictions: [],
    sort_conditions: []
  });
  const [template, setTemplate] = useState<TSelectOption>({label: '', value: ''});
  const [restrictionsData, setRestrictionsData] = useState<IRestrictionData>({});
  const [restrictionDates, setRestrictionDates] = useState<IRestrictionDates>({});
  const [activeKey, setActiveKey] = useState(1);
  const [analyticsTemplates, setAnalyticsTemplates] = useState<IAnalyticsTemplate[]>([]);
  const [selectState, setSelectState] = useState({
    diagram_type: {label: '', value: ''},
    field: {label: '', value: ''},
  });

  const isLoading = useMemo(() => {
    return (getAnalyticTemplateLoading || getAnalyticTemplateFetching);
  }, [getAnalyticTemplateLoading, getAnalyticTemplateFetching]);

  const selectsOptions: ISelectsOptions = useMemo(() => {
    const result: ISelectsOptions = {};

    if (usersList?.results) {
      result.author = usersList?.results?.map(el => ({
        label: (el.first_name || '') + " " + (el.last_name || ''),
        value: String(el.id)
      }))
      result.executor = [...result.author]
    }

    if (countiesList) {
      result.county = countiesList.map(el => ({
        label: el.coat_code + " - " + el.name,
        value: String(el.id)
      }))
    }

    if (districtsList) {
      result.district = districtsList.map(el => ({
        label: el.coat_code + " - " + el.name,
        value: String(el.id)
      }))
    }

    if (localitiesList) {
      result.locality = localitiesList.map(el => ({
        label: el.coat_code + " - " + el.name,
        value: String(el.id)
      }))
    }

    if (regionsList) {
      result.region = regionsList.map(el => ({
        label: el.coat_code + " - " + el.name,
        value: String(el.id)
      }))
    }

    if (emergencyTypesList?.results) {
      result.type_emergency = emergencyTypesList?.results?.map(el => ({
        label: el.name,
        value: String(el.id)
      }))
    }

    templateState?.restrictions?.forEach(el => {
      if (booleanTypes.includes(el.field_name)) {
        result[el.field_name] = [{label: t("labels.yes"), value: 'true'}, {label: t("labels.no"), value: 'false'}];
      }
      if (el.field_name === 'type_report') {
        result.type_report = typeReports.map(elem => ({
          label: t(`labels.${elem}`),
          value: elem
        }))
      }

      if (el.field_name === 'status') {
        result.status = statuses.map(elem => ({
          label: t(`labels.${elem}`),
          value: elem
        }))
      }
    })

    return result;
  }, [usersList, countiesList, districtsList, localitiesList, regionsList, emergencyTypesList, templateState, t]);

  useEffect(() => {
    if (!isNaN(Number(templateId))) {
      getTemplateById(templateId).then(res => {
        console.log(res);
        // @ts-ignore
        setTemplateState(res.data);
        setTemplate({label: res?.data?.name || '', value: String(res?.data?.id) || ''});
        res?.data?.restrictions.forEach(el => {
          if (el.field_name === 'author' || el.field_name === 'executor') getUsersList(makeQuery.stringify({page_size: 100000}));
          if (el.field_name === 'county') getCountiesList();
          if (el.field_name === 'district') getDistrictsList();
          if (el.field_name === 'locality') getLocalitiesList();
          if (el.field_name === 'region') getRegionsList();
          if (el.field_name === 'type_emergency') getEmergencyTypesList(makeQuery.stringify({page_size: 100000}));
        })
      })
    } else {
      setTemplateState({
        id: 0,
        name: '',
        collection: '',
        group_aggregation_functions: [],
        group_by_fields: [],
        restrictions: [],
        sort_conditions: []
      })
    }
  }, [templateId]);

  const onSelectChangeHandler = (data: TSelectOption, collection: string) => {
    if (collection === 'templates') {
      setTemplate(data);
      getTemplateById(data.value).then(res => {
        res?.data?.restrictions.forEach(el => {
          if (el.field_name === 'author' || el.field_name === 'executor') getUsersList(makeQuery.stringify({page_size: 100000}));
          if (el.field_name === 'county') getCountiesList();
          if (el.field_name === 'district') getDistrictsList();
          if (el.field_name === 'locality') getLocalitiesList();
          if (el.field_name === 'region') getRegionsList();
          if (el.field_name === 'type_emergency') getEmergencyTypesList(makeQuery.stringify({page_size: 100000}));
        })
        if (res.data) {
          setTemplateState(res.data)
        }
      })
    } else {
      setRestrictionsData(prevState => {
        return {...prevState, [collection]: data}
      })
    }
  }

  const onChangeDate = (data: Date | null, collection: string) => {
    const date: Date | string | number = data || ''
    setRestrictionDates(prevState => {
      return {...prevState, [collection]: date}
    })
  }

  const handleFormReport = (e: React.FormEvent) => {
    e.preventDefault();
    const data: { [key: string]: string } = {}
    oneTemplate?.restrictions.forEach(el => {
      if (datetimeTypes.includes(el.field_name)) {
        if (restrictionDates[el.field_name + "&" + el.field_method]) {
          data[String(el.id)] = dayjs(String(restrictionDates[el.field_name + "&" + el.field_method])).format('YYYY-MM-DD')
        }
      } else {
        // // @ts-ignore
        // data[String(el.id)] = restrictionsData[el.field_name]?.value
      }
    })
    let query = makeQuery.stringify(data);
    const object: { [key: string]: string } = {}
    oneTemplate?.restrictions.forEach(el => {
      if (!datetimeTypes.includes(el.field_name)) {
        // @ts-ignore
        object[String(el.id)] = restrictionsData[el.field_name]?.value
      }
    })
    query += query ? (makeQuery.stringify(object) ? "&" + makeQuery.stringify(object).substring(1) : '') : makeQuery.stringify(object);
    getAnalyticTemplate({id: !!isNaN(Number(templateId)) ? template.value : templateId, query}).then(res => {
      if (res?.data?.length) {
        setAnalyticsTemplates(res?.data)
      }
    })
  }

  const handleSetActiveKey = (key: number) => {
    setActiveKey(key)
  }

  const handleSelect = (data: TSelectOption, name: string) => {
    setSelectState(prevState => {
      return {...prevState, [name]: data}
    })
  }

  const colorStyles: StylesConfig<any> = {
    control: (styles) => (
      {
        ...styles,
        backgroundColor: '#ffffff',
        border: '1px solid grey',
        boxShadow: 'none',
        borderRadius: '6px',
        maxHeight: 31,
        fontSize: '14px'
      }
    )
  };

  const templatesOptions: TSelectOption[] = useMemo(() => {
    if (!analyticTemplates?.length) return [];
    return analyticTemplates.map(el => ({label: el.name, value: String(el.id)}))
  }, [analyticTemplates]);

  return (
    <>
      <div className="pb-3 mb-4 title-divider d-flex justify-content-between align-items-center">
        <h1>{templateState?.name ? templateState.name : 'Выберите шаблон'}</h1>
      </div>

      <div className={'pb-4 mb-4 title-divider'}
           style={{minWidth: '100%', display: 'flex', alignContent: 'center', justifyContent: 'space-between'}}>
        <div style={{width: '100%'}}>
          <CFormLabel>{t("templates.templates")}</CFormLabel>
          <Select options={templatesOptions}
                  value={templatesOptions.find(el => el.value === template?.value)}
                  styles={colorStyles}
                  placeholder={'Select one'}
                  onChange={e => onSelectChangeHandler(e, 'templates')}
                  isClearable
          />
        </div>
      </div>

      <div className="mb-5">
        {templateState?.restrictions?.map(el => {
          console.log(templateState);
          // console.log(el.field_name, el.field_method);
          // console.log((t(`labels.${el.field_name}`) + t(`labels.${el.field_method}`)));
          console.log(el);
          return (
            <div key={el.id + "_" + '_restriction'} className="mb-4">
              <CFormLabel>{t(`labels.${el.field_name}`) + " (" + t(`labels.${el.field_method || 'contains'}`) + ")"}</CFormLabel>
              {
                datetimeTypes.includes(el.field_name) ?
                  <div className="analytics-datepicker-wrapper" style={{width: '100%'}}>
                    <DatePicker onChange={e => onChangeDate(e, el.field_name + "&" + el.field_method)}
                                selected={restrictionDates[el.field_name + "&" + el.field_method] ? new Date(restrictionDates[el.field_name + "&" + el.field_method]) : null}
                                dateFormat={'dd-MM-YYYY'}
                                className={"ml-5 my-datepicker"}
                    />
                  </div> :
                  <Select options={selectsOptions[el.field_name]}
                          value={restrictionsData[el.field_name]}
                          styles={colorStyles}
                          placeholder={'Select one'}
                          onChange={e => onSelectChangeHandler(e, el.field_name)}
                          isClearable
                  />
              }
            </div>
          )

        })}
      </div>

      <CButton className="mb-5" onClick={handleFormReport}>
        {isLoading ? <CSpinner size="sm"/> : t("templates.toForm")}
      </CButton>

      {
        analyticsTemplates.length ?
          <DataDisplay data={analyticsTemplates}
                       activeKey={activeKey}
                       setActiveKey={handleSetActiveKey}
                       onSelect={handleSelect}
                       diagramState={selectState}
          /> :
          null
      }
    </>
  );
};

export default AnalyticReport;