import React, {FC, useState} from 'react';
import {esFeed} from "./EsMap";
import {GeoJSON, LayersControl, MapContainer, Marker, Popup, ScaleControl, TileLayer, ZoomControl} from "react-leaflet";
import L from "leaflet";
import Control from "react-leaflet-custom-control";
import {getTiles} from "../../../components/maps/tiles";
import MapLoader from "../../../components/preloaders/MapLoader/MapLoader";
import MarkerClusterGroup from "react-leaflet-cluster";
import {CImage, CModal} from "@coreui/react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import GalleryModal from "../../../components/UI/Modals/GalleryModal";
import {getParamsOfFileByUrl} from "../../../functions";

interface IEmergencyList {
    data: esFeed[]
    legend: any
    emergenciesGeom?: any
    // onClick: (e: React.MouseEvent<HTMLDivElement>, id: number) => void
    // isMenuOpen: any
    // menuPosition: string
    // users: any
    // openModal: (id: number) => void
    // emergencyTypes: IPaginationResponse<any[]> | undefined
}

const EmergencyListMap: FC<IEmergencyList> = ({
                                                  data,
                                                  legend,
                                                  // emergenciesGeom,
                                                  // onClick,
                                                  // isMenuOpen,
                                                  // menuPosition,
                                                  // users,
                                                  // openModal,
                                                  // emergencyTypes
                                              }) => {

    const {t} = useTranslation();

  const [showGalleryModal, setShowGalleryModal] = useState<number | null>(null);
  const [currentMarkerId, setCurrentMarkerId] = useState<number | null>(null);

  const onToggleGalleryModal = (markerId: number | null = null, value: number | null = null) => {
    setCurrentMarkerId(markerId);
    setShowGalleryModal(value);
  };

  const formatDate = (isoDateString: string) => {
        const date = new Date(isoDateString);

        // Форматируем дату в ДД.ММ.ГГГГ
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // месяцы начинаются с 0
        const year = date.getFullYear();

        // Форматируем время в ЧЧ:ММ:СС
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    const esInfo = (marker: any) => {
        return (
          <div key={marker.region + marker.name} className="info-popup-block">
            <Link className="detail-es-more" to={`/es-base/detail/feed/${marker?.id}`}
                  target="_blank">{t('ESMap.more')}</Link>

            <p><strong>{t('ESMap.title')}:</strong> {marker.title}</p>
            {/*<p><strong>Название проишествия:</strong> {marker?.type_emergency_name}</p>*/}
            <p><strong>{t('ESMap.desc')}:</strong> {marker.description}</p>
            <p><strong>{t('ESMap.date')}:</strong> {dayjs(marker.date_emergency).format('DD.MM.YYYY HH:mm')}</p>
            <p><strong>{t('ESMap.type')}:</strong> {t(`emergency.${marker.type_report}`)}</p>
            <p>
              <strong>{t('ESMap.status')}:</strong> {t(`esFeed.${marker.status === 'completed' ? 'completed' : 'inProcess'}`)}
            </p>
            <p><strong>{t('ESMap.casualties')}:</strong> {marker.human_casualties}</p>
            <p><strong>{t('ESMap.damage')}:</strong> {marker.material_damage}</p>
            <p><strong>{t('ESMap.executor')}:</strong> {marker.executor}</p>

            {/*<p><strong>{t("gis.geoObjects.infoFields")}:</strong></p>*/}
            {/*<ul>*/}
            {/*    <li>*/}
            {/*<strong>Автор</strong>: {marker?.executor?.last_name} {marker?.executor?.first_name}*/}
            {/*<strong>Ник</strong>: {marker?.executor?.username}*/}
            {/*    </li>*/}
            {/*    {marker?.executor?.user_groups?.map((g: any) => {*/}
            {/*        return (*/}
            {/*            <li key={g.id}>*/}
            {/*                <strong>Группа</strong>: {g?.name}*/}
            {/*            </li>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</ul>*/}

            {marker?.field_emergency?.length ?
              <>
                <p><strong>{t("gis.geoObjects.additionalInformation")}:</strong></p>
                <ul>
                  {marker.field_emergency.map((field: any) => {
                    return (
                      <li key={field.id}>
                        <strong>{field?.field_type_emergency?.name}: </strong>
                        <span>
                              {
                                field.field_type_emergency?.type === 'date' ?
                                  dayjs(field.meaning).format('DD.MM.YYYY') :
                                  field.field_type_emergency?.type === 'datetime' ?
                                    dayjs(field.meaning).format('DD.MM.YYYY HH:mm') :
                                    field.meaning
                              }
                            </span>
                      </li>
                    )
                  })}
                </ul>
              </>
              : null}

            {marker?.file_emergency?.length ?
              <>
                <p><strong>{t("Attachments")}:</strong></p>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: '4px'}}>
                  {marker?.file_emergency?.map((file: any, idx: number) => {
                    const fileParams = getParamsOfFileByUrl(file.file);
                    let innerJSX;
                    if (fileParams.type === 'image') {
                      innerJSX = (
                        <img alt={""}
                             src={file.file}
                             style={{height: '40px', width: 'auto'}}
                        />
                      );
                    }
                    if (fileParams.type === 'video') {
                      innerJSX = (
                        <video id="video" width="auto" height="40">
                          <source src={file.file} type={`${fileParams.type}/${fileParams.ext}`}/>
                        </video>
                      );
                    }
                    if (fileParams.type === 'audio') {
                      innerJSX = (
                        <i className="fa fa-file-audio-o" aria-hidden="true" style={{fontSize: '40px'}}/>
                      );
                    }
                    return (
                      <div key={idx + 'file' + file.emergency}
                           style={{cursor: 'pointer'}}
                           onClick={() => onToggleGalleryModal(marker.id, idx)}
                      >
                        {innerJSX}
                      </div>
                    )
                  })}
                </div>
              </>
              : null}
          </div>
        );
    };

  let loading = false;

  return (
    <>
    <MapContainer
      center={[41.1262532, 73.79516602]}
      zoom={7}
      zoomControl={false}
      attributionControl={false}
      style={{height: '100%', position: 'relative', zIndex: 0, margin: '20px'}}
    >
      {/*{*/}
      {/*    coordinates.lat && coordinates.lat ?*/}
      {/*        <Marker position={[coordinates.lat, coordinates.lng]}*/}
      {/*                icon={*/}
      {/*                    L.divIcon({*/}
      {/*                        className: 'coordinates-icon',*/}
      {/*                        html: '<i class="fa fa-dot-circle-o" aria-hidden="true"></i>',*/}
      {/*                        iconSize: [32, 32]*/}
      {/*                    })*/}
      {/*                }>*/}
      {/*            <Popup>*/}
      {/*                <div>*/}
      {/*                    <p>Широта:{coordinates.lat}</p>*/}
      {/*                    <p>Долгота: {coordinates.lng}</p>*/}
            {/*                </div>*/}
            {/*            </Popup>*/}
            {/*        </Marker>*/}
            {/*        : null*/}
            {/*}*/}
            {/*<Control position="topleft">*/}
            {/*    <a className="leaflet-control-filter leaflet-control-button"*/}
            {/*       href="/"*/}
            {/*       title="Category filter"*/}
            {/*       role="button"*/}
            {/*       aria-label="Category filter"*/}
            {/*       aria-disabled="false"*/}
            {/*       onClick={e => handleVisible(e, 'tree')}*/}
            {/*    >*/}
            {/*        <i className="fa fa-filter" aria-hidden="true"*/}
            {/*        />*/}
            {/*    </a>*/}
            {/*</Control>*/}

            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap">
                    <TileLayer url={getTiles('OpenStreetMap')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Google.Hybrid">
                    <TileLayer url={getTiles('Google.Hybrid')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldStreetMap">
                    <TileLayer url={getTiles('Esri.WorldStreetMap')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldGrayCanvas">
                    <TileLayer url={getTiles('Esri.WorldGrayCanvas')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldImagery">
                    <TileLayer url={getTiles('Esri.WorldImagery')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldShadedRelief">
                    <TileLayer url={getTiles('Esri.WorldShadedRelief')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Stamen.Terrain">
                    <TileLayer url={getTiles('Stamen.Terrain')}/>
                </LayersControl.BaseLayer>
            </LayersControl>

            <ZoomControl position="topright"/>

            {/*<Control position="topright">*/}
            {/*    <a className="leaflet-control-crosshairs leaflet-control-button"*/}
            {/*       href="/"*/}
            {/*       title="Crosshairs"*/}
            {/*       role="button"*/}
            {/*       aria-label="Crosshairs"*/}
            {/*       aria-disabled="false"*/}
            {/*       onClick={e => handleVisible(e, 'coordinates')}*/}
            {/*    >*/}
            {/*        <i className="fa fa-crosshairs" aria-hidden="true"/>*/}
            {/*    </a>*/}
            {/*</Control>*/}

            {/*<Control position="topright">*/}
            {/*    {opacityVisible ? renderOpacity() : null}*/}
            {/*    <a className="leaflet-control-layers-satellite leaflet-control-button"*/}
            {/*       href="/"*/}
            {/*       title="Layers satellite"*/}
            {/*       role="button"*/}
            {/*       aria-label="Layers satellite"*/}
            {/*       aria-disabled="false"*/}
            {/*       onClick={e => handleVisible(e, 'opacity')}*/}
            {/*    >*/}
            {/*        <i className="fa fa-adjust" aria-hidden="true"/>*/}
            {/*    </a>*/}
            {/*</Control>*/}

            <ScaleControl position="bottomleft"/>

            {loading ? <MapLoader/> : null}

            <MarkerClusterGroup
                chunkedLoading
            >
                {data?.map((marker: any, index: any) => (
                    <Marker key={index} position={marker?.position}
                            icon={new L.Icon({
                                iconUrl: marker?.icon,
                                iconSize: [32, 37],
                                iconAnchor: [16, 32],
                                popupAnchor: [0, -32],
                            })}>
                        <Popup>
                            {esInfo(marker)}
                        </Popup>
                    </Marker>
                ))}
            </MarkerClusterGroup>
            {data.length ? legend : null}
        </MapContainer>

      <GalleryModal show={!!showGalleryModal || showGalleryModal === 0}
                    toggle={onToggleGalleryModal}
                    data={data?.find(el => el.id === currentMarkerId)?.file_emergency || []}
                    startIndex={showGalleryModal || 0}
      />
    </>
    );
};

export default EmergencyListMap;