import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
  CButton,
  CButtonGroup,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";

import {useGetTemplatesQuery} from "../../store/analytics/analytics.api";

const Analytics = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {data: analyticTemplates} = useGetTemplatesQuery();

  const handleRedirectToCreateTemplate = () => {
    navigate({pathname: '/analytic-templates/create'})
  }

  const handleRedirectToUpdate = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>, id: number) => {
    e.preventDefault();
    navigate({pathname: `/analytic-templates/update/${id}`})
  }

  let templatesList: any[] = [];

  if (analyticTemplates?.length) {
    templatesList = analyticTemplates.map((el: any, idx: number) => {
      return (
        <CTableRow key={'user' + idx}>
          <CTableDataCell>
            <div style={{cursor: 'pointer'}}>
              <a href={`/analytic/report/${el.id}`}
                 className="es-base__title"
              >{el.name}</a>
            </div>
          </CTableDataCell>

          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">
              <CButton color="info" className="default-table__action-btn"
                       onClick={(e) => handleRedirectToUpdate(e, el.id)}
              >
                <i className="fa fa-pencil-square-o color-white" aria-hidden="true"> </i>
              </CButton>
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  }

  return (
    <>
      <div className="pb-3 mb-4 title-divider d-flex justify-content-between align-items-center">
        <h1>{t('templates.listOfAnalyticsTemplates')}</h1>
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={handleRedirectToCreateTemplate}
        >
          {t('templates.createATemplate')}
        </CButton>
      </div>

      <CTable bordered striped className="default-table">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell style={{width: '90%'}}
                              scope="col"
                              className="sorted-table-cell"
                              // onClick={() => onFilterHandler('title')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('settings.groups.name')}</span>
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell scope="col" className="default-table__actions">
              {t('settings.users.actions')}
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {templatesList}
        </CTableBody>
      </CTable>
    </>
  );
};

export default Analytics;
