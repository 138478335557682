import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const dailyInfoApi = createApi({
    reducerPath: 'dailyInfo/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['DailyInfo'],
    endpoints: build => ({
        getDailyInfo: build.query<any, string | void>({
            query: (query) => {
                return {
                    url: `/list/daily/operational/${query || ''}`,
                }
            },
            providesTags: ['DailyInfo'],
        }),
        createDailyInfo: build.mutation<any, any>({
            query: (body) => ({
                url: '/create/daily/operational/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['DailyInfo'],
        }),
        createDailyInfoFile: build.mutation<any, any>({
            query: (body) => ({
                url: '/create/file/daily/operational/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['DailyInfo'],
        }),
        removeDailyInfo: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/daily/operational/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DailyInfo'],
        }),
        removeDailyInfoFile: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/file/daily/operational/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DailyInfo'],
        }),
    })
})

export const {
    useGetDailyInfoQuery,
    useLazyGetDailyInfoQuery,
    useCreateDailyInfoMutation,
    useCreateDailyInfoFileMutation,
    useRemoveDailyInfoMutation,
    useRemoveDailyInfoFileMutation,
} = dailyInfoApi;