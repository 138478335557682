import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const authApi = createApi({
    reducerPath: 'auth/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['Auth'],
    endpoints: build => ({
        createAuth: build.mutation<any, any>({
            query: (body) => ({
                url: '/auth/jwt/create/',
                method: 'POST',
                body
            })
        }),
        refreshAuth: build.mutation<any, any>({
            query: (body) => ({
                url: '/auth/jwt/refresh/',
                method: 'POST',
                body
            })
        }),
        changePass: build.mutation<any, any>({
            query: (body) => ({
                url: '/change-password/',
                method: 'PATCH',
                body
            })
        }),
    })
})

export const {
    useCreateAuthMutation,
    useRefreshAuthMutation,
    useChangePassMutation
} = authApi;