import React, {useEffect, useRef, useState} from 'react';
import {CButton, CForm, CFormInput, CFormLabel, CFormTextarea} from '@coreui/react';
import {useTranslation} from 'react-i18next';
import {useLocation} from "react-router-dom";
import {
    useCreateAssigmentFilesMutation,
    useCreateAssigmentMutation,
    useLazyGetAssigmentByIdQuery,
    useRemoveAssigmentFileMutation,
    useUploadAssignmentMutation
} from '../../../store/assignments/assignments.api';
import {IAssigmentNew, ISelectType} from '../../../interfaces/IAssignments';
import {useGetReadCurrentUserQuery} from '../../../store/account/account.api';
import {useNavigate} from 'react-router-dom';
import {useLazyGetUsersQuery} from '../../../store/users/users.api';
import {IUser} from '../../../interfaces/IUsers';
import Select from 'react-select';
import DatePicker, {registerLocale} from 'react-datepicker';
import {enUS, ru} from "date-fns/locale";
import makeQuery from '../../../functions/makeQuery';
import CIcon from '@coreui/icons-react';
import {cilX} from '@coreui/icons';
import * as icon from '@coreui/icons';
import "react-datepicker/dist/react-datepicker.css";
import 'dayjs/locale/ky';
import PriorityComponent from '../dragAndDrop/components/PriorityComponent';

registerLocale('en', enUS);
registerLocale('ru', ru);
registerLocale('ky', ru);

const reactSelectControlStyles = (baseStyles: any, state: any): any => {
    return {
        ...baseStyles,
        '&:hover': {
            borderColor: state.isFocused ? '#998fed' : '#d8dbe0'
        },
        borderColor: state.isFocused ? '#998fed' : '#d8dbe0',
        boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(50, 31, 219, 0.25)' : 'unset',
        marginBottom: '5px'
    }
};

const CreateAssigment = () => {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const statusAllArray: ISelectType[] = [
        {value: 'generated', label: t('dragNdrop.generated')},
        {value: 'in_work', label: t('dragNdrop.in_work')},
        {value: 'done', label: t('dragNdrop.done')},
        {value: 'completed', label: t('dragNdrop.completed')}
    ];

    const priorityAllArray: ISelectType[] = [
        {
            value: 'highest', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <i className="fa fa-angle-double-up" aria-hidden="true" style={{color: 'red', fontSize: '23px'}}/>
                <span>{t('createAssignment.highest')}</span>
            </div>
        },
        {
            value: 'high', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <i className="fa fa-angle-up" aria-hidden="true" style={{color: 'red', fontSize: '23px'}}/>
                <span>{t('createAssignment.high')}</span>
            </div>
        },
        {
            value: 'medium', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <i style={{color: 'orange', fontSize: '23px', fontStyle: 'normal'}}>=</i>
                <span>{t('createAssignment.medium')}</span>
            </div>
        },
        {
            value: 'low', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <i className="fa fa-angle-down" aria-hidden="true" style={{color: 'blue', fontSize: '23px'}}/>
                <span>{t('createAssignment.low')}</span>
            </div>
        },
        {
            value: 'lowest', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <i className="fa fa-angle-double-down" aria-hidden="true" style={{color: 'blue', fontSize: '23px'}}/>
                <span>{t('createAssignment.lowest')}</span>
            </div>
        },
    ];

    const [createAssigment] = useCreateAssigmentMutation();
    const [createAssigmentFiles] = useCreateAssigmentFilesMutation();
    const [uploadAssignment] = useUploadAssignmentMutation();
    const [removeAssigmentFile] = useRemoveAssigmentFileMutation();
    const [getAssigmentById, {data: task}] = useLazyGetAssigmentByIdQuery();
    const {data: me} = useGetReadCurrentUserQuery();
    const [getUsers, {data: users}] = useLazyGetUsersQuery();
    const [error, setError] = useState(null);
    const [filesState, setFilesState] = useState<any[]>([]);
    const [filesFromApiState, setFilesFromApiState] = useState<any[]>([]);
    const [assignmentFilesState, setAssignmentFilesState] = useState<any[]>([]);
    const [filesForDelete, setFilesForDelete] = useState<number[]>([]);
    const [redirecting, setRedirecting] = useState(false);
    const fileInputRef = useRef(null);

    const [data, setData] = useState({
        title: '',
        description: '',
        datetime_deadline: null,
        status: statusAllArray.find(s => s.value === 'generated'),
        priority: priorityAllArray.find(p => p.value === 'medium'),
        responsible_executor: {label: '', value: ''},
        executors_report: [],
    });

    const [dataRequired, setDataRequired] = useState({
        title: '',
        description: '',
        datetime_deadline: '',
        status: '',
        priority: '',
        responsible_executor: '',
        executors_report: '',
    });

    const usersAllArray: any[] = [];
    const extension = ['png', 'jpeg', 'jpg', 'gif', 'raw', 'tiff', 'bmp', 'psd'];

    useEffect(() => {
        // redirecting && navigate({pathname: '/assignments/assignments-main'});
        if (redirecting) navigate(-1);
    }, [redirecting]);

    useEffect(() => {
        const newDataRequired = {
            title: '',
            description: '',
            datetime_deadline: '',
            status: '',
            priority: '',
            responsible_executor: '',
            executors_report: ''
        };
        setDataRequired(newDataRequired);
    }, [data]);

    useEffect(() => {
        if (makeQuery.parse(location.search).id) {
            const idTask = makeQuery.parse(location.search).id
            getAssigmentById(Number(idTask));
        }
    }, [getAssigmentById, location]);

    useEffect(() => {
        const status = makeQuery.parse(location.search).status
        const getData: any = {}
        if (task) {
            getData.title = task?.title || '';
            getData.description = task?.description || '';
            getData.datetime_deadline = task?.datetime_deadline || '';
            getData.status = {label: t(`dragNdrop.${status}`) || '', value: status || ''};
            getData.priority = {label: t(`createAssignment.${task?.priority}`) || '', value: task?.priority || '',};
            getData.responsible_executor = {
                value: String(task?.responsible_executor?.id),
                label: task?.responsible_executor?.username
            };
            getData.executors_report = task?.executors_report.map(user => {
                return {value: String(user.id), label: user.username}
            }) || [];

            setData(getData);
            if (task?.file_assignments) {
                setFilesFromApiState(task?.file_assignments);
            }
        }
    }, [task, location, t]);

    // const priorityAllArray:ISelectType[] = [
    //     {value: 'highest', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
    //             <PriorityComponent priority='highest'/> {t('createAssignment.highest')}
    //         </div>},
    //     {value: 'high', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
    //             <PriorityComponent priority='high'/> {t('createAssignment.high')}
    //         </div>},
    //     {value: 'medium', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
    //             <PriorityComponent priority='medium'/> {t('createAssignment.medium')}
    //         </div>},
    //     {value: 'low', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
    //             <PriorityComponent priority='low'/> {t('createAssignment.low')}
    //         </div>},
    //     {value: 'lowest', label: <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
    //             <PriorityComponent priority='lowest'/> {t('createAssignment.lowest')}
    //         </div>},
    // ];

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    users && users.results.forEach((user: IUser) => {
        const newSelectData: ISelectType = {value: String(user.id), label: user.username};
        usersAllArray.push(newSelectData);
    });

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let statusStr = '', priorityStr = '';
        if (data?.status?.value && data?.priority?.value) {
            statusStr = String(data.status.value);
            priorityStr = String(data.priority.value);
        } else return null;

        const formData: IAssigmentNew = {
            datetime_deadline: data.datetime_deadline!,
            title: data.title,
            description: data.description,
            status: statusStr,
            priority: priorityStr,
            author: me.id,
            responsible_executor: data.responsible_executor && Number(data.responsible_executor.value),
            executors_report: data.executors_report.map((e: any) => Number(e.value))
        };

        if (data.responsible_executor) {
            formData.executors_report.includes(Number(data.responsible_executor.value));
        }

        if (makeQuery.parse(location.search).id) {
            formData.id = Number(makeQuery.parse(location.search).id);
            uploadAssignment(formData)
                .then(res => {
                    //@ts-ignore
                    if (res?.error?.data) {
                        //@ts-ignore
                        Object.keys(res?.error?.data).map((ele) => {
                            //@ts-ignore
                            if (!res?.error?.error) {
                                //@ts-ignore
                                return setDataRequired(prev => ({
                                    ...prev,
                                    //@ts-ignore
                                    [ele]: res?.error?.data[ele].join()
                                }));
                            } else {
                                //@ts-ignore
                                setError(res?.error?.error)
                            }
                        })
                    }

                    if (filesState.length) {
                        filesState.forEach((el) => {
                            const formData = new FormData();
                            // @ts-ignore
                            formData.append('assignment', res?.data?.id);
                            formData.append('file', el);
                            createAssigmentFiles(formData).then(res => {
                                // @ts-ignore
                                if (res?.error?.file) {
                                    // @ts-ignore
                                    setError(res?.error?.file?.join())
                                }
                            })
                        });
                    }
                    //@ts-ignore
                    if (!res?.error?.data) {
                        setRedirecting(true);
                    }
                }).catch((er) => {
                setError(er);
            })
        }

        if (!makeQuery.parse(location.search).id) {
            createAssigment(formData)
                .then(res => {
                    //@ts-ignore
                    if (res?.error?.data) {
                        //@ts-ignore
                        Object.keys(res?.error?.data).map((ele) => {
                            //@ts-ignore
                            if (!res?.error?.error) {
                                //@ts-ignore
                                return setDataRequired(prev => ({
                                    ...prev,
                                    //@ts-ignore
                                    [ele]: res?.error?.data[ele].join()
                                }));
                            } else {
                                //@ts-ignore
                                setError(res?.error?.error)
                            }
                        })
                    }
                    if (filesState.length) {
                        filesState.forEach((el) => {
                            const formData = new FormData();
                            // @ts-ignore
                            formData.append('assignment', res?.data?.id);
                            formData.append('file', el);
                            createAssigmentFiles(formData).then(res => {
                                // @ts-ignore
                                if (res?.error?.file) {
                                    // @ts-ignore
                                    setError(res?.error?.file?.join())
                                }
                            })
                        });
                    }
                    //@ts-ignore
                    if (!res?.error?.data) {
                        setRedirecting(true);
                    }
                }).catch((er) => {
                setError(er);
            })
        }
    };

    const onChangeSelectHandler = (event: ISelectType, name: string) => {
        setData((prev) => ({...prev, [name]: event}));
    };

    const onChangeInputHandler = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setData((prev) => ({...prev, [name]: value}));
    };

    const onChangeDateHandler = (event: any) => {
        setData((prev) => ({...prev, datetime_deadline: event}));
    };

    const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = e.target;
        let tempFiles = [...filesState]

        if (e.target.type === 'file') {
            const chosenFiles = Array.prototype.slice.call(files);
            chosenFiles.forEach(el => {
                if (!tempFiles.find(item => item.name === el.name)) {
                    tempFiles.push(el)
                }
            })

            setFilesState(tempFiles);
            updateFileInput(tempFiles);
        }
    }

    const updateFileInput = (files: any) => {
        const dataTransfer = new DataTransfer();
        files.forEach((file: any) => dataTransfer.items.add(file));
        // @ts-ignore
        fileInputRef.current.files = dataTransfer.files;
    };

    const handleDeleteFile = (name: string) => {
        let arr = [...filesState];
        let index = arr.findIndex(el => el.name === name);
        arr.splice(index, 1);
        setFilesState(arr);

        updateFileInput(arr);
    }

    const handleDeleteAssignmentFile = (id: number) => {
        let arr = [...assignmentFilesState];
        let idsArr = [...filesForDelete];
        let idx = arr.findIndex(el => el.id === id);
        arr.splice(idx, 1);

        if (!idsArr.includes(id)) {
            idsArr.push(id);
        }

        setFilesFromApiState(filesFromApiState.filter(p => p.id !== id));
        removeAssigmentFile(id);

        setAssignmentFilesState(arr);
        setFilesForDelete(idsArr);
    }

    const getDataWithQueryHandler = (url: string) => {
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('target', '_blank');
        element.click();
    };

    return (
        <div>
            <CForm onSubmit={onSubmitHandler}>
                <div className={'mb-3'}>
                    <CFormLabel>
                        {t("createAssignment.status")}
                        <span style={{color: 'red'}}>&nbsp;*</span>
                    </CFormLabel>
                    <Select
                        // styles={{
                        //     control: (baseStyles, state) => ({
                        //         ...baseStyles,
                        //         borderColor: !dataRequired.status ? '#ddd' : 'red',
                        //         marginBottom: '5px'
                        //     }),
                        // }}
                        styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                        options={statusAllArray}
                        value={statusAllArray.find((p: ISelectType) => p.label === data?.status?.label)}
                        placeholder={t('createAssignment.chose_status')}
                        onChange={(e: any) => onChangeSelectHandler(e, 'status')}
                    />
                    {dataRequired.status && <span style={{color: 'red'}}>{dataRequired.status}</span>}
                </div>

                <div className={'mb-3'}>
                    <CFormLabel>
                        {t("createAssignment.priority")}
                        <span style={{color: 'red'}}>&nbsp;*</span>
                    </CFormLabel>
                    <Select
                        // styles={{
                        //     control: (baseStyles, state) => ({
                        //         ...baseStyles,
                        //         borderColor: !dataRequired.priority ? '#ddd' : 'red',
                        //         marginBottom: '5px'
                        //     }),
                        // }}
                        styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                        options={priorityAllArray}
                        value={priorityAllArray.find((p: ISelectType) => p.value === data?.priority?.value)}
                        placeholder={t('createAssignment.chose_priority')}
                        onChange={(e: any) => onChangeSelectHandler(e, 'priority')}
                    />
                    {dataRequired.priority && <span style={{color: 'red'}}>{dataRequired.priority}</span>}
                </div>
                <div className={'mb-3'}>
                    <CFormLabel>
                        {t("createAssignment.responsible_executor")}
                        <span style={{color: 'red'}}>&nbsp;*</span>
                    </CFormLabel>
                    <Select
                        // styles={{
                        //     control: (baseStyles, state) => ({
                        //         ...baseStyles,
                        //         borderColor: !dataRequired.responsible_executor ? '#ddd' : 'red',
                        //         marginBottom: '5px'
                        //     }),
                        // }}
                        styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                        options={usersAllArray}
                        value={data.responsible_executor.label ? data.responsible_executor : null}
                        placeholder={t('createAssignment.chose_responsible_executor')}
                        onChange={(e: any) => onChangeSelectHandler(e, 'responsible_executor')}
                    />
                    {dataRequired.responsible_executor &&
                        <span style={{color: 'red'}}>{dataRequired.responsible_executor}</span>}
                </div>
                <div className={'mb-3'}>
                    <CFormLabel>
                        {t("createAssignment.executors_report")}
                        {/*<span style={{color: 'red'}}>&nbsp;*</span>*/}
                    </CFormLabel>
                    <Select
                        styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                        options={usersAllArray}
                        value={data.executors_report}
                        placeholder={t('createAssignment.chose_executors_report')}
                        onChange={(e: any) => onChangeSelectHandler(e, 'executors_report')}
                        closeMenuOnSelect={false}
                        isClearable
                        isMulti
                    />
                    {/*{dataRequired.executors_report && <span style={{color: 'red'}}>{dataRequired.executors_report}</span>}*/}
                </div>

                <div style={{height: '50px'}} className="mb-4">
                    <div className="react-date-picker-wrapper">
                        <CFormLabel>
                            {/*{t("createAssignment.deadline")}*/}
                            {<>{t("createAssignment.deadline")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                            {/*<span style={{color: 'red'}}>&nbsp;*</span>*/}
                        </CFormLabel>
                        <div style={{
                            border: `1px solid ${dataRequired.datetime_deadline ? 'red' : 'none'}`,
                            borderRadius: '5px'
                        }}>
                            <DatePicker
                                onChange={onChangeDateHandler}
                                showTimeInput
                                selected={data.datetime_deadline}
                                dateFormat={'dd-MM-yyyy H:mm'}
                                locale={i18n.language}
                                className={"ml-5 form-control iams-default-form-control"}
                            />
                        </div>
                        {/*{dataRequired.datetime_deadline && <span style={{color: 'red'}}>{dataRequired.datetime_deadline}</span>}*/}
                    </div>
                </div>

                <div className="mb-3">
                    <CFormInput
                        type="text"
                        label={<>{t("createAssignment.title")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                        name={'title'}
                        value={data.title}
                        onChange={onChangeInputHandler}
                        // className={'emergency-custom-input'}
                        className={'iams-default-form-control'}
                        placeholder={t('createAssignment.whriteTitle')}
                        // style={{border: `1px solid ${dataRequired.title && 'red'}`, marginBottom: '5px'}}
                    />
                    {dataRequired.title && <span style={{color: 'red'}}>{dataRequired.title}</span>}
                </div>

                <div className="mb-3">
                    <CFormTextarea
                        label={<>{t("createAssignment.description")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                        name={'description'}
                        value={data.description}
                        onChange={onChangeInputHandler}
                        // className={'emergency-custom-textarea emergency-custom-input'}
                        className={'iams-default-form-control'}
                        placeholder={t('createAssignment.whriteDescription')}
                        // style={{border: `1px solid ${dataRequired.description && 'red'}`, marginBottom: '5px'}}
                    />
                    {dataRequired.description && <span style={{color: 'red'}}>{dataRequired.description}</span>}
                </div>
                <div className="mb-3">
                    <CFormInput
                        type="file"
                        name={'material_damage'}
                        onChange={handleChangeFiles}
                        className={'emergency-custom-input mb-3'}
                        label={t('gis.geoObjects.image')}
                        ref={fileInputRef}
                        multiple
                    />
                    <div style={{display: "flex", justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px'}}>
                        {filesState.map((item: any, i: number) => {

                            return (
                                <div key={i + '_pic'} className="image-wrapper">
                                    <div className="image-delete-icon-wrapper"
                                         onClick={() => handleDeleteFile(item.name)}
                                    >
                                        <CIcon icon={cilX} className="image-delete-icon"/>
                                    </div>
                                    {!item.type.includes("image")
                                        ?
                                        <div className="file-item">
                                            {item.name}
                                        </div>
                                        :
                                        <img alt={""}
                                             src={URL.createObjectURL(item)}
                                             style={{height: '110px', width: '170px'}}
                                        />
                                    }
                                </div>
                            )
                        })}
                        {filesFromApiState.map((item: any, i: number) => {
                            let decodedName;
                            const itemName = item.file.split('/')[item.file.split('/').length - 1];
                            if (itemName.split('.').includes('docx')) decodedName = decodeURIComponent(itemName);

                            return (
                                <div key={i + '_pic'} className="image-wrapper">
                                    <div className="image-delete-icon-wrapper"
                                         onClick={() => handleDeleteAssignmentFile(item.id)}
                                    >
                                        <CIcon icon={cilX} className="image-delete-icon"/>
                                    </div>
                                    <div onClick={() => getDataWithQueryHandler(item.file)} style={{cursor: 'pointer'}}>
                                        {!extension.includes(item.file.split('.')[item.file.split('.').length - 1])
                                            ?
                                            <div className="file-item">
                                                {decodedName}
                                                {/*<CIcon icon={icon.cilPaperclip} size="xxl" style={{cursor: 'pointer', width: '170px'}}/>*/}
                                            </div>
                                            :
                                            <img alt={""}
                                                 src={item.file}
                                                 style={{height: '110px', width: 'auto'}}
                                            />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {error && <span style={{color: 'red'}}>{error}</span>}
                <div className="mb-3">
                    <span style={{color: 'red'}}>*&nbsp;</span><span>{t('createAssignment.field star')}</span>
                </div>
                <CButton
                    className="mb-3"
                    type='submit'
                    disabled={
                        !data?.status?.value ||
                        !data?.priority?.value ||
                        !data.executors_report ||
                        !data.title ||
                        !data.description ||
                        !data.datetime_deadline
                    }
                >{makeQuery.parse(location.search).id ? t('createAssignment.edit') : t('createAssignment.create')}
                </CButton>
            </CForm>
        </div>
    );
};

export default CreateAssigment;