import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import {useTranslation} from "react-i18next";
import PublicESReportsCard from "./PublicESReportsCard";
import {CFormSelect} from "@coreui/react";
import {useLazyGetEsListQuery, useUpdateEsStatusMutation} from "../../../store/emergencyMessages/emergencyMessages.api";
import FormGroup from "../../../components/UI/Forms/FormGroup";
import makeQuery from "../../../functions/makeQuery";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";

export const statusMessagesES = {
    processed: "processed",
    rejected: "rejected"
}

interface Option {
    value: string;
    label: string;
}

const PublicEsReports = () => {
    const {t} = useTranslation();

    const [fetchEsList, {data: esList, isLoading: esListLoading}] = useLazyGetEsListQuery();
    const [updateEsStatus, {isLoading: updateEsStatusLoading}] = useUpdateEsStatusMutation();

    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const [queryState, setQueryState] = useState<string>(makeQuery.stringify({}));
    const [confirmModal, setConfirmModal] = useState<number>(0);

    const isLoading = useMemo(() => {
        return (esListLoading || updateEsStatusLoading);
    }, [esListLoading || updateEsStatusLoading]);

    useEffect(() => {
        fetchEsList(queryState);
    }, [queryState]);

    useEffect(() => {
        const query: { [key: string]: string } = {}
        query.status = selectedStatus;
        setQueryState(makeQuery.stringify(query))
    }, [selectedStatus]);

    const options: Option[] = [
        {value: '', label: `${t('esFeed.statusAll')}`},
        {value: 'created', label: `${t('esFeed.statusCreated')}`},
        {value: statusMessagesES.processed, label: `${t('esFeed.statusProcessedDone')}`},
        {value: statusMessagesES.rejected, label: `${t('esFeed.statusRejectedDone')}`}
    ];

    const handleChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        setSelectedStatus(event.target.value);
    };

    const changeStatus = (e: React.MouseEvent<HTMLDivElement>, id: number, status: string) => {
        e.stopPropagation();
        if (status && id) {
            if (status === statusMessagesES.rejected) {
                onToggleConfirmModal(id);
                return null;
            }
            updateEsStatus({
                body: {id: id, status: status}, id: id
            })
        } else {
            return
        }
    }

    const onToggleConfirmModal = (id: number = 0) => {
        setConfirmModal(id);
    }

    const onRejectReportHandler = () => {
        updateEsStatus({
            body: {id: confirmModal, status: statusMessagesES.rejected}, id: confirmModal
        }).then(() => {
            onToggleConfirmModal(0);
        });
    }

    return (
        <>
            <div className="pb-3 mb-4 title-divider">
                <h1>{t('esFeed.esReportTitle')}</h1>
                <LoaderLine visible={isLoading}/>
            </div>

            <div className="public_es_reports">
                <FormGroup className="main-label status-input-es mb-3 ms-auto" htmlFor="status">
                    <CFormSelect size="sm"
                                 id="status"
                                 label={t("esFeed.statusTitle")}
                                 value={selectedStatus}
                                 name="status"
                                 onChange={handleChange}
                                 options={options}
                    />
                </FormGroup>
                {esList?.length ? (
                    esList.map((el: any) => (
                        <div key={el.id}>
                            <PublicESReportsCard
                                item={el}
                                onClick={changeStatus}
                            />
                        </div>
                    ))
                ) : (
                    !isLoading && <p>{t('emptyReports')}</p>
                )}
            </div>

            <DefaultModal visible={!!confirmModal}
                          setVisible={onToggleConfirmModal}
                          backdrop
                          title={t('deletion')}
                          type="danger"
                          cancelButton={t('cancel')}
                          onOk={onRejectReportHandler}
            >
                <div>
                    <p className="mb-2 fs-6">{t('esManagement.Do you really want to reject this message')}</p>
                </div>
            </DefaultModal>
        </>
    );
};

export default PublicEsReports;
