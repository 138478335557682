import {createBrowserRouter} from "react-router-dom";

import DefaultLayout from "./layout/DefaultLayout";
import Home from "./views/home/Home";
import EsFeed from "./views/EsManagement/EsFeed/EsFeed";
import GeoBase from "./views/GIS/GeoBase/GeoBase";
import EsBase from "./views/EsManagement/EsBase/EsBase";
import EsProcessed from "./views/EsManagement/EsProcessed/EsProcessed";
import EsDetail from "./views/EsManagement/EsDetail/EsDetail";
import EsDailyReports from "./views/EsManagement/EsDailyReports/EsDailyReports";
import EsDailyReportsNew from "./views/EsManagement/EsDailyReports/EsDailyReportNew";
import NewEsReport from "./views/EsManagement/NewEsReport/NewEsReport";
import EsMap from "./views/EsManagement/EsMap/EsMap";
import PublicEsReports from "./views/EsManagement/PublicEsReports/PublicEsReports";
import RejectedPublicEsReports from "./views/EsManagement/PublicEsReports/RejectedPublicEsReports";
import CardsFrom112 from "./views/EsManagement/CardsFrom112/CardsFrom112";
import EsGeneralInformation from "./views/EsManagement/EsGeneralInformation/EsGeneralInformation";
import Es1Form from "./views/EsManagement/Es1Form/Es1Form";
import GeoObjects from "./views/GIS/GeoObjectsManagement/GeoObjects";
import GeoCategories from "./views/GIS/GeoObjectsManagement/GeoCategories";
import GeoTypes from "./views/GIS/GeoObjectsManagement/GeoTypes";
import ObjectsImport from "./views/GIS/ObjectsImport/ObjectsImport";
import ObjectsExport from "./views/GIS/ObjectExport/ObjectExport";
import DocumentTypes from "./views/Registry/Documents/DocumentTypes";
import Documents from "./views/Registry/Documents/Documents";
import Document from "./views/Registry/Documents/Document";
import Dictionaries from "./views/Registry/Dictionaries/Dictionaries";
import NewDictionary from "./views/Registry/Dictionaries/NewDictionary";
import Reports from "./views/Registry/Reports/Reports";
import NewReport from "./views/Registry/Reports/NewReport";
import Analytics from "./views/Analytics/Analytics";
import AnalyticsTemplate from "./views/Analytics/AnalyticsTemplate";
import ListOfServices from "./views/Services/ListOfServices/ListOfServices";
import SingleServiceInfo from "./views/Services/ListOfServices/SingleServiceInfo";
import VideoStreams from "./views/VideoStreams/VideoStreams/VideoStreams";
import ChannelsWall from "./views/VideoStreams/ChannelsWall/ChannelsWall";
import RIS from "./views/RIS/RIS";
import EarthMap from "./views/EarthMap/EarthMap";
import PublicMobileAdmin from "./views/PublicMobileAdmin/PublicMobileAdmin";
import VersionHistory from "./views/Settings/VersionHistory/VersionHistory";
import Regions from "./views/Settings/Regions/Regions";
import GeneralSettings from "./views/Settings/GeneralSettings/GeneralSettings";
import BrowserPolicy from "./views/Settings/BrowserPolicy/BrowserPolicy";
import GeoIcons from "./views/Settings/GeoIcons/GeoIcons";
import UserManual from "./views/Settings/UserManual/UserManual";
import EsTypes from "./views/Settings/EsTypes/EsTypes";
import AuditLog from "./views/Settings/AuditLog/AuditLog";
import GroupsList from "./views/Settings/Users/GroupsList";
import Users from "./views/Settings/Users/Users";
import ChannelsWallSettings from "./views/VideoStreams/ChannelsWallSettings/ChannelsWallSettings";
import Profile from "./views/Profile/Profile";
import AssignmentsMain from "./views/Assignments/Assignments-main/AssignmentsMain";
import Generated from "./views/Assignments/Generated/Generated";
import InWork from "./views/Assignments/InWork/InWork";
import Done from "./views/Assignments/Done/Done";
import Completed from "./views/Assignments/Completed/Completed";
import NewGeoObject from "./views/GIS/GeoObjectsManagement/NewGeoObject";
import AnalyticReport from "./views/Analytics/AnalyticsReport/AnalyticReport";
import CreateAssigment from "./views/Assignments/CreateAssigment/CreateAssigment";

const router = createBrowserRouter([
    {
        element: <DefaultLayout/>,
        children: [
            {
                element: <Profile/>,
                path: '/profile',
            },
            {
                element: <EsFeed/>,
                path: '/es-feed',
            },
            {
                element: <EsBase/>,
                path: '/es-base',
            },
            {
                element: <EsDetail/>,
                path: '/es-base/detail/:from/:id'
            },
            {
                element: <EsProcessed/>,
                path: '/es-base-drafts',
            },
            {
                element: <EsDailyReports/>,
                path: '/es-daily-reports/list',
            },
            {
                element: <EsDailyReportsNew/>,
                path: '/es-daily-reports/new',
            },
            {
                element: <NewEsReport/>,
                path: '/es-report/create',
            },
            {
                element: <NewEsReport/>,
                path: '/es-report/update/:id',
            },
            {
                element: <EsMap/>,
                path: '/es-map',
            },
            {
                element: <PublicEsReports/>,
                path: '/es-public-reports/reports',
            },
            {
                element: <RejectedPublicEsReports/>,
                path: '/es-public-reports/rejected',
            },
            {
                element: <CardsFrom112/>,
                path: '/es-cards-from112',
            },
            {
                element: <EsGeneralInformation/>,
                path: '/es-general',
            },
            {
                element: <Es1Form/>,
                path: '/es-es1-report-form',
            },
            {
                element: <GeoBase/>,
                path: '/gis-geo-base',
            },
            {
                element: <GeoObjects/>,
                path: '/gis-geo-objects/list',
            },
            {
                element: <GeoCategories/>,
                path: '/gis-geo-categories/list',
            },
            {
                element: <GeoTypes/>,
                path: '/gis-geo-types/list',
            },
            {
                element: <ObjectsImport/>,
                path: '/gis-objects-import',
            },
            {
                element: <ObjectsExport/>,
                path: '/gis-objects-export',
            },
            {
                element: <AssignmentsMain/>,
                path: '/assignments/assignments-main',
            },
            {
                element: <CreateAssigment/>,
                path: '/assignments/assignments-main/create-edit-assignment',
            },
            {
                element: <Generated/>,
                path: '/assignments/generated',
            },
            {
                element: <InWork/>,
                path: '/assignments/in-work',
            },
            {
                element: <Done/>,
                path: '/assignments/done',
            },
            {
                element: <Completed/>,
                path: '/assignments/completed',
            },
            {
                element: <NewGeoObject/>,
                path: '/gis-geo-object',
            },
            {
                element: <NewGeoObject/>,
                path: '/gis-geo-object/:id',
            },
            {
                element: <DocumentTypes/>,
                path: '/registry-document-types',
            },
            {
                element: <Documents/>,
                path: '/registry-document-types/:id',
            },
            {
                element: <Document/>,
                path: '/registry-document/:id',
            },
            {
                element: <Dictionaries/>,
                path: '/registry-dictionaries/list',
            },
            {
                element: <NewDictionary/>,
                path: '/registry-dictionaries/new',
            },
            {
                element: <Reports/>,
                path: '/registry-reports/list',
            },
            {
                element: <NewReport/>,
                path: '/registry-reports/new',
            },
            {
                element: <Analytics/>,
                path: '/analytic-templates',
            },
            {
                element: <AnalyticsTemplate/>,
                path: '/analytic-templates/create',
            },
            {
                element: <AnalyticsTemplate/>,
                path: '/analytic-templates/update/:id',
            },
            {
                element: <AnalyticReport/>,
                path: '/analytic/report'
            },
            {
                element: <AnalyticReport/>,
                path: '/analytic/report/:id'
            },
            {
                element: <ListOfServices/>,
                path: '/services',
            },
            {
                element: <SingleServiceInfo/>,
                path: '/services/:id',
            },
            {
                element: <VideoStreams/>,
                path: '/video-streams',
            },
            {
                element: <ChannelsWall/>,
                path: '/channels-wall',
            },
            // {
            //     element: <ChannelsWallSettings/>,
            //     path: '/channels-wall-settings',
            // },
            {
                element: <Users/>,
                path: '/users/list',
            },
            {
                element: <GroupsList/>,
                path: '/users/groups-list',
            },
            {
                element: <AuditLog/>,
                path: '/audit-log',
            },
            {
                element: <EsTypes/>,
                path: '/es-types',
            },
            {
                element: <UserManual/>,
                path: '/user-manual',
            },
            {
                element: <GeoIcons/>,
                path: '/geo-icons',
            },
            {
                element: <BrowserPolicy/>,
                path: '/browser-policy',
            },
            {
                element: <GeneralSettings/>,
                path: '/general-settings',
            },
            {
                element: <Regions/>,
                path: '/regions',
            },
            {
                element: <VersionHistory/>,
                path: '/version-history',
            },
            {
                element: <PublicMobileAdmin/>,
                path: '/public-mobile-admin',
            },
            {
                element: <EarthMap/>,
                path: '/earth-map',
            },
            {
                element: <RIS/>,
                path: '/ris',
            },
        ],
    },
    {
        element: <Home/>,
        path: '/',
    },
    // {
    //     element: <Page404/>,
    // }
]);
export default router;
