import React from 'react';

import PaginationSize from "./PaginationSize";
import PaginationSearch from "./PaginationSearch";
import PaginationStatus from "./PaginationStatus";
import PaginationManage from "./PaginationManage";

interface DefaultPaginationWrapperProps {
    children?: React.ReactNode;
    data: { page: number; results: any[]; total: number; pages: number } | any;
    state: { page_size?: any; search?: string; page?: number };
    sizeOptions?: { label: string; value: string }[];
    onChange: (state: { [key: string]: any }) => void;
    onSearchDelay?: number;
    onSearchCallback?: any;
    showSearch?: boolean; // Новый пропс
}
const DefaultPaginationWrapper: React.FC<DefaultPaginationWrapperProps> = (
  {
    children,
    data,
    state,
    sizeOptions,
    onChange,
    onSearchDelay = 3000,
    onSearchCallback,
    showSearch = true, // Новый пропс
}) => {

  let fromEntry = 0, toEntry = 0, totalEntries = 0, totalPages = 1;
  try {
    const pageSize = isNaN(state.page_size) ? 100 : parseInt(state.page_size);
    fromEntry = (data.page - 1) * pageSize + 1;
    toEntry = fromEntry - 1 + data.results.length;
    totalEntries = data.total;
    totalPages = data.pages;
  } catch (e) {}

  const onChangeHandler = (data: {name: string, value: string | number}) => {
    const state: any = {};
    state[data.name] = data.value;
    onChange(state);
  };

  return (
    <div className="pagination-wrapper">
      <div className="d-flex justify-content-between">
        <PaginationSize name={'page_size'}
                        value={state.page_size || '100'}
                        options={sizeOptions}
                        onChange={e => onChangeHandler({name: 'page_size', value: e.target.value})}
        />
          {showSearch && ( // Условие отображения поиска
              <PaginationSearch name={'search'}
                                value={state.search || ''}
                                onChange={(value: string) => onChangeHandler({name: 'search', value: value})}
                                onSearchDelay={onSearchDelay}
                                onSearchCallback={onSearchCallback}
              />
          )}
      </div>
      {children}
      <div className="d-flex justify-content-between">
        <PaginationStatus from={fromEntry}
                          to={toEntry}
                          of={totalEntries}
        />
        <PaginationManage active={state.page || 1}
                          total={totalPages}
                          onClick={value => onChangeHandler({name: 'page', value})}
        />
      </div>
    </div>
  )
}

export default DefaultPaginationWrapper;
