import dayjs from "dayjs";
import React, {Fragment, useState} from "react";
import {useGetReadCurrentUserQuery} from "../../../../store/account/account.api";
import {useTranslation} from "react-i18next";
import {useUploadCommentMutation} from "../../../../store/assignments/assignments.api";
import {descriptionEdit} from "../../../../views/Assignments/dragAndDrop/utils/tasks";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import '../../../../scss/_comments.scss';
import FileComponents from "../FileComponents/FileComponents";

interface Props {
    description: string,
    author: string,
    created_at: string,
    id: number,
    assignment: number,
    files: [],
}

const MessageComment = ({description, author, created_at, id, assignment, files}: Props) => {
    const {data: me} = useGetReadCurrentUserQuery();
    const [uploadComment] = useUploadCommentMutation();
    const {t} = useTranslation();
    const [desc, setDesc] = useState(description);
    const extension = ['png', 'jpeg', 'jpg', 'gif', 'raw', 'tiff', 'bmp', 'psd'];

    const uploadCommentHandler = () => {
        const formData = {
            description: desc,
            id: id,
            assignment: assignment
        };
        uploadComment(formData);
    };

    const getDataWithQueryHandler = (url: string) => {
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('target', '_blank');
        element.click();
    };

    return (
        <div className="message" key={'messageComment' + id}>
            {me && me.username === author
                ?
                <div className='message-block-Y'>
                    <div className="message-block-Y_you">
                        <h3 className="message-block-Y_you_author">{t('assignmentsPage.you')}</h3>
                        <span className="message-block-Y_you_date">{dayjs(created_at).format('DD.MM.YYYY HH:mm')}</span>
                        <textarea
                            style={{
                                border: 'none',
                                background: 'none',
                                color: '#000',
                                fontSize: '14px',
                                wordWrap: 'break-word',
                                overflow: 'hidden',
                                // width: '175px',
                                width: '100%',
                                resize: 'none',  // добавлено для отключения изменения размера
                                // height: desc.length > 20 ? `${desc.length + 25}px` : '20px'
                                height: 'auto'
                            }}
                            name="desc"
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                            onBlur={uploadCommentHandler}
                            disabled
                        />
                        <div className="msg-block-images">
                            {files && files.map((item: any, i: number) => {

                                return (
                                    <div key={i + '_pic' + id} className="image-wrapper" style={{width: '170px'}}>
                                        <div onClick={() => getDataWithQueryHandler(item.file)}
                                             style={{cursor: 'pointer', width: '170px'}}>
                                            {!extension.includes(item.file.split('.')[item.file.split('.').length - 1])
                                                ?
                                                <div className="file-item">
                                                    {descriptionEdit(item.file.split('/')[item.file.split('/').length - 1], 23)}
                                                </div>
                                                :
                                                <img alt={""}
                                                     src={item.file}
                                                     style={{width: '170px', height: 'auto'}}
                                                />
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                :
                <div className='message-block-U'>
                    <div className="message-block-U_user">
                        <h3 className="message-block-U_user_author">{author}</h3>
                        <span
                            className="message-block-U_user_date">{dayjs(created_at).format('DD.MM.YYYY HH:mm')}</span>
                        <p className="message-block-U_user_message">{description}</p>
                        <div className="msg-block-images">
                            {files && files.map((item: any, i: number) => {
                                // return (
                                //     <div key={i + '_pic'} className="image-wrapper">
                                //         <div onClick={() => getDataWithQueryHandler(item.file)} style={{cursor: 'pointer'}}>
                                //             {!extension.includes(item.file.split('.')[item.file.split('.').length - 1])
                                //                 ?
                                //                     <div className="file-item">
                                //                         <CIcon icon={icon.cilPaperclip} size="xxl" style={{cursor: 'pointer', width: '170px'}}/>
                                //                     </div>
                                //                 :
                                //                     <img alt={""}
                                //                         src={item.file}
                                //                         style={{height: '110px', width: 'auto'}}
                                //                     />
                                //             }
                                //         </div>
                                //     </div>
                                // )
                                return (
                                    <Fragment key={'fileComponent' + item.id}>
                                        <FileComponents
                                            extension={extension}
                                            icon={icon.cilPaperclip}
                                            item={item}
                                            getDataWithQueryHandler={getDataWithQueryHandler}
                                        />
                                    </Fragment>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default MessageComment;