import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IUrgentNotification} from "../../interfaces/IUrgentNotifications";

export const urgentNotificationsApi = createApi({
  reducerPath: 'urgentNotifications/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  endpoints: build => ({
    getUrgentNotifications: build.query<IUrgentNotification[], void>({
      query: () => {
        return {
          url: `/urgent/messages/`
        }
      }
    })
  })
})

export const {useGetUrgentNotificationsQuery} = urgentNotificationsApi;
