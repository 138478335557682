import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const divisionsApi = createApi({
  reducerPath: 'divisions/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  endpoints: build => ({
    getDivisions: build.query<any[], void>({
      query: () => {
        return {
          url: '/divisions/'
        }
      },
    })
  })
})

export const {useGetDivisionsQuery} = divisionsApi;
