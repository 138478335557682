import React, {useEffect, useMemo, useState} from 'react';
import {useGetInfoUserQuery, useUpdateInfoUserMutation} from "../../store/account/account.api";
import {
    CButton,
    CCol,
    CForm,
    CFormInput, CFormLabel,
    CImage, CRow,
} from "@coreui/react";

import avatarPage from "../../assets/images/avatars/avatar-page.svg";
import {useTranslation} from "react-i18next";
import DefaultModal from "../../components/UI/Modals/DefaultModal";
import FormGroup from "../../components/UI/Forms/FormGroup";
import {useNavigate} from "react-router-dom";
import {useChangePassMutation} from "../../store/auth/auth.api";

const Profile = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    // const [getMe, {isError: errorGetMe, isLoading: loadingGetMe, data: me}] = useLazyGetInfoUserQuery();
    const {data: me} = useGetInfoUserQuery();
    const [updateUser] = useUpdateInfoUserMutation();
    const [changePass, {error: changePassError}] = useChangePassMutation();

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editingUser, setEditingUser]: any = useState({});
    const [password, setPassword] = useState({
        passOld: '',
        passNew: '',
        passNewAgain: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showFormPassword, setShowFormPassword] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [avatarPreview, setAvatarPreview]: any = useState('');
    const [error, setError]: any = useState(null);

    useEffect(() => {
        if (me) {
            setEditingUser({
                first_name: me.first_name,
                last_name: me.last_name,
                patronymic: me.patronymic,
                phone_number: me.phone_number,
                email: me.email,
                avatar: me.avatar
            });

            setAvatarPreview(me.avatar || '');
        }
    }, [me]);

    const isChanged = useMemo(() => {
        for (let [key, value] of Object.entries(editingUser)) {
            if (me[key] !== value) return true;
        }
        return false;
    }, [editingUser]);

    const goBackHandler = () => {
        navigate(-1);
    };

    const toggleEditModalHandler = (visible?: boolean) => {
        setError(null);
        setEditModalVisible(!!visible);
        if (!editModalVisible) {
            setShowFormPassword(false);
            setShowModalSuccess(false);
        }
        if (visible && me) {
            setEditingUser({
                first_name: me.first_name,
                last_name: me.last_name,
                patronymic: me.patronymic,
                phone_number: me.phone_number,
                email: me.email,
                avatar: me.avatar
            });
            if (typeof me.avatar !== "string") setAvatarPreview(me.avatar || '');
        }
    };

    const onChangePasswordSubmit = async () => {
        const passwordData = {
            old_password: password?.passOld,
            new_password: password?.passNew,
            repeat_new_password: password?.passNewAgain
        };

        try {
            changePass(passwordData).then((res: any) => {
                if (res?.data) {
                    setShowModalSuccess(true);
                    setShowFormPassword(false);
                    setPassword({
                        passOld: '',
                        passNew: '',
                        passNewAgain: ''
                    });
                }
            });
        } catch (error) {
            console.error('Network error:', error);
        }
    };


    const onEditUserHandler = () => {
        if (!isChanged) {
            toggleEditModalHandler();
            return null;
        }

        const formData: any = new FormData();

        Object.entries(editingUser).forEach((key: any) => {
            if (key[0] !== "avatar") {
                formData.append(key[0], key[1]);
            } else {
                if (key[1] && typeof key[1] !== "string") formData.append(key[0], key[1]);
            }
        });

        updateUser(formData).then(
            () => {
                toggleEditModalHandler();
            },
            () => {
                setError('Something went wrong');
            }
        );
    };

    const onChangeHandler = (event: any) => {
        setEditingUser((prev: any) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    const onChangePasswordHandler = (e: any) => {
        const {name, value} = e.target;
        setPassword((prevPassword) => ({
            ...prevPassword,
            [name]: value,
        }));
    };


    const onChangePhoneHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let {value} = event.target;

        // Удаляем все символы кроме цифр
        value = value.replace(/\D/g, '');

        // Убедимся, что номер всегда начинается с 996
        if (!value.startsWith('996')) {
            value = '996';
        }

        // Ограничиваем количество цифр до 9 после 996
        value = value.slice(0, 12); // 3 цифры для "996" + 9 цифр для остальной части

        // Обновляем состояние
        setEditingUser((prev: any) => ({
            ...prev,
            phone_number: value
        }));
    };


    const onChangeAvatarHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = e?.target;
        const file = files?.length ? files[0] : '';

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const result = reader.result;
                setAvatarPreview(result);
                setEditingUser((prev: any) => ({
                    ...prev,
                    avatar: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            {
                me ?
                    <div className="container-md">
                        <div className="profile-page">
                            <h2 className="profile-page__title">{t('profile.title')}</h2>
                            <div className="profile-page__content">
                                <div className="profile-page__block-img">
                                    <img src={me.avatar ? me.avatar : avatarPage} alt="avatar-page"/>
                                </div>

                                <div className="profile-page__block-info">
                                    <div className="profile-page__block-text">
                                        <p className="profile-page__text">{t("profile.full_name")}:</p>
                                        <p className="profile-page__text">{me.last_name} {me.first_name} {me.patronymic}</p>
                                    </div>

                                    {me.email ? <div className="profile-page__block-text">
                                        <p className="profile-page__text">{t("profile.email")}:</p>
                                        <p className="profile-page__text">{me.email}</p>
                                    </div> : null}

                                    <div className="profile-page__block-text">
                                        <p className="profile-page__text">{t("profile.phone")}:</p>
                                        <p className="profile-page__text">{me.phone_number ? me.phone_number : t('profile.phone_number')}</p>
                                    </div>
                                </div>
                                <button className="btn btn-info btn-sm color-white" type="button"
                                        onClick={() => toggleEditModalHandler(true)}>
                                    {t("profile.edit")}
                                </button>
                                <button className="btn btn-secondary btn-sm color-black ms-4" type="button"
                                        onClick={goBackHandler}
                                >
                                    {t("profile.back")}
                                </button>
                            </div>
                        </div>
                    </div>
                    : null

            }

            <DefaultModal visible={editModalVisible}
                          setVisible={toggleEditModalHandler}
                          title={t(`profile.titleModal`)}
                          cancelButton={t('cancel')}
                          okButton={t('save')}
                          onOk={onEditUserHandler}
                          error={error}
                          size="xl"
            >
                <CForm>
                    <FormGroup htmlFor='username'>
                        <div className="profile-page-top">
                            {/*<div className="profile-page-top-img">*/}
                            <div className="profile-page__img-edit-block">
                                <CImage
                                    className="profile-page__img-edit"
                                    src={avatarPreview || editingUser.avatar}
                                />
                                <i className="fa fa-camera nav-icon" aria-hidden="true"></i>
                                <CFormInput type="file"
                                            id="file"
                                            name="file"
                                            className="profile-page__input-upload"
                                            onChange={onChangeAvatarHandler}
                                />
                            </div>
                            {/*</div>*/}

                            <div className="profile-page-top-change-block">
                                <div className="change-block">
                                    {!showFormPassword ? <CButton size="sm"
                                                                  color='info'
                                                                  onClick={() => {
                                                                      setShowFormPassword(true);
                                                                      setShowModalSuccess(false);
                                                                  }}
                                                                  style={{width: '200px', marginLeft: 'auto'}}
                                    >
                                        {t('profile.changePass')}
                                    </CButton> : null}

                                    {showModalSuccess ?
                                        <p className="change-pass-success">{t('profile.successPass')}</p>
                                        : null}
                                </div>


                                {showFormPassword ? <div className="profile-page-top-change-inputs">
                                        <CRow>
                                            <CRow className="p-0">
                                                <CFormLabel htmlFor="passOld"
                                                            className="col-sm-4 col-form-label align-self-center">{t('profile.oldPass')}</CFormLabel>
                                                <CCol className="align-self-center px-0">
                                                    <CFormInput
                                                        id="passOld"
                                                        name="passOld"
                                                        type={showPassword ? "text" : "password"}
                                                        className="profile-page__input-modal"
                                                        value={password?.passOld}
                                                        onChange={onChangePasswordHandler}
                                                    />

                                                </CCol>
                                            </CRow>

                                            {/*@ts-ignore*/}
                                            {changePassError?.data?.old_password ?
                                                //@ts-ignore
                                                <p className="change-pass-error">{changePassError?.data?.old_password[0]}</p> : null}
                                        </CRow>

                                        <CRow>
                                            <CRow className="p-0">
                                                <CFormLabel htmlFor="passNew"
                                                            className="col-sm-4 col-form-label align-self-center">{t('profile.newPass')}</CFormLabel>
                                                <CCol className="align-self-center px-0">
                                                    <CFormInput
                                                        id="passNew"
                                                        name="passNew"
                                                        type={showPassword ? "text" : "password"}
                                                        className="profile-page__input-modal"
                                                        value={password?.passNew}
                                                        onChange={onChangePasswordHandler}
                                                    />
                                                </CCol>
                                            </CRow>

                                            {/*@ts-ignore*/}
                                            {changePassError?.data?.new_password ?
                                                //@ts-ignore
                                                <p className="change-pass-error">{changePassError?.data?.new_password[0]}</p> : null}
                                        </CRow>

                                        <CRow>
                                            <CRow className="p-0">
                                                <CFormLabel htmlFor="passNewAgain"
                                                            className="col-sm-4 col-form-label align-self-center">{t('profile.repeatNewPass')}</CFormLabel>
                                                <CCol className="align-self-center px-0">
                                                    <CFormInput
                                                        id="passNewAgain"
                                                        name="passNewAgain"
                                                        type={showPassword ? "text" : "password"}
                                                        className="profile-page__input-modal"
                                                        value={password?.passNewAgain}
                                                        onChange={onChangePasswordHandler}
                                                    />
                                                </CCol>
                                            </CRow>

                                            {/*@ts-ignore*/}
                                            {changePassError?.data?.repeat_new_password ?
                                                //@ts-ignore
                                                <p className="change-pass-error">{changePassError?.data?.repeat_new_password[0]}</p> : null}
                                        </CRow>

                                        <div className="profile-page-bottom-change">
                                            {/*@ts-ignore*/}
                                            {changePassError?.data?.non_field_errors
                                                //@ts-ignore
                                                ? changePassError?.data?.non_field_errors?.map((el: any) => (
                                                    <p key={el} className="change-pass-error-other">{el}</p>
                                                )) : null}

                                            <CButton size="sm"
                                                     color='info'
                                                     onClick={() => onChangePasswordSubmit()}
                                                     style={{width: '200px', marginLeft: 'auto'}}
                                            >
                                                {t('profile.savePass')}
                                            </CButton>
                                            <button type="button"
                                                    className="show-password-btn"
                                                    onClick={() => setShowPassword(!showPassword)}
                                            >
                                                <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                                            </button>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>

                        <div className="profile-page-modal-block-inputs">
                            <CRow>
                                <CFormLabel htmlFor="last_name"
                                            className="col-sm-4 col-form-label">{t('profile.last_name')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="last_name"
                                                name="last_name"
                                                type="text"
                                                className="profile-page__input-modal"
                                                value={editingUser.last_name}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CFormLabel htmlFor="first_name"
                                            className="col-sm-4 col-form-label">{t('profile.first_name')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="first_name"
                                                name="first_name"
                                                type="text"
                                                className="profile-page__input-modal"
                                                value={editingUser.first_name}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CFormLabel htmlFor="patronymic"
                                            className="col-sm-4 col-form-label">{t('profile.patronymic')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="patronymic"
                                                name="patronymic"
                                                type="text"
                                                className="profile-page__input-modal"
                                                value={editingUser.patronymic}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CFormLabel htmlFor="email"
                                            className="col-sm-4 col-form-label">{t('profile.email')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="email"
                                                name="email"
                                                type="text"
                                                className="profile-page__input-modal"
                                                value={editingUser.email}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CFormLabel htmlFor="phone_number"
                                            className="col-sm-4 col-form-label">{t('profile.phone')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="phone_number"
                                                name="phone_number"
                                                type="phone"
                                                className="profile-page__input-modal"
                                                value={editingUser.phone_number}
                                                onChange={onChangePhoneHandler}
                                                maxLength={12}
                                                placeholder="996XXXXXXXXX"
                                    />
                                </CCol>
                            </CRow>
                        </div>
                    </FormGroup>
                </CForm>
            </DefaultModal>
        </>
    )
}

export default Profile;
