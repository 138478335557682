import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const mainPagesApi = createApi({
    reducerPath: 'mainPage/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        // prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['mainPage'],
    endpoints: build => ({
        mainPage: build.query<any, string | void>({
            query: (query) => {
                return {
                    url: `/main-page/${query || ''}`
                }
            },
            providesTags: ['mainPage'],
        }),
    })
});

export const {
    useLazyMainPageQuery
} = mainPagesApi;
