import React, {useEffect, useMemo, useState} from 'react';
import {
    CButton,
    CButtonGroup,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";

import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import {useTranslation} from "react-i18next";
import {
    useCreateGeoCategoryMutation,
    useLazyGetGeoCategoriesQuery, useRemoveGeoCategoryMutation, useUpdateGeoCategoryMutation
} from "../../../store/geoCategories/geoCategories.api";
import makeQuery from "../../../functions/makeQuery";
import PaginationSearch from "../../../components/UI/Pagination/PaginationSearch";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

const GeoCategories = () => {
    const {t} = useTranslation();
    const [fetchGeoCategories, {isError: geoError, isLoading: geoCategoriesLoading, isFetching: geoCategoriesFetching, data: geoCategories}] = useLazyGetGeoCategoriesQuery();
    const [createGeoCategory, {isLoading: createGeoCategoryLoading}] = useCreateGeoCategoryMutation();
    const [deleteGeoCategory, {isLoading: deleteGeoCategoryLoading}] = useRemoveGeoCategoryMutation();
    const [updateGeoCategory, {isLoading: updateGeoCategoryLoading}] = useUpdateGeoCategoryMutation();

    const [pagination, setPagination] = useState({page_size: '100', search: '', page: 1});
    const [filter, setFilter] = useState({sort_by: '', direction: 'asc'});
    const [visible, setVisible] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [expGeoObj, setExpGeoObj] = useState<{ [key: number]: boolean }>({});
    const [tree, setTree] = useState<{ [key: number]: boolean }>({});
    const [geoCategory, setGeoCategory]: any = useState({});
    const [parent, setParent]: any = useState({id: ''});
    const [error, setError]: any = useState(null);
    const [randomColor, setColor]: any = useState([]);
    const [searchString, setSearchString] = useState('');
    const [okIsHover, setOkIsHover] = useState<boolean>(false);

    const isLoading = useMemo(() => {
        return (geoCategoriesLoading || geoCategoriesFetching || createGeoCategoryLoading || deleteGeoCategoryLoading || updateGeoCategoryLoading);
    }, [geoCategoriesLoading, geoCategoriesFetching, createGeoCategoryLoading, deleteGeoCategoryLoading, updateGeoCategoryLoading]);

    let parentId: number | string;
    const colors = [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'dark',
        'light'
    ];
    let oneColor: string;


    useEffect(() => {
        fetchGeoCategories('');
    }, [])

    useEffect(() => {
        if (geoCategories?.length) {
            setGeoCategory({
                category_name: geoCategories?.category_name,
                description: geoCategories?.description,
            })
        }
    }, [geoCategories]);

    useEffect(() => {
        if (geoCategories?.length) {
            setGeoCategory((prev: any) => ({...prev, parent: parent.id}))
        }
    }, [parent]);

    useEffect(() => {
        getCurrentParentModal(geoCategories)
    }, [expGeoObj]);

    useEffect(() => {
        if (geoCategories) setSortedGeoCategories(geoCategories);
    }, [geoCategories])

    const [sortedGeoCategories, setSortedGeoCategories] = useState([]);
    const [sortOrder, setSortOrder] = useState<String>('asc');

    const sortGeoCategories = () => {
        const sorted = sortedGeoCategories && [...sortedGeoCategories].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a > b ? 1 : -1;
            } else {
                return a < b ? 1 : -1;
            }
        });
        console.log(sorted);
        setSortedGeoCategories(sorted);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleToggleModal = (visible: boolean) => {
        setTimeout(() => {
            if (visible === false) {
                setGeoCategory({
                    category_name: '',
                    description: '',
                    parent: ''
                });
                setSelectedId(null);
            }
        }, 200)
        setVisible(visible);
        setParent({id: ''})
    }

    const handleToggleDeleteModal = (visible: boolean) => {
        if (!visible) setSelectedId(null);
        setDeleteModal(visible)
    }

    const handleEdit = async () => {
        const data: { [key: string]: string | number } = {
            description: geoCategory.description || '',
            parent: geoCategory.parent || null
        };
        if (geoCategory.category_name) data.category_name = geoCategory.category_name;
        else return null;

        const formData: any = new FormData();

        Object.keys(data).forEach(key => {
            formData.append(key, geoCategory[key])
        });

        if (selectedId) {
            updateGeoCategory({id: selectedId, body: formData});
        } else {
            createGeoCategory(formData);
        }
        handleToggleModal(false);
    }

    const handleChange = (event: any) => {
        const state: any = {...geoCategory};
        state[event.target.name] = event.target.value;
        setGeoCategory(state);
    };

    const handleGetId = (id: number | string) => {
        setGeoCategory((prev: any) => ({...prev, parent: id || null}));
    };

    const toggleEditModalHandler = (visible?: boolean, data?: any) => {
        let parentId = '';
        const getParentId = (geoCategories: any) => {
            geoCategories?.forEach((g: any) => {
                if (g.children.length) {
                    g.children?.forEach((child: any) => {
                        if (data.id === child.id) {
                            parentId = g.id
                        }
                    })
                }
                getParentId(g.children)
            })
        }
        getParentId(geoCategories)

        if (data && data.id) {
            setSelectedId(data.id);
            setGeoCategory({category_name: data.category_name, description: data.description, parent: parentId});
        } else {
            setTimeout(() => {
                setSelectedId(null);
                setGeoCategory({category_name: '', description: '', parent: ''});
            }, 200);
        }
        setError(null);
        setVisible(!!visible);
        setTree(expGeoObj);
    };

    const handleOpenDeleteModal = (id: any) => {
        setDeleteModal(true);
        setSelectedId(id);
    }

    const handleDelete = () => {
        deleteGeoCategory(Number(selectedId)).then(
            (res: any) => {
                if (res.error) setError(res.error.data?.error || 'Удаление невозможно');
                else setDeleteModal(false);
            }
        )
    }

    const onPaginationHandler = (state: any) => {
        const innerState = {...pagination, ...state};
        setPagination(innerState);
        onSearchDataHandler({...innerState, ...filter});
    };

    const onFilterHandler = (field: string) => {
        const state = {...filter};
        if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
        else {
            state.sort_by = field;
            state.direction = 'asc';
        }
        setFilter(state);
        onSearchDataHandler({...pagination, ...state});
    };

    const onSearchCallbackHandler = (search: string) => {
        // onSearchDataHandler({...pagination, ...filter, search});
    };

    const onSearchDataHandler = (data: any) => {
        const query: any = {};
        if (data.page_size) query.page_size = data.page_size;
        if (data.page) query.page = data.page;
        if (data.search && data.search !== '') query.search = data.search;
        if (data.sort_by) query.sort_by = data.sort_by;
        if (data.direction === 'desc') query.direction = 'desc';

        fetchGeoCategories(makeQuery.stringify(query));
    }

    const handleToggleCollapse = (event: any, id: number) => {
        event.preventDefault();
        setExpGeoObj(prev => ({...prev, [id]: !prev[id]}));
    }

    const handleToggleTree = (id: number) => {
        setTree(prev => ({...prev, [id]: !prev[id]}));
    }

    const getCurrentParentModal = (geoCategories: any) => {
        geoCategories?.forEach((g: any) => {
            Object.values(expGeoObj).forEach(v => {

                Object.keys(expGeoObj).forEach(key => {
                    if (Number(g.id) === Number(key) && g.children.length) {
                        return handleToggleTree(g.id);
                    } else {
                        return false
                    }
                });
            })
            if (g.children.length) {
                getCurrentParentModal(g.children);
            }
        })
    }

    const Tree = (data: any, level: number = 0) => {
        const result: any[] = [];
        data && data.forEach((i: any, n: any) => {
            if (i.id !== selectedId) {
                result.push(
                    <div className='tree-wrapper category-tree-wrapper' key={i.id + 'category' + n}>
                        <div className="tree category-tree"
                             style={{paddingLeft: `${5 * level}px`}}
                        >
                            <div style={{width: '12px'}}>
                                {
                                    (i.children.length && !i.children.map((el: any) => el.id).includes(selectedId)) ?
                                        <i
                                            className={`fa caret-icon fa-caret-${tree[i.id] ? 'down' : 'right'}`}
                                            aria-hidden="true"
                                            onClick={() => handleToggleTree(i.id)}
                                        />
                                        : null
                                }
                            </div>
                            <CFormCheck
                                className='tree-radio'
                                type="radio"
                                name="tree-radio"
                                id="tree-radio"
                                value={i.id}
                                checked={geoCategory.parent === i.id}
                                onChange={() => handleGetId(i.id)}
                            />
                            <p>{i.category_name}</p>
                        </div>
                    </div>
                )
                if (i.children && tree[i.id]) {
                    result.push(
                        Tree(i.children, level + 3)
                    )
                }
            }
        })
        return result;
    }
    // const getRandomColor = (colors: any, id: any) => {
    //     const newObjColor: any = {
    //         id: id,
    //         color: colors[Math.floor(Math.random() * colors.length - 1)]
    //     }
    //     setColor([...randomColor, newObjColor])
    // }

    const renderTableRow = (geoCategories: any, className?: string, level: number = 0) => {
        const result: any[] = [];

        geoCategories?.forEach((el: any) => {
            if (!searchString || el.category_name.toLowerCase().includes(searchString.toLowerCase()) || el.description.toLowerCase().includes(searchString.toLowerCase())) {
                result.push(
                    <CTableRow
                        key={el.id}
                        className={className}
                        color={className ? 'info' : ''}
                    >
                        {
                            el.children.length ?
                                <CTableDataCell
                                    // style={{paddingLeft: level ? `${level * 5}px` : '8px', fontSize: '1.2rem'}}
                                    style={{textAlign: 'center', fontSize: '1.2rem'}}
                                >
                                    {/*<i className="fa fa-plus" aria-hidden="true"*/}
                                    {/*   onClick={(e) => handleToggleCollapse(e, el.id)}*/}
                                    {/*/>*/}
                                    <i
                                        // className={`fa  fa-chevron-${tree[i.id] ? 'up' : 'down'}`}
                                        className={`fa caret-icon fa-caret-${expGeoObj[el.id] ? 'down' : 'right'}`}
                                        style={{marginLeft: '10px', marginRight: '10px'}}
                                        aria-hidden="true"
                                        onClick={(e) => handleToggleCollapse(e, el.id)}
                                    />
                                </CTableDataCell>
                                :
                                <CTableDataCell>
                                </CTableDataCell>
                        }
                        <CTableDataCell
                            style={{paddingLeft: level ? `${level * 5}px` : '8px',}}
                        >
                            {el.category_name}
                        </CTableDataCell>
                        <CTableDataCell>
                            {el.description}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                            <CButtonGroup size="sm">
                                <CButton size="sm" color="info" className="default-table__action-btn"
                                         onClick={() => toggleEditModalHandler(true, el)}>
                                    <i className="fa fa-pencil-square-o color-white"/>
                                </CButton>
                                <CButton size="sm" color="danger" className="default-table__action-btn"
                                         onClick={() => handleOpenDeleteModal(el.id)}>
                                    <i className="fa fa-times color-white" aria-hidden="true"> </i>
                                </CButton>
                            </CButtonGroup>
                        </CTableDataCell>
                    </CTableRow>
                )
            }
            if (el.children && expGeoObj[el.id]) {
                result.push(
                    renderTableRow(el.children, `tree-child`, level + 5)
                )
            }
        })
        return result
    }

    const okHoverHandler = (isHover: boolean = false) => {
        setOkIsHover(isHover);
    };

    return (
        <>
            <div className="pb-3 mb-4 title-divider">
                <h1>{t('gis.geoCategories.geoCategories')}</h1>
                <LoaderLine visible={isLoading}/>
            </div>

            <div className="d-flex justify-content-md-end mb-3">
                <CButton size="sm" color="info" className="color-white"
                         onClick={() => handleToggleModal(true)}>
                    {t('gis.geoCategories.addGeoCategory')}
                </CButton>
            </div>

            <PaginationSearch name={'geo-search'}
                              value={pagination.search || ''}
                // onChange={(value: string) => onPaginationHandler({
                //     name: 'search',
                //     value: value
                // })}
                              onChange={(value: string) => setSearchString(value)}
                // onSearchDelay={10}
                // onSearchCallback={onSearchCallbackHandler}
            />
            <CTable bordered striped className="default-table">
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell style={{width: '2%'}} scope="col"
                                          className="default-table__actions">

                        </CTableHeaderCell>
                        <CTableHeaderCell style={{width: '50%'}}
                                          scope="col"
                                          className="sorted-table-cell"
                            // onClick={() => onFilterHandler('category_name')}
                                          onClick={() => sortGeoCategories()}
                        >
                            <div className="d-flex justify-content-between">
                                <span>{t('gis.typesOfGeoObjects.title')}</span>
                                {sortOrder === 'asc' ?
                                    <i className={`fa fa-sort-amount-asc`} aria-hidden="true"/> :
                                    <i className="fa fa-sort-amount-desc" aria-hidden="true"/>
                                }
                            </div>
                        </CTableHeaderCell>
                        <CTableHeaderCell style={{width: '20%'}} scope="col"
                                          className="default-table__actions">
                            {t('geoCategories.description')}
                        </CTableHeaderCell>
                        <CTableHeaderCell style={{width: '13%'}} scope="col"
                                          className="default-table__actions">
                            {t('actions')}
                        </CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {renderTableRow(sortedGeoCategories)}
                </CTableBody>
            </CTable>

            <DefaultModal visible={visible}
                          setVisible={handleToggleModal}
                          title={selectedId ? t('gis.geoCategories.editGeoCategory') : t('gis.geoCategories.addGeoCategory')}
                          type="info"
                          cancelButton={t('cancel')}
                          onOk={handleEdit}
                          onOkHover={okHoverHandler}
                          size="lg"
                          okDisabled={!(geoCategory.category_name)}
            >
                <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
                    <CFormLabel
                        className="regions-label main-label regions-modal-input">{t('gis.geoCategories.geoCategory')}</CFormLabel>
                    <CFormInput type="text"
                                id='category_name'
                                value={geoCategory?.category_name || ''}
                                name="category_name"
                                onChange={handleChange}
                                size="sm"
                                className={`${(okIsHover && !geoCategory?.category_name) ? 'form-control--alert' : ''}`}
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
                    <CFormLabel
                        className="regions-label main-label regions-modal-input">{t('gis.geoCategories.description')}</CFormLabel>
                    <CFormInput type="text"
                                id='description'
                                value={geoCategory?.description || ''}
                                name="description"
                                onChange={handleChange}
                                size="sm"
                    />
                </div>

                <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                    <p
                        className={`title-tree main-label`}
                    >
                        Родитель
                        <span
                            style={{
                            marginLeft: '10px',
                            color: '#e55353',
                            cursor: 'pointer',
                            fontSize: '16px',
                            height: '20px'
                        }}
                              onClick={() => handleGetId('')}
                        >
                                {geoCategory.parent &&
                                    <i
                                    className='fa fa-close'
                                    aria-hidden="true"
                                    />
                                }
                            </span>
                    </p>
                    <div className="tree-block">
                        {Tree(sortedGeoCategories)}
                    </div>
                </div>

            </DefaultModal>

            <DefaultModal visible={deleteModal}
                          setVisible={handleToggleDeleteModal}
                          title={t('deletion')}
                          type="danger"
                          cancelButton={t('cancel')}
                          onOk={handleDelete}
                          error={error}
                          size="lg"
            >
                <div>
                    <p className="mb-0 fs-6">{t('gis.geoCategories.categoryDeletion')}</p>
                </div>
            </DefaultModal>

        </>
    );
};

export default GeoCategories;
