import React, {useRef, useState} from 'react';
import {CButton, CFormInput, CFormLabel} from "@coreui/react";
import {useTranslation} from "react-i18next";
import {useCreateGeoTypeMutation} from "../../../store/geoTypes/geoTypes.api";

const ObjectsImport = () => {
    const {t} = useTranslation();
    const [createGeoType] = useCreateGeoTypeMutation();
    const fileInputRef = useRef(null);

    const [state, setState] = useState({
        category: "",
        geo_type_field: [],
        icon: "",
        type_name: "",
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setState((prev: any) => ({
            ...prev,
            type_name: e.target.value
        }));
    };


    const handleChangeFiles = (e: any) => {
        try {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const contents = e.target.result;
                const parsedData = JSON.parse(contents);

                if(parsedData.category && parsedData.geo_type_field && parsedData.icon) {
                        setState((prev: any) => ({
                            ...prev,
                            category: parsedData?.category,
                            geo_type_field: parsedData?.geo_type_field?.map((field: any, index: number) => {
                                // Проверяем, есть ли соответствующие данные в parsedData
                                const updatedField = parsedData?.geo_type_field?.find((parsedField: any) => parsedField?.field_name === field?.field_name);
                                if (updatedField) {
                                    // Если данные найдены, возвращаем обновленный объект поля
                                    return {
                                        field_name: updatedField?.field_name,
                                        field_type: updatedField?.field_type
                                    };
                                }
                                // Если данные не найдены, возвращаем исходный объект поля
                                return field;
                            }),
                            icon: parsedData?.icon,
                        }));
                }
        };
            try {
                reader.readAsText(file);

            } catch (e) {
                console.log(e);
            }

        } catch (e) {
            console.log(e);
        }
    };

    const uploadType = async (e: any) => {

        e.preventDefault();
        if (state && state.type_name) {
            await createGeoType(state);

            if (fileInputRef.current) {
                //@ts-ignore
                fileInputRef.current.value = ""; // Clear the file input
            }

            setState((prev: any) => ({
                category: "",
                geo_type_field: [],
                icon: "",
                type_name: "",
            }));
        }
    };

    return (
        <>
            <p className="import-export-title">{t('nav.Import')}</p>
            <div className="mb-3">
                <CFormLabel className="main-label">{t('nav.typeName')}</CFormLabel>
                <CFormInput
                    type="text"
                    name={'material_damage'}
                    value={state?.type_name || ''}
                    onChange={handleChange}
                    className={'emergency-custom-input'}
                    min={0}
                    required
                />
            </div>
            <div className="mb-3">
                <CFormLabel className="main-label">{t('nav.typeFile')}</CFormLabel>
                <CFormInput
                    type="file"
                    name={'material_damage'}
                    onChange={handleChangeFiles}
                    className={'emergency-custom-input mb-3'}
                    multiple
                    ref={fileInputRef} // Add ref to the file input
                />
            </div>
            <CButton type="submit"
                     onClick={e => uploadType(e)}
                     className="mb-5"
                     disabled={!(state.category && state.type_name)}
            >
                {t('nav.typeBtnSend')}
            </CButton>
        </>
    );
};

export default ObjectsImport;
