import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";


export const emergencyChatApi = createApi({
    reducerPath: 'emergencyChat/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['emergencyChat'],
    endpoints: build => ({
        getEmergencyListChat: build.query<any, string | void>({
            query: (query) => {
                return {
                    url: `/comments/emergency/list/${query || ''}`
                }
            },
            providesTags: ['emergencyChat']
        }),
        getEmergencyChatById: build.query<any, string | void>({
            query: id => {
                return {
                    url: `/comments/emergency/list/${id}/`
                }
            },
            providesTags: ['emergencyChat']
        }),
        createEmergencyMessage: build.mutation<any, any>({
            query: (body) => ({
                url: '/create/comment/emergency/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['emergencyChat'],
        }),
    })
})

export const {
    useLazyGetEmergencyChatByIdQuery,
    useLazyGetEmergencyListChatQuery,
    useCreateEmergencyMessageMutation
} = emergencyChatApi