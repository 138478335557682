import React from 'react';
import {
  FeatureGroup,
  LayersControl,
  MapContainer,
  ScaleControl,
  TileLayer,
  useMapEvents,
  ZoomControl
} from 'react-leaflet';
import L from "leaflet";
import Control from 'react-leaflet-custom-control';
import { EditControl } from "react-leaflet-draw";
import 'leaflet/dist/leaflet.css';
import "leaflet-draw/dist/leaflet.draw.css";

import MapLoader from "../preloaders/MapLoader/MapLoader";
import {getTiles} from "./tiles";

interface BaseMapProps {
  filterControl?: boolean;
  drawControl?: boolean;
  layersControl?: boolean;
  zoomControl?: boolean;
  crosshairsControl?: boolean;
  adjustControl?: boolean;
  scaleControl?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  handlerZoom: (zoom: number) => void;
  getPosition?: (event: any) => void;
  center?: number[]
  zoom?: number
}

const MyComponent: React.FC<{ handlerZoom: (zoom: number) => void, getPosition?: (e: any) => void }> = ({handlerZoom, getPosition}) => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      handlerZoom(mapEvents.getZoom());
    },
    click: (e) => {
      // @ts-ignore
      if (getPosition) {
        getPosition(e.latlng)
      }
    }
  });
  return null;
};

// @ts-ignore
const BaseMap: React.FC<BaseMapProps> = (props) => {
  const _onDrawStart = (event: any) => {
    console.log(event);
  };

  const _onEdited = (event: any) => {
    console.log(event);
  };

  const _onCreated = (event: any) => {
    console.log(event);
  };

  const _onDeleted = (event: any) => {
    console.log(event);
  };

  return (
    // @ts-ignore
    <MapContainer center={(props.center[0] && props.center[1]) ? props.center : [41.1262532, 73.79516602]}
      zoom={props.zoom || 7}
      zoomControl={false}
      attributionControl={false}
      style={{height: '100%'}}
    >
      {props.filterControl ?
        <Control position="topleft" >
          <a className="leaflet-control-filter"
             href="/"
             title="Category filter"
             role="button"
             aria-label="Category filter"
             aria-disabled="false"
             onClick={e => e.preventDefault()}
          >
            <i className="fa fa-filter" aria-hidden="true"/>
          </a>
        </Control>
        : null}

      {props.drawControl ?
        <FeatureGroup>
          <EditControl position="topleft"
                       onDrawStart={_onDrawStart}
                       onEdited={_onEdited}
                       onCreated={_onCreated}
                       onDeleted={_onDeleted}
                       draw={{
                         polyline: {
                           icon: new L.DivIcon({
                             iconSize: new L.Point(8, 8),
                             className: "leaflet-div-icon leaflet-editing-icon"
                           }),
                           shapeOptions: {
                             guidelineDistance: 10,
                             color: "navy",
                             weight: 3
                           }
                         },
                         circlemarker: false
                       }}
          />
        </FeatureGroup>
        : null}

      {props.layersControl ?
          // @ts-ignore
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
            <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="OpenStreetMap">
            <TileLayer url={getTiles('OpenStreetMap')}/>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Google.Hybrid">
            <TileLayer url={getTiles('Google.Hybrid')}/>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri.WorldStreetMap">
            <TileLayer url={getTiles('Esri.WorldStreetMap')}/>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri.WorldGrayCanvas">
            <TileLayer url={getTiles('Esri.WorldGrayCanvas')}/>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri.WorldImagery">
            <TileLayer url={getTiles('Esri.WorldImagery')}/>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri.WorldShadedRelief">
            <TileLayer url={getTiles('Esri.WorldShadedRelief')}/>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Stamen.Terrain">
            <TileLayer url={getTiles('Stamen.Terrain')}/>
          </LayersControl.BaseLayer>
        </LayersControl>
        :
        <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
      }

      {props.zoomControl ? <ZoomControl position="topright"/> : null}

      {props.crosshairsControl ?
        <Control position="topright" >
          <a className="leaflet-control-crosshairs"
             href="/"
             title="Crosshairs"
             role="button"
             aria-label="Crosshairs"
             aria-disabled="false"
             onClick={e => e.preventDefault()}
          >
            <i className="fa fa-crosshairs" aria-hidden="true"/>
          </a>
        </Control>
        : null}

      {props.adjustControl ?
        <Control position="topright" >
          <a className="leaflet-control-layers-satellite"
             href="/"
             title="Layers satellite"
             role="button"
             aria-label="Layers satellite"
             aria-disabled="false"
             onClick={e => e.preventDefault()}
          >
            <i className="fa fa-adjust" aria-hidden="true"/>
          </a>
        </Control>
        : null}

      {props.scaleControl ? <ScaleControl position="bottomleft"/> : null}

      {props.loading ? <MapLoader/> : null}
      {props.children}
      {/*@ts-ignore*/}
      <MyComponent handlerZoom={props.handlerZoom} getPosition={props.getPosition}/>
    </MapContainer>
  );
};

export default BaseMap;
