import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import {CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler} from '@coreui/react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { AppSidebarNav } from './AppSidebarNav';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {useGetInfoUserQuery, useLazyGetInfoUserQuery} from "../store/account/account.api";
import ProfileComponent from "./ProfileComponent";
// import logo from '../assets/images/herb-cmc.png';

interface AppSidebarProps {
  sidebarMenu: any;
  sidebarIsCollapsed: any;
  sidebarCollapseHandler: any;
}
const AppSidebar: React.FC<AppSidebarProps> = ({sidebarMenu, sidebarIsCollapsed, sidebarCollapseHandler}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [unfoldable, setUnfoldable] = useState(false);
  const {sidebarShow} = useAppSelector((state) => state.toggleBtnApp);
  // const {data: me} = useGetInfoUserQuery();
  // const [getMe, {isError: errorGetMe,  isLoading: loadingGetMe, data: me}] = useLazyGetInfoUserQuery();
  //
  // useEffect(() => {
  //   getMe();
  // }, []);

  return (
      <CSidebar
          position="fixed"
          unfoldable={unfoldable}
          visible={sidebarShow}
          onVisibleChange={(visible) => {
            dispatch({ type: 'set', sidebarShow: visible })
          }}
          onMouseOver={() => sidebarCollapseHandler(false)}
          onMouseLeave={() => sidebarCollapseHandler(unfoldable && true)}
      >
        {/*@ts-ignore*/}
        <CSidebarBrand className="d-none d-md-flex" style={{cursor: 'pointer'}} to="/" onClick={() => navigate('/')}>
          <div className="d-flex align-items-center justify-content-center">
            {(!unfoldable || !sidebarIsCollapsed) &&
                <span style={{fontSize: '24px'}}>
              {t("IAMS")} ({t("MES")})
              <sub style={{fontSize: '12px', marginLeft: '5px'}}>v.1.0.0</sub>
            </span>
            }
          </div>
        </CSidebarBrand>
        <ProfileComponent/>
        <CSidebarNav>
          <SimpleBar>
            <AppSidebarNav items={sidebarMenu} />
          </SimpleBar>
        </CSidebarNav>
        {/*<CSidebarToggler*/}
        {/*    className="d-none d-lg-flex"*/}
        {/*    onClick={() => setUnfoldable(prev => !prev)}*/}
        {/*/>*/}
      </CSidebar>
  )
}

export default React.memo(AppSidebar)
