import '../../../../scss/_comments.scss';
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import React from "react";
import config from "../../../../config";

interface Props {
    extension: string[],
    icon: string[],
    item: any,
    getDataWithQueryHandler: (item: any) => void,
}

const FileComponents = ({ extension, icon, item, getDataWithQueryHandler }:Props) => {
    let decodedName;
    const itemName = item.file.split('/')[item.file.split('/').length - 1];
    if(!itemName.split('.').includes('svg') ||
        !itemName.split('.').includes('jpeg') ||
        !itemName.split('.').includes('jpg') ||
        !itemName.split('.').includes('png') ||
        !itemName.split('.').includes('bmp') ||
        !itemName.split('.').includes('tiff') ||
        !itemName.split('.').includes('heif') ||
        !itemName.split('.').includes('raw') ||
        !itemName.split('.').includes('webp')
    ) decodedName = decodeURIComponent(itemName);

    return (
        <div  className="image-wrapper" >
            <div onClick={() => getDataWithQueryHandler(item.file)} style={{cursor: 'pointer'}}>
                {!extension.includes(item.file.split('.')[item.file.split('.').length - 1]) ?
                    <div className="file-item">
                        <CIcon icon={icon} size="xxl"
                               style={{cursor: 'pointer', width: '170px'}}/>
                        <p className="file-item_name" style={{borderRadius: '5px'}}>
                            {decodedName}
                        </p>
                    </div> :
                    <img alt={""}
                         src={config.baseUrl + item.file}
                         // src={`https://iamsui.yorc.org${item.file}`}
                         style={{height: '110px', width: '170px'}}
                    />
                }
            </div>
        </div>
    )
}

export default FileComponents;