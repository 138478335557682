// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-picker {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 0.375rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
}

.time-picker__select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-picker__divider {
  padding-top: 0.325rem;
}

.time-picker select {
  min-width: 60px;
  font-size: 0.75rem;
  padding: 0.325rem 0.5rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  background-color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/TimePicker/TimePicker.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,wBAAwB;EACxB,gBAAgB;EAChB,gBAAgB;EAChB,sBAAsB;EACtB,yBAAyB;AAC3B","sourcesContent":[".time-picker {\n  display: flex;\n  flex-wrap: nowrap;\n  column-gap: 0.375rem;\n  font-size: 0.75rem;\n  font-weight: 400;\n  line-height: 1.5;\n}\n\n.time-picker__select-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.time-picker__divider {\n  padding-top: 0.325rem;\n}\n\n.time-picker select {\n  min-width: 60px;\n  font-size: 0.75rem;\n  padding: 0.325rem 0.5rem;\n  font-weight: 400;\n  line-height: 1.5;\n  border-radius: 0.25rem;\n  background-color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
