import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

function defineStreamType (src) {
  const ext = src.split('.').reverse()[0];
  switch (ext) {
    case 'webm': return 'video/webm';
    case 'ogg': return 'video/ogg';
    case 'ogv': return 'video/ogg';
    case 'mp4': return 'video/mp4';
    case 'mov': return 'video/mp4';
    case 'avi': return 'video/x-msvideo';
    case '3gp': return 'video/3gpp';
    case 'm3u8': return 'application/x-mpegURL';
    default: // do nothing
  }
  if (src.indexOf('://youtu') > 0) return 'youtube';
  if (src.indexOf('://www.youtu') > 0) return 'youtube';
  if (src.indexOf('://flowplayer') > 0) return 'other';
  if (src.indexOf('://webcam.elcat.kg') > 0 && src.includes('/embed/')) return 'other';
  return 'text/html';
}

function InitHLS (id, src, autoplay) {
  const video = document.getElementById(id);
  if (!video) return null;
  const Hls = window.Hls;
  if (Hls && Hls.isSupported()) {
    const hls = new Hls({
      // "debug": true,
      "enableWorker": true,
      "lowLatencyMode": true,
      "backBufferLength": 30
    });
    hls.loadSource(src);
    hls.attachMedia(video);
    hls.on(Hls.Events.INIT_PTS_FOUND,function() {
      // console.log('INIT_PTS_FOUND');
      if (autoplay) video?.play();
    });
    // hls.on(Hls.Events.MANIFEST_PARSED,function() {
      // console.log('MANIFEST_PARSED');
      // if (autoplay) video?.play();
    // });
  }
  else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = src;
    video.addEventListener('canplay',function() {
      if (autoplay) video?.play();
      // video.pause();
      // if (autoplay) {
      //   setTimeout(() => {
      //     video.play();
      //   }, 2000);
      // }
    });
  }
}

function DefaultPlayer (props) {
  let type = props.type;
  if (!type) type = defineStreamType(props.source);

  useEffect(() => {
    if ((type === 'application/x-mpegURL' || type === 'application/mpegURL') && props.id && props.source) {
      InitHLS(props.id, props.source, props.autoPlay);
    }
  }, []);

  // useEffect(() => {
  //   if ((type === 'application/x-mpegURL' || type === 'application/mpegURL') && props.id && props.source) InitHLS(props.id, props.source, props.autoPlay);
  // }, [type, props.id, props.source, props.autoPlay]);

  const onClick = event => {
    event.preventDefault();
  };

  if (type === 'youtube') {
    return (
      <div className="default-player" style={{width: props.width || '100%', height: props.height || '100%', background: props.source ? 'black' : 'grey'}}>
        {props.source ?
          <>
            <iframe id={props.id}
                    width='100%'
                    height='100%'
              // src={props.source + '?mute=1&disablekb=1' + (props.autoPlay ? '&autoplay=1' : '') + '&loop=1'}
              // src={props.source + '?mute=1&disablekb=1&loop=1&playlist=' + props.source.split('/').reverse()[0] + (props.autoPlay ? '&autoplay=1' : '')}
                    src={props.source + '?mute=1&disablekb=1&loop=1' + (props.autoPlay ? '&autoplay=1' : '')}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    onClick={onClick}
                    title={'player' + props.id}
            />
            {props.allowManagement ? null :
              <div id={'overlay' + props.id}
                   style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: 'transparent'}}
                   onMouseDown={e => props.onMouseDown(e)}
                   onMouseUp={props.onMouseUp}
                   onMouseMove={props.onMouseMove}
              />
            }
          </>
          : null}
      </div>
    );
  }

  if (type === 'other') {
    return (
      <div className="default-player" style={{width: props.width || '100%', height: props.height || '100%', background: props.source ? 'black' : 'grey'}}>
        {props.source ?
          <>
            <iframe id={props.id}
                    width='100%'
                    height='100%'
                    src={props.source}
                    // src={props.source + '?mute=1&disablekb=1&loop=1' + (props.autoPlay ? '&autoplay=1' : '')}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    onClick={onClick}
                    title={'player' + props.id}
            />
            {props.allowManagement ? null :
              <div id={'overlay' + props.id}
                   style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: 'transparent'}}
                   onMouseDown={e => props.onMouseDown(e)}
                   onMouseUp={props.onMouseUp}
                   onMouseMove={props.onMouseMove}
              />
            }
          </>
          : null}
      </div>
    );
  }

  if (type === 'application/x-mpegURL' || type === 'application/mpegURL') {
    return (
      <div className="default-player" style={{width: props.width || '100%', height: props.height || '100%', background: props.source ? 'black' : 'grey'}}>
        {props.source ?
          <video id={props.id}
                 controls={!!props.controls}
                 preload={props.preload || 'none'}
                 width='100%'
                 height='100%'
                 muted='muted'
                 loop='loop'
                 onClick={onClick}
          />
        : null}
      </div>
    );
  }

  return (
    <div className="default-player" style={{width: props.width || '100%', height: props.height || '100%', background: props.source ? 'black' : 'grey'}}>
      {props.source ?
        <video id={props.id}
               controls={!!props.controls}
               autoPlay={!!props.autoPlay}
               preload={props.preload || 'metadata'}
               width='100%'
               height='100%'
               muted='muted'
               loop='loop'
               onClick={onClick}
        >
          <source src={props.source}
                  type={type}
          />
        </video>
        : null}
    </div>
  );
}

DefaultPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  preload: PropTypes.string,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  allowManagement: PropTypes.bool
};

export default DefaultPlayer;
