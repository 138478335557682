import {useTranslation} from "react-i18next";

interface SelectFilterProps {
    options: any[],
    onclick: () => void,
    visible: boolean,
    onMouse: (event: boolean) => void
}
const SelectForFilter = ({options, onclick, visible, onMouse}:SelectFilterProps) => {
    const {t} = useTranslation();
    return (
        <>
          <div
            onClick={onclick}
            style={{
              padding: '0.5rem',
              marginRight: '1rem',
              border: '1px solid #d8dbe0',
              borderRadius: '0.375rem',
              background: '#fff',
              width: '12rem',
              cursor: 'default',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontSize: '1rem',
              color: 'rgba(44, 56, 74, 0.95)',
              userSelect: 'none'
            }}
          >
            {t('assignmentsPage.fast_filters')}
            <img
              src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23636f83%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e"
              alt="" width={13} height={13}
            />

            <div onMouseLeave={() => onMouse(false)}
                 style={{
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '20px',
                   padding: '10px',
                   background: visible ? '#fff' : 'transparent',
                   border: visible ? '1px solid grey' : 'none',
                   boxShadow: visible ? '0px 0px 10px 3px rgba(0,0,0,0.1)' : 'none',
                   position: 'absolute',
                   top: '41px',
                   right: 0,
                   zIndex: 9
                 }}
                 onClick={(e) => e.stopPropagation()}
            >
              {visible && options.map((item: any, i: number) => (
                <div key={item.value + i}>
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        </>
    );
}


export default SelectForFilter;