import React, {FC, useEffect, useState} from 'react';
import {getParamsOfFileByUrl} from "../../../functions";
import "./GalleryModal.css";

interface IGalleryModalProps {
  show: boolean,
  toggle: (state: number | null) => void,
  data?: any[],
  startIndex?: number
}

type TFile = {
  name: string,
  url: string
};

const GalleryModal: FC<IGalleryModalProps> = ({show, data = [], toggle, startIndex}) => {
  const [current, setCurrent] = useState<number>(0);
  const [file, setFile] = useState<TFile>({name: '', url: ''});
  const [fileParams, setFileParams] = useState({name: '', ext: '', type: ''});
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  useEffect(() => {
    setCurrentHandler(startIndex || 0);
  }, [show, startIndex]);

  const setCurrentHandler = (number: number) => {
    const num = number > (data.length - 1) ? 0 : number < 0 ? (data.length - 1) : number;
    const file = data[num]?.file || '';
    setFile({name: file.split('/').reverse()[0] || '', url: file});
    setFileParams(getParamsOfFileByUrl(file));
    setCurrent(num);
  };

  const downloadHandler = () => {
    if (!file.url) return;
    setIsDownloading(true);
    fetch(file.url).then(response => response.blob()).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', file.name);
      link.click();
      window.URL.revokeObjectURL(url);
      setIsDownloading(false);
    }).catch(() => {
      setIsDownloading(false);
      alert('Не удалось скачать изображение.');
    });
  };

  if (!show) return null;

  return (
    <div className={`gallery-modal-wrapper${isDownloading ? ' gallery-modal-wrapper__downloading' : ''}`}>
      <div className="gallery-modal-header">
        <div className="gallery-modal-header__block">
          <span className="gallery-modal-header__file-name">{decodeURI(file.name)}</span>
        </div>
        <div className="gallery-modal-header__block">
          {isDownloading ?
            <i className="fa fa-spinner fa-spin" aria-hidden="true"/>
            :
            <i className="fa fa-download" aria-hidden="true" onClick={downloadHandler}/>
          }
          <i className="fa fa-times" aria-hidden="true" onClick={() => toggle(null)}/>
        </div>
      </div>
      <div className="gallery-modal-main">
        <div className="gallery-modal-main__arrow-wrapper gallery-modal-main__arrow-left-wrapper">
          <div className="gallery-modal-main__arrow-button" onClick={() => setCurrentHandler(current - 1)}>
            <i className="fa fa-chevron-left" aria-hidden="true"/>
          </div>
        </div>
        <div className="gallery-modal-main__content">
          <div className="gallery-modal-main__media-wrapper">
            {fileParams.type === 'image' ?
              <img src={file.url} alt=""/>
              : fileParams.type === 'video' ?
                <video controls>
                  <source src={file.url} type={`${fileParams.type}/${fileParams.ext}`}/>
                </video>
                : fileParams.type === 'audio' ?
                  <audio controls loop>
                    <source src={file.url} type={`${fileParams.type}/${fileParams.ext}`}/>
                  </audio>
                  : <div>Unknown format</div>
            }
          </div>
        </div>
        <div className="gallery-modal-main__arrow-wrapper gallery-modal-main__arrow-right-wrapper">
          <div className="gallery-modal-main__arrow-button" onClick={() => setCurrentHandler(current + 1)}>
            <i className="fa fa-chevron-right" aria-hidden="true"/>
          </div>
        </div>
      </div>
      <div className="gallery-modal-footer">
        <div className="gallery-modal-footer__dots-wrapper">
          {data?.map((el: any, idx: number) => (
            <i key={'dot' + idx} className={`fa fa-${idx === current ? 'dot-' : ''}circle-o`} aria-hidden="true"
               onClick={() => setCurrentHandler(idx)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;
