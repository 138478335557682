import React, {ChangeEvent, Fragment, useEffect, useMemo, useState} from 'react';
// import {CButton, CFormSelect} from '@coreui/react';
import {
  useChangeAssignmentStatusMutation, useLazyGetAssigmentsListQuery, useGetAssigmentsListQuery,
} from "../../../../store/assignments/assignments.api";
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { BoardSections as BoardSectionsType } from '../types';
import { findBoardSectionContainer } from '../utils/board';
// import { useNavigate } from 'react-router-dom';
import {getTaskById, IFilterSettings, sortingAndFilteringList} from '../utils/tasks';
import { useTranslation } from 'react-i18next';
import { useGetReadCurrentUserQuery } from '../../../../store/account/account.api';
import { IAssignmentsList } from '../../../../interfaces/IAssignments';
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
  DropAnimation,
  defaultDropAnimation,
} from '@dnd-kit/core';
import BoardSection from './BoardSection';
import TaskItem from './TaskItem';
import '../../../../scss/_drag_n_drop.scss';
import dayjs from "dayjs";
import PriorityComponent from "./PriorityComponent";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import CommentsComponent from "../../../../components/UI/CommentsUI/CommentsComponent/CommentsComponent";
import DefaultModal from "../../../../components/UI/Modals/DefaultModal";
// import SelectForFilter from "../../../../components/UI/SelectForFilter/SelectForFilter";
// import SelectForFilter from "../../../../components/UI/SelectForFilter/SelectForFilter";
import SortAndFilter from "../../../../components/UI/SortAndFilter/SortAndFilter";
import FileComponents from "../../../../components/UI/CommentsUI/FileComponents/FileComponents";
import config from "../../../../config";
import LoaderLine from "../../../../components/preloaders/LoaderLine/LoaderLine";

const BoardSectionList = () => {
  const {t} = useTranslation();
  const {data: tasks, isLoading: tasksLoading} = useGetAssigmentsListQuery();
  const {data: me, isLoading: meLoading} = useGetReadCurrentUserQuery();
  const [filter, setFilter] = useState(sessionStorage.getItem('filter'));
  const [container, setContainer] = useState('');
  const [changeAssignmentStatus] = useChangeAssignmentStatusMutation();
  const [activeTaskId, setActiveTaskId] = useState<null | string>(null);
  const [activeTask, setActiveTask] = useState<IAssignmentsList>();
  const [visible, setVisible] = useState(false);
  const extension = ['png', 'jpeg', 'jpg', 'gif', 'raw', 'tiff', 'bmp', 'psd'];
  const [executorsHidden, setExecutorsHidden] = useState(false);
  const [boardSections, setBoardSections] = useState<BoardSectionsType>({
    generated: [],
    in_work: [],
    done:[],
    completed: [],
  });
  const [filterSettings, setFilterSettings] = useState<IFilterSettings>({ sort_by: 'date', sort_direction: 'asc' });

  useEffect(() => {
    const newBoardSections: BoardSectionsType = {
      generated: [],
      in_work: [],
      done: [],
      completed: []
    };
    sortingAndFilteringList(tasks, filterSettings, me?.username).forEach(task => {
      if (newBoardSections[task.status]) newBoardSections[task.status].push(task);
      else newBoardSections[task.status] = [task];
    });

    setBoardSections(newBoardSections);
  }, [filterSettings, tasks, me]);

  const isLoading = useMemo(() => {
    return (tasksLoading || meLoading);
  }, [tasksLoading, meLoading]);

  const showTasksByIdInModalWindow = (id: number | string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const task = getTaskById(tasks! && tasks!, id);
    setActiveTask(task);
    setVisible(true);
  };

  const getDataWithQueryHandler  = (url: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', config.baseUrl + url);
    // element.setAttribute('href', 'https://iamsui.yorc.org' + url);
    element.setAttribute('target', '_blank');
    element.click();
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: {x: 50},
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveTaskId(active.id as string);
    setContainer(active?.data?.current?.sortable?.containerId);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const activeContainer = findBoardSectionContainer(
      boardSections,
      active?.id as string
    );

    const overContainer = findBoardSectionContainer(
      boardSections,
      over?.id as string
    );

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    if(boardSections[activeContainer].filter(p => p.id === active.id)[0].author.id === me.id) {
      setBoardSections((boardSection:any) => {
        const activeItems = boardSection[activeContainer];
        const overItems = boardSection[overContainer];
        const activeIndex = activeItems.findIndex(
          (item:any) => item?.id === active.id
        );
        const overIndex = overItems.findIndex((item:any) => item?.id !== over?.id);
          return {
            ...boardSection,
            [activeContainer]: [
              ...boardSection[activeContainer].filter(
                (item:any) => item?.id !== active.id
              ),
            ],
            [overContainer]: [
              ...boardSection[overContainer].slice(0, overIndex),
              boardSections[activeContainer][activeIndex],
              ...boardSection[overContainer].slice(
                overIndex,
                boardSection[overContainer].length
              ),
            ],
          };
      });
    }
    else if(boardSections[activeContainer].filter(p => p.id === active.id)[0].responsible_executor.id === me.id) {
      if(overContainer !== 'completed' && activeContainer !== 'completed') {
        setBoardSections((boardSection:any) => {
          const activeItems = boardSection[activeContainer];
          const overItems = boardSection[overContainer];
          const activeIndex = activeItems.findIndex(
            (item:any) => item?.id === active.id
          );
          const overIndex = overItems.findIndex((item:any) => item.id !== over?.id);
            return {
              ...boardSection,
              [activeContainer]: [
                ...boardSection[activeContainer].filter(
                  (item:any) => item?.id !== active.id
                ),
              ],
              [overContainer]: [
                ...boardSection[overContainer].slice(0, overIndex),
                boardSections[activeContainer][activeIndex],
                ...boardSection[overContainer].slice(
                  overIndex,
                  boardSection[overContainer].length
                ),
              ],
            };
        });
        // changeAssignmentStatus({id: active.id, status: overContainer});
      }
    }
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const activeContainer = findBoardSectionContainer(
      boardSections,
      active.id as string
    );
    const overContainer = findBoardSectionContainer(
      boardSections,
      over?.id as string
    );

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }
    const activeIndex = boardSections[activeContainer].findIndex(
      (task) => task?.id === active.id
    );
    const overIndex = boardSections[overContainer].findIndex(
      (task) => task?.id === over?.id
    );

    if (activeIndex !== overIndex) {

      // setBoardSections((boardSection) => ({
      //   ...boardSection,
      //   [overContainer]: arrayMove(
      //     boardSection[overContainer],
      //     activeIndex,
      //     overIndex
      //   ),
      // }));
    }
    boardSections[activeContainer].filter(p => p?.id === active.id)[0]?.author?.id === me.id
      ? activeContainer !== container && changeAssignmentStatus({id: active.id, status: activeContainer})
      : <></>
  };

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };

  // const navigateToPathHandler = (path:string) => {
  //   navigate(path);
  // };

  const task = activeTaskId ? tasks && getTaskById(tasks, activeTaskId) : null;
  return (
    <div className='board-block'>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('nav.Board')}</h1>
        <LoaderLine visible={isLoading}/>
        {/*<CFormSelect*/}
        {/*    style={{width: '180px'}}*/}
        {/*    value={selectValue}*/}
        {/*    options={selectOptions}*/}
        {/*    onChange={(e: ChangeEvent<HTMLSelectElement>) => onChangeSelectHandler(e)}*/}
        {/*/>*/}
        {/*<CButton*/}
        {/*    style={{background: 'white',*/}
        {/*            color: 'black',*/}
        {/*            border: `1px solid ${sortButton ? '#998fed' : '#ddd'}`,*/}
        {/*    }}*/}
        {/*    active={filter === 'all'}*/}
        {/*    onClick={() => setSortButton(!sortButton)}*/}
        {/*>*/}
        {/*  <i className="fa fa-sort" aria-hidden="true"/>*/}
        {/*</CButton>*/}
        {/*<SelectForFilter*/}
        {/*    options={selectFilter}*/}
        {/*    onclick={() => setToggleSelect(!toggleSelect)}*/}
        {/*    visible={toggleSelect}*/}
        {/*    onMouse={(event:boolean) => setToggleSelect(event)}*/}
        {/*/>*/}
      </div>

      <SortAndFilter
        sort
        defaultSettings={filterSettings}
        onChangeSettings={setFilterSettings}
      />

      {!isLoading ? 
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className='board-block_columns'>
            {Object.keys(boardSections).map((boardSectionKey) => (
              <Fragment key={boardSectionKey}>
                <BoardSection
                  id={boardSectionKey}
                  title={boardSectionKey}
                  tasks={boardSections[boardSectionKey]}
                  onclick={(id:number | string) => showTasksByIdInModalWindow(id)}
                />
              </Fragment>
            ))}
            <DragOverlay
              dropAnimation={dropAnimation}
              style={{background: '#F4F5F7', opacity: 0.6}}
            >
              {task ? <TaskItem task={task} /> : null}
            </DragOverlay>
          </div>
        </DndContext>
        : <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      }

      <DefaultModal
        visible={visible}
        setVisible={setVisible}
        title={activeTask?.title}
        size="xl"
        type="info"
      >
        {activeTask &&
          <div className='board-block_modal'>
            <div className='board-block_modal_content'>
              {/*<h3 className='board-block_modal_content_title'>{activeTask?.title}</h3>*/}
              <div className="board-block_modal_content-top">
                <div className="board-block_modal_content-top-left">
                  <h5 className='board-block_modal_content_description-title'>{t('assignmentsPage.description')}</h5>
                  <p className='board-block_modal_content_description'>{activeTask?.description}</p>
                </div>

                <div className="board-block_modal_content-top-right">
                  <h4 className='board-block_modal_content_author'>
                                        <span style={{
                                          color: '#000',
                                          fontSize: '12px'
                                        }}>{t('assignmentsPage.author')}: </span>
                    {activeTask.author.id === me?.id ? t('assignmentsPage.you') : activeTask?.author?.username}
                  </h4>
                  <h4 className='board-block_modal_content_author'>
                                        <span style={{
                                          color: '#000',
                                          fontSize: '12px'
                                        }}>{t('assignmentsPage.responsibleExecutor')}: </span>
                    {activeTask?.responsible_executor?.username}
                  </h4>

                  {activeTask?.executors_report.length ? <div className="board-block_modal_content_executors">
                                              <span style={{color: '#000', fontSize: '12px'}}>
                                                {t('assignmentsPage.executors')}:{' '}
                                              </span>

                    <span
                      className="board-block_modal_content_executors-names"
                      onClick={() => setExecutorsHidden(!executorsHidden)}
                      style={{color: '#000', fontSize: '12px'}}
                    >
                                                    {t('assignmentsPage.executorsList')}
                      {executorsHidden && (
                        <div className="board-block_modal_content_executors-hidden">
                          {activeTask?.executors_report.map((el: any) => (
                            <div key={el?.id}>{el?.username}</div>
                          ))}
                        </div>
                      )}
                                                  </span>
                  </div> : null}
                  <div className='board-block_modal_content_date'>
                    {/* дата создания */}
                    <div>
                      <b>{t('assignmentsPage.create_at')}</b>
                      <i className='board-block_modal_content_deadline'>{dayjs(activeTask?.created_at).format('DD.MM.YYYY HH:mm')}</i>
                    </div>
                    {/**/}
                    {/* Сроки исполнения */}
                    {activeTask?.datetime_deadline
                      ? <div>
                        <b>{t('assignmentsPage.deadline')}</b>
                        <span>{t('assignmentsPage.to')}</span>
                        <i className='board-block_modal_content_deadline'>
                          {dayjs(activeTask?.datetime_deadline).format('DD.MM.YYYY HH:mm')}
                        </i>
                      </div>
                      : null
                    }
                    {/**/}
                    {/* Дата принятия в работу */}
                    {activeTask?.datetime_employment
                      ? <div>
                        <b>{t('assignmentsPage.datetime_employment')}</b>
                        <i className='board-block_modal_content_deadline'>
                          {dayjs(activeTask?.datetime_employment).format('DD.MM.YYYY HH:mm')}
                        </i>
                      </div>
                      : null
                    }
                    {/**/}
                    {/* дата исполнения */}
                    {activeTask?.datetime_actual_execution ?
                      <div>
                        <b>{t('assignmentsPage.actual_execution')}</b>
                        <i className='board-block_modal_content_deadline'>
                          {dayjs(task?.datetime_actual_execution).format('DD.MM.YYYY HH:mm')}
                        </i>
                      </div>
                      : null
                    }

                    {activeTask?.datetime_actual_approval ?
                        <div>
                          <b>{t('assignmentsPage.datetime_actual_approval')}</b>
                          <i className='board-block_modal_content_deadline'>
                            {dayjs(task?.datetime_actual_approval).format('DD.MM.YYYY HH:mm')}
                          </i>
                        </div>
                        : null
                    }

                    {/**/}
                    {/*<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>*/}
                    <div style={{display: 'flex', gap: '10px'}}>
                      <b>{t('assignmentsPage.priority')}</b>
                      <PriorityComponent priority={activeTask?.priority}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}
            {activeTask?.file_assignments?.length ?
              <div>
                <h5 className='board-block_modal_content_description-title'>{t('assignmentsPage.imgDesc')}</h5>
                <div className="board-block_modal_content_files">
                  {activeTask?.file_assignments && activeTask?.file_assignments.map((item: any) => {
                    return (
                      <FileComponents
                        key={item.id}
                        extension={extension}
                        icon={icon.cilPaperclip}
                        item={item}
                        getDataWithQueryHandler={getDataWithQueryHandler}
                      />
                    )
                  })}
                </div>
              </div>
              : null}
            <div className='board-block_modal_comments'>
              <CommentsComponent taskID={activeTask?.id}/>
            </div>
          </div>
        }
      </DefaultModal>
    </div>
  );
};

export default BoardSectionList;
